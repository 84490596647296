import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import ProfileIcon from "@material-ui/icons/Person";

import useStyles from "@kimiya/ui/toolbar/toolbar.styles";

const shopSiderbarVoices = [    
    { label: "Il tuo account", value: "account" },
    { label: "Cambia password", value: "password" },
    { label: "I tuoi indirizzi", value: "addresses" },
    { label: "Gestione P.IVA", value: "vat" },
    { label: "Il tuo abbonamento", value: "subscription" },
    { label: "Impostazioni", value: "settings" },
    { label: "Termini e Condizioni", value: "terms" },
    { label: "Informativa Privacy", value: "privacy" },
];

const AppSidebar = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { profile_section } = useParams();

    const goToProfileSection = (value) => {
        if(value === 'terms'){
            window.open("https://erp.kimiya.club/api/?id_transaction_type=0&id_agreement=8&request_format=url&action_type=custom&action_custom_method=generatePDF&action_custom_class=work.bitframe.api.SystemAction", '_blank')
            return;
        }
        if(value === 'privacy'){
            window.open("https://erp.kimiya.club/api/?id_transaction_type=0&id_agreement=7&request_format=url&action_type=custom&action_custom_method=generatePDF&action_custom_class=work.bitframe.api.SystemAction", '_blank')
            return;
        }
        history.push(`/profile/${value || ''}`);
    };

    return (        
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <Toolbar />
            <div className={classes.drawerContainer}>
                <List>
                    <ListItem>
                        <ListItemIcon className={classes.sidebarHeaderIcon}>
                            <ProfileIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("Profilo")} className={classes.sidebarHeaderText} />
                    </ListItem>
                    <Divider className={classes.sidebarHeaderDivider} />
                    {shopSiderbarVoices.map((item, index) => (
                        <ListItem button key={index} onClick={() => goToProfileSection(item.value)}>
                            <ListItemText
                                className={((profile_section===item.value) || (!profile_section && index===0)) ? classes.sidebarVoiceSelected : classes.sidebarVoice}
                                primary={item.label}                                
                            />
                        </ListItem>
                    ))}
                </List>
            </div>
        </Drawer>
    );
};

export default AppSidebar;
