import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import AddressForm from "@kimiya/forms/address/address.form"

import BackIcon from "@material-ui/icons/KeyboardBackspace";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
    sectionTitle: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(48),
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,
    },
    switchContainer: {
        margin:theme.spacing(2,0)
    },
    switchText: {
        fontFamily: "Brown-Light",
        color: theme.palette.grey5.main,
        fontSize: theme.typography.pxToRem(16),        
        marginLeft: theme.spacing(3)
    }
}));

const AddressFormPage = ({ history }) => {
    const classes = useStyle();
    const { t } = useTranslation();
    const { address_id } = useParams();  

    const goBack = () => history.goBack();

    const renderTitle = useMemo(() => {
        if (address_id) {
            return (
                <Typography className={classes.sectionTitle}>
                    {t("Modifica indirizzo")}
                </Typography>
            );
        }

        return (
            <Typography className={classes.sectionTitle}>
                {t("Crea indirizzo")}
            </Typography>
        );
    }, [address_id, t, classes]);

    return (
        <Container maxWidth={'md'}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>{renderTitle}</Grid>
                <Grid item>
                    <Button
                        startIcon={<BackIcon />}
                        variant="text"
                        onClick={goBack}
                        color="secondary"
                    >
                        {t("Back")}
                    </Button>
                </Grid>
            </Grid>
            <AddressForm />
        </Container>
    );
};

export default AddressFormPage;
