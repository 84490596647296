import { useState } from "react"; 
import { useTranslation } from "react-i18next";
import i18n from "@kimiya/core/i18n";
import { useFormik } from "formik";
import * as yup from "yup";
import { useQuery } from "react-query";
import { getUser, requestCloseAccount} from "@kimiya/core/store/actions/auth";
import { format, addDays, differenceInCalendarDays } from "date-fns";
import { DialogConsumer } from "@kimiya/core/context/dialog.context";

import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid"
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';

import Card from "@kimiya/ui/card/card"
import ContainedButton from "@kimiya/ui/button/contained-button.component";
import Input from "@kimiya/ui/input/input.component";
import Backdrop from "@kimiya/ui/backdrop/backdrop"
import ConfirmActionDialog from "@kimiya/dialogs/confirm-action/confirm-action.dialog";

import { useTheme, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyle = makeStyles((theme) => ({    
    cardSection: {
        marginBottom: theme.typography.pxToRem(25)
    },
    sectionSubTitle: { 
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(22),
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,    
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.pxToRem(18),
        }    
    },
}));

const validationSchema = yup.object({
    deleteConfirmText: yup
        .string(i18n.t("Digita 'CHIUDI' per procedere"))        
        .matches(/^(chiudi\b)/i, i18n.t('Per procedere digitare la parola "chiudi"'))
        .required(i18n.t("La parola di sicurezza è richiesta per chiudere l'account")),
});

const CloseAccountCard = () => {
    const classes = useStyle();
    const { t } = useTranslation(); 
    const theme = useTheme();    
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [loading, setLoading] = useState(false)
    const [saveError, setSaveError] = useState(false)
    const [showToast, setShowToast] = useState(false)
    const [user, setUser] = useState(false)

    const toggleDeleteAccount = async () => {
        setLoading(true)
        const res = await requestCloseAccount(user.b_deleted_request===true ? "false" : "true")
        setLoading(false)
        if(!res) {          
            setSaveError(true)  
            setShowToast(true)
            return;
        }        
        setShowToast(true)        
        refetchResource()
    }

    const handleClose = (event, reason) => {
        setSaveError(false)  
        setShowToast(false)
    };

    const formik = useFormik({
        initialValues: {
            deleteConfirmText: ''
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (_, { resetForm }) => {
            toggleDeleteAccount();
            resetForm()
        }
    });

    const refetchResource = async () => {                
        const resource = await getUser()                
        setUser(resource)
        return resource
    }

    const { isLoading, error } = useQuery(["resource"], refetchResource)

    if (isLoading) return <Backdrop open customText={t('Caricando dati utente...Attendere...')} />
    if (error) return "An error has occurred: " + error.message;

    if(user.b_deleted_request){
        return (
            <Card customClasses={classes.cardSection}>
                <Typography className={classes.sectionSubTitle}>
                    {t("Chiudi Account")}
                </Typography>
                <Typography>{t('Abbiamo ricevuto la tua richiesta di chiusura dell\'account.')}</Typography>
                <Typography>{t('Il tuo account verrà chiuso il giorno')}: <strong>{format(addDays(new Date(user.d_deleted_request), 10),'dd/MM/yyyy')}</strong> <small>(-{differenceInCalendarDays(addDays(new Date(user.d_deleted_request),10), new Date())} {t('giorni')})</small></Typography>
                <Box py={3}>
                    <DialogConsumer>
                        {({ showDialog }) => (
                            <ContainedButton
                                type="button"
                                variant="contained"
                                color="secondary"                        
                                onClick={() => showDialog(ConfirmActionDialog, {
                                    title: t('Conferma Richiesta'),
                                    callback: () => toggleDeleteAccount()
                                })}
                            >
                                {t("Annulla richiesta")}
                            </ContainedButton>
                        )}
                    </DialogConsumer>
                </Box>
                <Backdrop open={loading} customText={t("Richiesta annullamento in  corso...Attendere...")} />
                <Snackbar 
                    open={showToast} 
                    autoHideDuration={6000} 
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    {!saveError 
                        ? <Alert severity="success">{t('Richiesta effettuata con successo.')}</Alert>
                        : <Alert severity="error">{t('Errore durante il salvataggio della nuova password')}</Alert>
                    } 
                </Snackbar>
            </Card>
        )
    }
    
    return (
        <DialogConsumer>
            {({ showDialog }) => (
                <Card customClasses={classes.cardSection}>
                    <Typography className={classes.sectionSubTitle}>
                        {t("Chiudi Account")}
                    </Typography>
                    <Typography>{t('Utilizza questa sezione per richiedere la chiusura del tuo account.')}</Typography>
                    <Typography>{t('N.B. Una volta richiesta la chiusura avrai a disposizione 10 giorni di tempo per cambiare idea e annullare la richiesta.')}</Typography>
                    <Box py={3}>                
                        <form
                            className={classes.root}
                            noValidate
                            autoComplete="off"
                            onSubmit={(e) => {
                                e.preventDefault();
                                showDialog(ConfirmActionDialog,{
                                    title: t('Conferma Richiesta'),
                                    callback: () => formik.handleSubmit()
                                })
                                return;
                            }}
                        >
                            <Grid
                                container
                                direction={isMobile ? "column" : "row"}
                                justifyContent={isMobile ? "center" : "space-between"}
                                alignItems="center"
                                spacing={isMobile ? 4 : null}
                            >
                                <Grid item xs={12} md={8}>                            
                                    <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
                                        <Grid item xs={12}>
                                            <Input
                                                autoComplete="off"
                                                type="email"
                                                id="deleteConfirmText"
                                                name="deleteConfirmText"
                                                label={t("Digita 'CHIUDI' per confermare la richiesta")}
                                                placeholder={t('digita CHIUDI')}
                                                variant="outlined"
                                                fullWidth
                                                value={formik.values.deleteConfirmText}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched.deleteConfirmText &&
                                                    Boolean(formik.errors.deleteConfirmText)
                                                }
                                                helperText={
                                                    formik.touched.deleteConfirmText &&
                                                    formik.errors.deleteConfirmText
                                                }
                                            />
                                        </Grid>                                
                                    </Grid>                            
                                </Grid>  
                                <Grid item xs={12} md={2}>
                                    <ContainedButton
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        disabled={!formik.dirty || (formik.dirty && !formik.isValid)}
                                    >
                                        {t("Elimina")}
                                    </ContainedButton>
                                </Grid>                  
                            </Grid>                
                        </form>                
                    </Box>
                    <Backdrop open={loading} customText={t("Richiesta cancellazione in  corso...Attendere...")} />
                    <Snackbar 
                        open={showToast} 
                        autoHideDuration={6000} 
                        onClose={handleClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    >
                        {!saveError 
                            ? <Alert severity="success">{t('Richiesta effettuata con successo.')}</Alert>
                            : <Alert severity="error">{t('Errore durante il salvataggio della nuova password')}</Alert>
                        } 
                    </Snackbar>
                </Card>
            )}
        </DialogConsumer>
    )
}

export default CloseAccountCard