import _ from "lodash"

export const decodeHtmlCharCodes = (str) =>
    str.replace(/(&#(\d+);)/g, (match, capture, charCode) =>
        String.fromCharCode(charCode)
    );

export const keysValuesGenerator = async (obj) => {
    let keys_values = "";
    const objKeys = Object.keys(obj);
    objKeys.map((key) => {
        
        if(keys_values==="") keys_values += `${key}~${obj[key]}`;        
        else keys_values += `|${key}~${obj[key]}`;        

        return key;
    });
    return keys_values;
};

export const getMaxItemFromArrayByAttribute = (array, attribute) => {
    return array.reduce(function(prev, current) {
        return (prev[attribute] > current[attribute]) ? prev : current
    })
}

export const isArrayEqual = function(x, y) {
    return _(x).differenceWith(y, _.isEqual).isEmpty();
};