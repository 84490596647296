import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import { useQuery } from "react-query"
import { getCities, getRegions, getCounties } from "@kimiya/core/store/actions/shared"
import { registrationBillingValidationSchema } from "@kimiya/core/utils/validation_schemas"

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel"
import Checkbox from "@material-ui/core/Checkbox";
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Backdrop from "@kimiya/ui/backdrop/backdrop"
import ContainedButton from "../../ui/button/contained-button.component";
import OutlinedButton from "../../ui/button/outlined-button.component";
import Input from "../../ui/input/input.component";
import StyledSelect from "../../ui/select/select.component";

import { useTheme, useMediaQuery } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    formControl: {
        width: "100%",
    },
    backButton: {
        textTransform: "Capitalize",
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },
    checkboxLabel: {
        fontFamily: 'Brown-Light',
        fontSize: theme.typography.pxToRem(14)
    },
    introText: {
        fontSize: 16,
        color: theme.palette.secondary.main,
        marginBottom: theme.typography.pxToRem(15)
    }
}));

const RegistrationPersonalDataForm = ({ form, handleNext, handleBack }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [cities, setCities] = useState()
    const [regions, setRegions] = useState()
    const [counties, setCounties] = useState()      
    const [citiesCompany, setCitiesCompany] = useState()
    const [regionsCompany, setRegionsCompany] = useState()
    const [countiesCompany, setCountiesCompany] = useState()      

    const validationSchema = yup.object(registrationBillingValidationSchema);

    const formik = useFormik({
        initialValues: form,
        validationSchema: validationSchema,
        enableReinitialize: true,
        validateOnMount: true,        
        onSubmit: (values) => {
            // alert(JSON.stringify(values, null, 2));
            handleNext(values);
        },
    });

    const refetchRegions = async () => {        
        const regions = await getRegions(formik.values.id_country)
        setRegions(regions ? regions : [])
        return regions
    }
    
    const refetchCounties = async () => {        
        const counties = await getCounties(formik.values.id_region)
        setCounties(counties ? counties : [])
        return counties
    }    

    const refetchCities = async () => {        
        const cities = await getCities(formik.values.id_county)
        setCities(cities ? cities : [])
        return cities
    }

    const refetchRegionsCompany = async () => {        
        const regions = await getRegions(formik.values.id_country_company)
        setRegionsCompany(regions ? regions : [])
        return regions
    }
    
    const refetchCountiesCompany = async () => {        
        const counties = await getCounties(formik.values.id_region_company)
        setCountiesCompany(counties ? counties : [])
        return counties
    }    

    const refetchCitiesCompany = async () => {        
        const cities = await getCities(formik.values.id_county_company)
        setCitiesCompany(cities ? cities : [])
        return cities
    }
    
    const { isLoadingRegions, errorRegions } = useQuery(["regions"], refetchRegions, {
        enabled: !!formik.values.id_country
    })
    const { isLoadingCounties, errorCounties } = useQuery(["counties"], refetchCounties,{
        enabled: !!formik.values.id_region
    })
    const { isLoadingCities, errorCities } = useQuery(["cities"], refetchCities,{
        enabled: !!formik.values.id_county
    })

    const { isLoadingRegionsCompany, errorRegionsCompany } = useQuery(["regions-company"], refetchRegionsCompany, {
        enabled: !!formik.values.id_country_company
    })
    const { isLoadingCountiesCompany, errorCountiesCompany } = useQuery(["counties"], refetchCountiesCompany,{
        enabled: !!formik.values.id_region_company
    })
    const { isLoadingCitiesCompany, errorCitiesCompany } = useQuery(["cities"], refetchCitiesCompany,{
        enabled: !!formik.values.id_county_company
    })
    
    if (isLoadingRegions) return <Backdrop open customText={t('Fetching regions...')} />
    if (isLoadingCounties) return <Backdrop open customText={t('Fetching counties...')} />
    if (isLoadingCities) return <Backdrop open customText={t('Fetching cities...')} />

    if (isLoadingRegionsCompany) return <Backdrop open customText={t('Fetching regions...')} />
    if (isLoadingCountiesCompany) return <Backdrop open customText={t('Fetching counties...')} />
    if (isLoadingCitiesCompany) return <Backdrop open customText={t('Fetching cities...')} />
    
    if (errorCities) return "An error has occurred: " + errorCities.message;
    if (errorRegions) return "An error has occurred: " + errorRegions.message;
    if (errorCounties) return "An error has occurred: " + errorCounties.message;
    
    if (errorCitiesCompany) return "An error has occurred: " + errorCitiesCompany.message;
    if (errorRegionsCompany) return "An error has occurred: " + errorRegionsCompany.message;
    if (errorCountiesCompany) return "An error has occurred: " + errorCountiesCompany.message;

    return (
        <Box py={3}>
            <form
                className={classes.root}
                noValidate
                autoComplete="off"
                onSubmit={formik.handleSubmit}
            >
                <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Typography className={classes.introText}>
                            {t("Inserisci i dati anagrafici")}:
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
                            <Grid item>
                                <Typography>Privato</Typography>
                            </Grid>
                            <Grid item>
                                <Switch checked={formik.values.b_company ? true : false} onChange={formik.handleChange} name="b_company" />
                            </Grid>
                            <Grid item>
                                <Typography>Azienda</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Personal billing Section */}
                    <Grid item xs={12}>
                        <Input
                            autoComplete="off"
                            id="s_tax_code"
                            name="s_tax_code"
                            label={t("Codice Fiscale")}
                            variant="outlined"
                            fullWidth
                            required
                            value={formik.values.s_tax_code}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.s_tax_code &&
                                Boolean(formik.errors.s_tax_code)
                            }
                            helperText={
                                formik.touched.s_tax_code &&
                                formik.errors.s_tax_code
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            autoComplete="off"
                            id="s_address"
                            name="s_address"
                            label={t("Indirizzo")}
                            variant="outlined"
                            fullWidth
                            required
                            value={formik.values.s_address}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.s_address &&
                                Boolean(formik.errors.s_address)
                            }
                            helperText={
                                formik.touched.s_address && formik.errors.s_address
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            autoComplete="off"
                            id="s_zip_code"
                            name="s_zip_code"
                            label={t("CAP")}
                            variant="outlined"
                            fullWidth
                            required
                            value={formik.values.s_zip_code}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.s_zip_code && Boolean(formik.errors.s_zip_code)
                            }
                            helperText={formik.touched.s_zip_code && formik.errors.s_zip_code}
                        />
                    </Grid>                    
                    
                    {regions && (
                        <Grid item xs={12}>
                            <StyledSelect
                                autoComplete="off"
                                id="id_region"
                                name="id_region"
                                label={t("Regione")}
                                variant="outlined"
                                fullWidth
                                required
                                value={formik.values.id_region}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.id_region &&
                                    Boolean(formik.errors.id_region)
                                }
                                helperText={
                                    formik.touched.id_region && formik.errors.id_region
                                }
                            >
                                <MenuItem value="">
                                    <em>{t("Scegli Regione")}</em>
                                </MenuItem>
                                {regions.map((region,index) => (
                                    <MenuItem key={index} value={region.id_region}>
                                        {region.s_region}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </Grid>
                    )}
                    
                    {counties && (
                        <Grid item xs={12}>
                            <StyledSelect
                                autoComplete="off"
                                id="id_county"
                                name="id_county"
                                label={t("Provincia")}
                                variant="outlined"
                                fullWidth
                                required
                                value={formik.values.id_county}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.id_county &&
                                    Boolean(formik.errors.id_county)
                                }
                                helperText={
                                    formik.touched.id_county && formik.errors.id_county
                                }
                            >
                                <MenuItem value="">
                                    <em>{t("Scegli Provincia")}</em>
                                </MenuItem>
                                {counties.map((county,index) => (
                                    <MenuItem key={index} value={county.id_county}>
                                        {county.s_county}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </Grid>
                    )}
                    
                    {cities && (
                        <Grid item xs={12}>
                            <StyledSelect
                                autoComplete="off"
                                id="id_city"
                                name="id_city"
                                label={t("Città")}
                                variant="outlined"
                                fullWidth
                                required
                                value={formik.values.id_city}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.id_city &&
                                    Boolean(formik.errors.id_city)
                                }
                                helperText={
                                    formik.touched.id_city && formik.errors.id_city
                                }
                            >
                                <MenuItem value="">
                                    <em>{t("Scegli Città")}</em>
                                </MenuItem>
                                {cities.map((city,index) => (
                                    <MenuItem key={index} value={city.id_city}>
                                        {city.s_city}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </Grid>
                    )}

                    {/* Company Billing Section */}

                    {formik.values.b_company && (
                        <>
                            <Grid item xs={12}>
                                <Typography>{t('Company Details')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    autoComplete="off"
                                    id="s_denomination"
                                    name="s_denomination"
                                    label={t("Company Name")}
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={formik.values.s_denomination}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.s_denomination &&
                                        Boolean(formik.errors.s_denomination)
                                    }
                                    helperText={
                                        formik.touched.s_denomination &&
                                        formik.errors.s_denomination
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    autoComplete="off"
                                    id="s_tax_code_company"
                                    name="s_tax_code_company"
                                    label={t("Fiscal Code")}
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={formik.values.s_tax_code_company}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.s_tax_code_company &&
                                        Boolean(formik.errors.s_tax_code_company)
                                    }
                                    helperText={
                                        formik.touched.s_tax_code_company &&
                                        formik.errors.s_tax_code_company
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    autoComplete="off"
                                    id="s_vat_code_company"
                                    name="s_vat_code_company"
                                    label={t("VAT")}
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={formik.values.s_vat_code_company}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.s_vat_code_company &&
                                        Boolean(formik.errors.s_vat_code_company)
                                    }
                                    helperText={
                                        formik.touched.s_vat_code_company &&
                                        formik.errors.s_vat_code_company
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl 
                                    component="fieldset" 
                                    error={
                                        formik.touched.s_fe_type &&
                                        Boolean(formik.errors.s_fe_type)
                                    }
                                >
                                    <FormLabel component="legend">{t('Metodo Fatturazione Elettronica')}</FormLabel>
                                    <RadioGroup 
                                        aria-label="s_fe_type" 
                                        name="s_fe_type" 
                                        value={formik.values.s_fe_type} 
                                        onChange={formik.handleChange}
                                    >
                                        <FormControlLabel value="sdi" control={<Radio />} label="Codice SDI" />
                                        <FormControlLabel value="pec" control={<Radio />} label="PEC" />                                        
                                    </RadioGroup>
                                    {formik.touched.s_fe_type && Boolean(formik.errors.s_fe_type) && (
                                        <FormHelperText>{t('Si prega di selezionare un metodo per la Fatturazione Elettronica')}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            {formik.values.s_fe_type==='sdi' && (
                                <Grid item xs={12}>
                                    <Input
                                        autoComplete="off"
                                        id="s_fe_sdi"
                                        name="s_fe_sdi"
                                        label={t("Codice SDI (Fatturazione Elettronica)")}
                                        variant="outlined"
                                        fullWidth                                    
                                        value={formik.values.s_fe_sdi}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.s_fe_sdi &&
                                            Boolean(formik.errors.s_fe_sdi)
                                        }
                                        helperText={
                                            formik.touched.s_fe_sdi && formik.errors.s_fe_sdi
                                        }
                                    />
                                </Grid>
                            )}
                            {formik.values.s_fe_type==='pec' && (
                                <Grid item xs={12}>
                                    <Input
                                            autoComplete="off"
                                            required
                                            type="email"
                                            id="email"
                                            name="email"
                                            label={t("PEC Fatturazione Elettronica")}
                                            variant="outlined"
                                            fullWidth
                                            value={formik.values.s_fe_pec}
                                            onChange={formik.handleChange}
                                            error={formik.touched.s_fe_pec && Boolean(formik.errors.s_fe_pec)}
                                            helperText={formik.touched.s_fe_pec && formik.errors.s_fe_pec}
                                        />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Input
                                    autoComplete="off"
                                    id="s_address_company"
                                    name="s_address_company"
                                    label={t("Company Address")}
                                    variant="outlined"
                                    fullWidth
                                    value={formik.values.s_address_company}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.s_address_company &&
                                        Boolean(formik.errors.s_address_company)
                                    }
                                    helperText={
                                        formik.touched.s_address_company && formik.errors.s_address_company
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    autoComplete="off"
                                    id="s_zip_code_company"
                                    name="s_zip_code_company"
                                    label={t("ZIP")}
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={formik.values.s_zip_code_company}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.s_zip_code_company && Boolean(formik.errors.s_zip_code_company)
                                    }
                                    helperText={formik.touched.s_zip_code_company && formik.errors.s_zip_code_company}
                                />
                            </Grid>
                            {regionsCompany && (
                                <Grid item xs={12}>
                                    <StyledSelect
                                        autoComplete="off"
                                        id="id_region_company"
                                        name="id_region_company"
                                        label={t("Regione")}
                                        variant="outlined"
                                        fullWidth
                                        required
                                        value={formik.values.id_region_company}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.id_region_company &&
                                            Boolean(formik.errors.id_region_company)
                                        }
                                        helperText={
                                            formik.touched.id_region_company && formik.errors.id_region_company
                                        }
                                    >
                                        <MenuItem value="">
                                            <em>{t("Scegli Regione")}</em>
                                        </MenuItem>
                                        {regions.map((region,index) => (
                                            <MenuItem key={index} value={region.id_region}>
                                                {region.s_region}
                                            </MenuItem>
                                        ))}
                                    </StyledSelect>
                                </Grid>
                            )}
                            
                            {countiesCompany && (
                                <Grid item xs={12}>
                                    <StyledSelect
                                        autoComplete="off"
                                        id="id_county_company"
                                        name="id_county_company"
                                        label={t("Provincia")}
                                        variant="outlined"
                                        fullWidth
                                        required
                                        value={formik.values.id_county_company}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.id_county_company &&
                                            Boolean(formik.errors.id_county_company)
                                        }
                                        helperText={
                                            formik.touched.id_county_company && formik.errors.id_county_company
                                        }
                                    >
                                        <MenuItem value="">
                                            <em>{t("Scegli Provincia")}</em>
                                        </MenuItem>
                                        {counties.map((county,index) => (
                                            <MenuItem key={index} value={county.id_county}>
                                                {county.s_county}
                                            </MenuItem>
                                        ))}
                                    </StyledSelect>
                                </Grid>
                            )}
                            
                            {citiesCompany && (
                                <Grid item xs={12}>
                                    <StyledSelect
                                        autoComplete="off"
                                        id="id_city_company"
                                        name="id_city_company"
                                        label={t("Città")}
                                        variant="outlined"
                                        fullWidth
                                        required
                                        value={formik.values.id_city_company}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.id_city_company &&
                                            Boolean(formik.errors.id_city_company)
                                        }
                                        helperText={
                                            formik.touched.id_city_company && formik.errors.id_city_company
                                        }
                                    >
                                        <MenuItem value="">
                                            <em>{t("Scegli Città")}</em>
                                        </MenuItem>
                                        {citiesCompany.map((city,index) => (
                                            <MenuItem key={index} value={city.id_city}>
                                                {city.s_city}
                                            </MenuItem>
                                        ))}
                                    </StyledSelect>
                                </Grid>
                            )}
                            
                        </>
                    )}


                    {/* Checkboxes Section */}
                    <Grid item xs={12}>                        
                        <FormControl
                            className={classes.formControl}
                            error={!formik.values.b_ads}
                        >
                            <FormControlLabel
                                classes={{label: classes.checkboxLabel}}
                                control={
                                    <Checkbox
                                        checked={formik.values.b_ads}
                                        onChange={formik.handleChange}
                                        id="b_ads"
                                        name="b_ads"
                                    />
                                }
                                label="Privacy Marketing"
                            />
                            {formik.touched.b_ads &&
                                Boolean(formik.errors.b_ads) && (
                                    <FormHelperText className={classes.checkboxLabel}>
                                        {t(
                                            "Accettare la Privacy Marketing è obbligatorio"
                                        )}
                                    </FormHelperText>
                                )}
                        </FormControl>
                        <FormControl
                            className={classes.formControl}
                            error={!formik.values.b_ads2}
                        >
                            <FormControlLabel
                                classes={{label: classes.checkboxLabel}}
                                control={
                                    <Checkbox
                                        checked={formik.values.b_ads2}
                                        onChange={formik.handleChange}
                                        id="b_ads2"
                                        name="b_ads2"
                                    />
                                }
                                label="Privacy Profilazione"
                            />
                            {formik.touched.b_ads2 &&
                                Boolean(formik.errors.b_ads2) && (
                                    <FormHelperText className={classes.checkboxLabel}>
                                        {t(
                                            "Accettare la Privacy Profilazione è obbligatorio"
                                        )}
                                    </FormHelperText>
                                )}
                        </FormControl>                        
                        <FormControl
                            className={classes.formControl}
                            error={!formik.values.b_privacy_medical}
                        >
                            <FormControlLabel
                                classes={{label: classes.checkboxLabel}}
                                control={
                                    <Checkbox
                                        checked={formik.values.b_privacy_medical}
                                        onChange={formik.handleChange}
                                        id="b_privacy_medical"
                                        name="b_privacy_medical"
                                    />
                                }
                                label="Privacy Medicale"
                            />
                            {formik.touched.b_privacy_medical &&
                                Boolean(formik.errors.b_privacy_medical) && (
                                    <FormHelperText className={classes.checkboxLabel}>
                                        {t(
                                            "Accettare la Privacy Medicale è obbligatorio"
                                        )}
                                    </FormHelperText>
                                )}
                        </FormControl>
                    </Grid>
                </Grid>
                <Box my={4}>
                    <Grid
                        container
                        justifyContent={isMobile ? "center" : "space-between"}
                        alignItems="center"
                        direction={isMobile ? "column" : "row"}
                        spacing={isMobile ? 2 : null}
                    >
                        <Grid item xs={12} md={5}>
                            <OutlinedButton
                                onClick={handleBack}
                                className={classes.backButton}
                                fullWidth
                            >
                                {t("Indietro")}
                            </OutlinedButton>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <ContainedButton
                                type="submit"
                                variant="contained"
                                color="secondary"
                                fullWidth
                                disabled={!formik.isValid}
                            >
                                {t("Conferma e procedi")}
                            </ContainedButton>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </Box>
    );
};

export default RegistrationPersonalDataForm;
