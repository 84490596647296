import { useEffect } from "react"
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"
import { getCart } from "@kimiya/core/store/actions/checkout"

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import Card from "@kimiya/ui/card/card";

import CheckoutItems from "@kimiya/core/components/custom/checkout/checkout-items";
import CheckoutBillingShipping from "@kimiya/core/components/custom/checkout/checkout-billing-shipping";
import CheckoutShippingType from "@kimiya/core/components/custom/checkout/checkout-shipping-type";
import CheckoutTotal from "@kimiya/core/components/custom/checkout/checkout-total";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
    pageTopContainer: {
        marginTop: theme.spacing(4),
        textAlign: "center",
    },
    pageTitle: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(48),
        color: theme.palette.primary.main,
        marginTop: theme.spacing(2),
        [theme.breakpoints.down("sm")]:{
            fontSize: theme.typography.pxToRem(38),
        }
    },
    pageSubtitle: {
        color: theme.palette.grey3.main,
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(24),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]:{
            fontSize: theme.typography.pxToRem(18),
        }
    },    
    checkoutDivider: {
        margin: theme.spacing(4,0)
    },
}));

const CheckoutPage = () => {
    const { t } = useTranslation();
    const classes = useStyle();
    const history = useHistory();
    const dispatch = useDispatch();
    const cart = useSelector(({ checkout }) => checkout.cart || null )

    useEffect(() => {
        if(!cart) dispatch(getCart())
        if(cart && cart.items.length===0){
            history.push('/shop')            
        }        
    },[cart,dispatch,history])
    

    return (        
        <>
            <div className={classes.pageTopContainer}>
                <Typography className={classes.pageTitle}>
                    {t("Checkout ordine")}
                </Typography>
                <Typography className={classes.pageSubtitle}>
                    {t("Di seguito il tuo ordine e i tuoi dati")}:
                </Typography>
            </div>
            <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >
                <Grid item xs={12} md={6}>
                    <CheckoutItems />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card>
                        <Grid container direction="column" spacing={3}>
                            <Grid item xs={12}>
                                <CheckoutBillingShipping />
                            </Grid>
                            <Grid item xs={12}>
                                <CheckoutShippingType />
                            </Grid>
                        </Grid>
                        <Divider className={classes.checkoutDivider} />
                        <CheckoutTotal />
                    </Card>
                </Grid>
            </Grid>
        </>           
    );
};

export default CheckoutPage;
