import { useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AdjustIcon from '@material-ui/icons/Adjust';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import StorefrontIcon from '@material-ui/icons/Storefront';

import useStyles from "@kimiya/ui/toolbar/toolbar.styles";


const LandingNavbar = () => {

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();    
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    },[]);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    },[]);

    
    const openMenuVoice = useCallback(
        (url, external) => {
            if (external) return window.open(url, "_blank");
            history.push(url);
        },
        [history]
    );

    const isActiveRoute = useCallback(
        (url) => {
            return location.pathname.includes(url);
        },
        [location]
    );

    const renderMenu = useMemo(() => {
        return (
            <>
                <Grid item className={classes.menuVoiceContainer}>
                    <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            <IconButton
                                onClick={() =>
                                    openMenuVoice("/home", false)
                                }
                                size="small"
                                className={
                                    isActiveRoute("/home")
                                        ? classes.menuIconContainerSelected
                                        : classes.menuIconContainer
                                }
                            >
                                <HomeIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Typography
                                className={
                                    isActiveRoute("/home")
                                        ? classes.menuVoiceSelected
                                        : classes.menuVoice
                                }
                                onClick={() =>
                                    openMenuVoice("/home", false)
                                }
                            >
                                {t("Home")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.menuVoiceContainer}>
                    <Grid
                        aria-controls="landing-menu" 
                        onClick={handleClick}
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            <IconButton                            
                                size="small"
                                className={classes.menuIconContainer}
                            >
                                <InfoIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Typography
                                className={classes.menuVoice}                                                    
                            >
                                {t("Su di noi")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton 
                                size="small"
                                className={classes.menuIconContainer}
                            >
                                <KeyboardArrowDownIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Menu
                        id="landing-menu"
                        anchorEl={anchorEl}
                        elevation={2}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        getContentAnchorEl={null}
                        classes={{ paper: classes.userDropdownMenu }}
                    >                        
                        <div>
                            <MenuItem
                                className={classes.userDropdownMenuItem}                    
                                onClick={() => history.push("/la-nostra-mission")}
                            >
                                <ListItemIcon className={classes.userDropDownMenuItemIcon}>
                                    <AdjustIcon />
                                </ListItemIcon>
                                <Typography
                                    className={classes.userDropDownMenuItemText}
                                    variant="inherit"
                                >
                                    {t("La Mission")}
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                className={classes.userDropdownMenuItem}
                                onClick={() => history.push("/le-nostre-materie-prime")}
                            >
                                <ListItemIcon className={classes.userDropDownMenuItemIcon}>
                                    <MenuBookIcon />
                                </ListItemIcon>
                                <Typography
                                    className={classes.userDropDownMenuItemText}
                                    variant="inherit"
                                >
                                    {t("Materie Prime")}
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                className={classes.userDropdownMenuItem}
                                onClick={() => history.push("/i-nostri-prodotti")}
                            >
                                <ListItemIcon className={classes.userDropDownMenuItemIcon}>
                                    <FastfoodIcon />
                                </ListItemIcon>
                                <Typography
                                    className={classes.userDropDownMenuItemText}
                                    variant="inherit"
                                >
                                    {t("Prodotti")}
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                className={classes.userDropdownMenuItem}
                                onClick={() => history.push("/la-nostra-azienda")}
                            >
                                <ListItemIcon className={classes.userDropDownMenuItemIcon}>
                                    <StorefrontIcon />
                                </ListItemIcon>
                                <Typography
                                    className={classes.userDropDownMenuItemText}
                                    variant="inherit"
                                >
                                    {t("L'azienda")}
                                </Typography>
                            </MenuItem>                            
                        </div>                                                
                    </Menu>
                </Grid>
            </>
        );
    }, [classes, isActiveRoute, openMenuVoice, t, anchorEl, handleClick, handleClose, history]);

    return renderMenu
}

export default LandingNavbar