
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import Card from "@kimiya/ui/card/card"

import { makeStyles } from "@material-ui/core/styles"

const useStyle = makeStyles(theme => ({    
    faqLetter: {
        fontFamily: "Brown-Bold",
        color: theme.palette.secondary.main,
        fontSize: theme.typography.pxToRem(20)
    },
    faqText: {
        fontFamily: "Brown-Light",
        color: theme.palette.grey5.main,
        fontSize: theme.typography.pxToRem(16),
        letterSpacing: 0.44,
        padding: 0,
        margin: 0
    }
}))

const FaqCard = ({ question, answer }) => {

    const classes = useStyle();

    return (
        <Card>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2}>
                        <Grid item>
                            <Typography className={classes.faqLetter}>Q</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={classes.faqText}>{question}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
                <Grid item>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2}>
                        <Grid item className={classes.faqLetter}>A</Grid>
                        <Grid item xs>
                            <Typography className={classes.faqText}>{answer}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}

export default FaqCard