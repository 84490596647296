import ChatHeader from "../chat-header/chat-header"
import ChatMessages from "@kimiya/core/components/custom/coach/chat-messages/chat-messages"
import ChatInput from "@kimiya/core/components/custom/coach/chat-input/chat-input"

import useChatStyles from "@kimiya/custom/coach/chat.styles"

const Chat = ({ chat, toggleUserPanel, isUserPanelOpen}) => {
    
    const classes = useChatStyles()

    return (
        <div className={classes.chatContainer}>            
            <ChatHeader 
                chat={chat}
                toggleUserPanel={toggleUserPanel}
                isUserPanelOpen={isUserPanelOpen}
            />
            <ChatMessages chatId={chat.id_support} />
            <ChatInput chat={chat} />
        </div>
    )
}

export default Chat