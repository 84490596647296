import { useState } from "react"
import { useTranslation } from "react-i18next"

import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import IconButton from "@material-ui/core/IconButton"
import Avatar from "@material-ui/core/Avatar"
import Divider from "@material-ui/core/Divider"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

import ChatIcon from "@material-ui/icons/Forum"
import MenuIcon from "@material-ui/icons/MoreVert"

import useChatStyles from "@kimiya/custom/coach/chat.styles"

const ChatSidebarItem = ({ chat, chatId, chatName, chatDate, onSelect }) => {

    const classes = useChatStyles()
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = useState(null);

    const openChatMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const closeChatMenu = () => {
        setAnchorEl(null);
    };

    const openChat = () => {
        onSelect(chat)
        setAnchorEl(null);
    }

    return (
        <>
            <ListItem button onClick={openChat}>
                <ListItemAvatar>
                    <Avatar className={classes.chatAvatar}>
                        <ChatIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={chatName} secondary={chatDate} />
                <ListItemSecondaryAction>
                    <IconButton 
                        edge="end" 
                        aria-controls={`chat-menu-${chatId}`}
                        aria-haspopup={true}
                        onClick={openChatMenu}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id={`chat-menu-${chatId}`}
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={closeChatMenu}
                    >
                        <MenuItem onClick={openChat}>{t("Apri Chat")}</MenuItem>
                        <MenuItem onClick={closeChatMenu}>{t("Archivia Chat")}</MenuItem>                        
                    </Menu>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
        </>
    )
}

export default ChatSidebarItem