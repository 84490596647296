import { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query"
import { getSubscription } from "@kimiya/core/store/actions/auth"
import { SUBSCRIPTION_DURATION } from "@kimiya/core/core/combined-fields-mapper"

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Card from "@kimiya/ui/card/card"
import Backdrop from "@kimiya/ui/backdrop/backdrop"

import InfoIcon from "@material-ui/icons/InfoOutlined"

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    subscriptionCardContainer: {
        width: "100%",
        marginBottom: theme.spacing(1),        
    },    
    card: {
        borderRadius: 20
    },
    selected: {
        border: `3px solid ${theme.palette.secondary.main}`
    },
    subscriptionName: {        
        color: theme.palette.secondary.main,
        fontSize: 26,
        textTransform: "uppercase"
    },
    periodText: {
        color: theme.palette.primary.main,
        fontSize: 14,
    }
}));

const SubscriptionCard = ({ selected = false, item, onSelect, fullWidth = false }) => {

    const classes = useStyles();
    const { t } = useTranslation()
    const [subscription, setSubscription] = useState()

    const refetchSubscriptions = async () => {
        const subscription = await getSubscription(item.id_item);
        setSubscription(subscription)
    }
    const { isLoading, error } = useQuery(["get-subscriptions"], refetchSubscriptions)
    if (isLoading || !subscription) return <Backdrop open customText={t('Fetching subscription...')} />
    if (error) return "An error has occurred: " + error.message;

    return (
        <Grid container direction="column" spacing={1} className={classes.subscriptionCardContainer}>
            <Grid item>
                <Card customClasses={selected ? `${classes.card} ${classes.selected}` : classes.card} onClick={() => onSelect(subscription)}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h5" className={classes.subscriptionName}>{subscription.s_item_code_supplier}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" color="primary">
                                {subscription.m_sell_price_taxed.toFixed(2)}€/
                                <span className={classes.periodText}>{subscription[SUBSCRIPTION_DURATION].s_license_duration==="annuale" ? "anno" : "mese"}</span>
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item style={{ height: 40}}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                    <Grid item xs={7}>
                        <Typography variant="caption" color="secondary">
                            {(subscription?.paymentTiming) 
                                ? subscription.paymentTiming==='monthly' 
                                    ? t("Ciclo di fatturazione mensile") 
                                    : t("Ciclo di fatturazione annuale")
                                : ''
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
                            <Grid item>
                                <InfoIcon color="secondary" fontSize="small" />
                            </Grid>
                            <Grid item>
                                <Typography color="secondary" variant="caption"> {t('cosa è incluso')}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SubscriptionCard