import { useState } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query"
import { getAddresses, deleteAddress } from "@kimiya/core/store/actions/profile"
import { toast } from "react-toastify"

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Backdrop from "@kimiya/ui/backdrop/backdrop"
import OutlinedButton from "@kimiya/ui/button/outlined-button.component"
import AddressCard from "@kimiya/ui/card/address-card"

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({    
    sectionTitle: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(48),
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.pxToRem(28),
            marginBottom: theme.spacing(2)
        }
    },
    sectionSubTitle: { 
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(28),
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,
        marginTop: 20,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.pxToRem(18),
        }
    },
}));

const ProfileAddresses = () => {

    const classes = useStyle();
    const { t } = useTranslation();
    const history = useHistory();
    const [addresses, setAddresses] = useState([])

    const goToAddressForm = (address_id) => {
        if(address_id){
            history.push(`/profile/addresses/edit/${address_id}`)    
            return;
        }
        history.push("/profile/addresses/new")
    }

    const onDeleteAddress = async (addressId) => {
        const res = await deleteAddress(`id_address~${addressId}|b_outdated~1`);
        if(!res){
            toast.error("Errore durante la cancellazione dell'indirizzo");
        }
        toast.success("Indirizzo eliminato con successo");
        refetchAddresses()
    }

    const refetchAddresses = async () => {                
        const addresses = await getAddresses()                
        setAddresses(addresses ? addresses : [])
        return addresses
    }

    const { isLoading, error } = useQuery(["addresses"], refetchAddresses)

    if (isLoading) return <Backdrop open customText={t('Fetching addresses...')} />
    if (error) return "An error has occurred: " + error.message;

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography className={classes.sectionTitle}>
                        {t("I tuoi indirizzi")}
                    </Typography>
                </Grid>
                <Grid item>
                    <OutlinedButton variant="outlined" onClick={() => goToAddressForm()}>{t("Aggiungi Indirizzo")}</OutlinedButton>
                </Grid>
            </Grid>               
            {addresses.length>0 ? 
                addresses.map((address,index) => (
                    <AddressCard 
                        key={index} 
                        address={address} 
                        openAddress={goToAddressForm} 
                        deleteAddress={onDeleteAddress}
                        canDelete={(address.b_billing || address.b_shipping) ? false : true}
                    />
                ))
            : (
                <Typography>
                    {t("Non sono presenti indirizzi associati al tuo profilo al momento.")}
                </Typography>
            )}                    
        </>
    );
}

export default ProfileAddresses