import { useCallback } from "react"
import { useTranslation } from "react-i18next";
import { ORDER_SHIPPING_TYPE } from "@kimiya/core/core/combined-fields-mapper"
import { ORDER_STATUSES } from "@kimiya/core/core/defaults-values"

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import useStyle from "@kimiya/custom/order/order.style"

const OrderStatusShipping = ({ order }) => {
    
    const { t } = useTranslation();
    const classes = useStyle();

    const getOrderStatus = useCallback(() => {        
        if(order.b_authorized) return ORDER_STATUSES.WORKING
        if(order.b_shipped) return ORDER_STATUSES.SHIPPED
        if(order.b_canceled) return ORDER_STATUSES.CANCELED
        if(order.b_payed) return ORDER_STATUSES.PAYED        
        return ORDER_STATUSES.PENDING
    },[order])

    return (
        <Grid container justifyContent="flex-start" alignItems="stretch">
            <Grid item xs={6}>
                <Typography className={classes.sectionTitle}>
                    {t("modalità di spedizione")}:
                </Typography>
                <Typography
                    className={`${classes.sectionText} ${classes.sectionTextMargin}`}
                >
                    {order[ORDER_SHIPPING_TYPE].s_shipping_type}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography className={classes.sectionTitle}>
                    {t("stato ordine")}:
                </Typography>
                <Typography className={classes.orderStatusText}>
                    {getOrderStatus()}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default OrderStatusShipping;
