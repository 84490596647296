import { useTranslation } from "react-i18next";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import KimiyaLogo from "@kimiya/core/images/kimiya-logo-full.svg";
import SupportImage from "@kimiya/core/images/support.svg";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    gridContainer: {
        [theme.breakpoints.down("xs")]: {
            margin: 5,
            "& > .MuiGrid-item": {
                padding: 5,
            },
        },
    },
    supportImage: {
        height: 225,
        [theme.breakpoints.down("xs")]: {
            height: 150,
        },
    },
    registrationCompletedTitle: {
        fontFamily: "Brown-Bold",
        fontSize: 20,
        color: theme.palette.primary.main,
    },
    registrationErrorTitle: {
        fontFamily: "Brown-Bold",
        fontSize: 20,
        color: theme.palette.error.main,
    },
    successIcon: {
        color: theme.palette.success.main,
        fontSize: theme.typography.pxToRem(50),
    },
    warningIcon: {
        color: theme.palette.warning.main,
        fontSize: theme.typography.pxToRem(50),
    },
    pendingIcon: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(50),
    },
    errorIcon: {
        color: theme.palette.error.main,
        fontSize: theme.typography.pxToRem(50),
    },
}));

const HelpPage = ({ history }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Container component="main" maxWidth="xs" className={classes.root}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                classes={{
                    root: classes.gridContainer,
                }}
            >
                <Grid item xs={12} align="center">
                    <Link href="/">
                        <img src={KimiyaLogo} alt="kimiya logo" width={300} />
                    </Link>
                </Grid>
                <Grid item xs={12} align="center">
                    <img
                        src={SupportImage}
                        alt="kimiya support"                        
                        className={classes.supportImage}
                    />
                </Grid>
                <Grid item xs={12} align="center">
                    <Typography variant="h4" color="primary">
                        {t("Hai bisogno di assistenza")}?
                    </Typography>
                </Grid>
                <Grid item xs={12} align="center">
                    <Typography color="primary">
                        {t("Contattaci telefonicamente al numero")}:
                    </Typography>
                </Grid>
                <Grid item xs={12} align="center">                    
                    <Typography>+39 XXX XXXXXXX</Typography>
                </Grid>
                <Grid item xs={12} align="center">
                    <Typography color="primary">
                        {t("Oppure via email all'indirizzo")}:
                    </Typography>
                </Grid>
                <Grid item xs={12} align="center">                    
                    <Typography>info@kimiya.club</Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default HelpPage;
