import { useState } from "react";
import { useQuery } from "react-query";
import { getItemImage } from "@kimiya/core/store/actions/shop";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import Card from "@kimiya/ui/card/card";
import ProductPriceCart from "@kimiya/custom/product/product-price-cart.component";

import FoodPlaceholder from "@kimiya/core/images/food-placeholder.jpeg";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
  },
  imageLoaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cartImage: {
    width: 100,
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  productTitle: {
    fontFamily: "Brown-Light",
    fontSize: 16,
    color: theme.palette.grey5.main,
    letterSpacing: 0.44,
    textTransform: "uppercase",
  },
  productInfo: {
    width: "100%",
  },
  productInfoContainer: {
    height: "100%",
    padding: theme.spacing(2, 2),
  },
}));

const CartCard = ({ id, code, title, price }) => {
  const classes = useStyle();
  const [image, setImage] = useState(FoodPlaceholder);

  const refetchItemImage = async () => {
    const image = await getItemImage(id);
    if (image) {
      setImage(
        `${process.env.REACT_APP_BASE_URL}/files/1/${image.s_file_name}.${image.s_file_ext}`
      );
    }
    return image;
  };

  const { isLoading, error } = useQuery(
    ["item-cart-image", code],
    refetchItemImage
  );

  return (
    <Card size="full" customClasses={classes.root}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item>
          {isLoading && (
            <div className={classes.imageLoaderContainer}>
              <CircularProgress />
            </div>
          )}
          {error && (
            <Typography variant="caption">
              {"An error has occurred: " + error.message}
            </Typography>
          )}
          {!isLoading && (
            <div
              style={{ backgroundImage: `url(${image})` }}
              className={classes.cartImage}
            />
          )}
        </Grid>
        <Grid item xs className={classes.productInfo}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            className={classes.productInfoContainer}
          >
            <Grid item>
              <Typography className={classes.productTitle}>{title}</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.productPrice}>{`${price.toFixed(
                2
              )} €`}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ProductPriceCart itemId={id} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default CartCard;

