import { useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import eventBus from "@kimiya/core/utils/event_bus"
import { createMessage } from "@kimiya/core/store/actions/coaching"
import { toast } from "react-toastify"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import IconButton from "@material-ui/core/IconButton"

import SendIcon from "@material-ui/icons/Send"

import useChatStyles from "@kimiya/custom/coach/chat.styles"

const ChatMessageInput = ({ chat }) => {
    
    const { t } = useTranslation()
    const classes = useChatStyles()
    const [message, setMessage] = useState('')

    const onSendMessage = useCallback(async () => {

        if(!message || message.trim()==='') return;

        const res = await createMessage({ chat, message})
        if(!res){
            toast.error(t('Errore durante l\'invio del messaggio'))
            return;
        }
        eventBus.dispatch("refetchMessages");
        setMessage('')
    },[t, chat, message])

    return (
        <Grid container className={classes.chatInput}>
            <Grid item xs={12}>
                <TextField       
                    id="input"
                    fullWidth
                    placeholder="Scrivi messaggio..."                    
                    multiline
                    maxRows={6}                       
                    value={message}
                    
                    onChange={e => setMessage(e.target.value)}
                    InputProps={{
                        classes: { input:  classes.chatInputTextField },
                        endAdornment: (
                            <IconButton 
                                className={classes.chatInputIcon} 
                                disabled={(!message || message.trim()==='') ? true : false}
                                size="small" 
                                edge="end" 
                                onClick={onSendMessage}
                            >
                                <SendIcon />                                    
                            </IconButton>
                        ),
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default ChatMessageInput