// eslint-disable-next-line import/no-anonymous-default-export
export default {
  TERMS: "mb_agreement_term",
  COUNTRIES: "bf_country",
  COUNTIES: "bf_county",
  REGIONS: "bf_region",
  CITIES: "bf_city",
  FILES: "bf_file",
  ALLERGENS: "mb_allergen",
  NUTRITIOUS: "mb_nutritious_type",
  SHIPPING_TYPES: "mb_shipping_type",
  TRANSACTIONS: "mb_transaction",
  TRANSACTION_ITEMS: "mb_transaction_item",
  SHOP_CATEGORIES: "mb_subcategory",
  SHOP_ITEMS: "mb_item",
  SHOP_ITEM_ALLERGENS: "mb_item_allergen",
  SHOP_ITEM_INGREDIENTS: "mb_item_component",
  SHOP_ITEM_NUTRITIOUS: "mb_item_nutritious",
  FAQ: "mb_help",
  ADDRESSES: "bf_address",
  RESOURCES: "bf_resource",
  PROFILE: "ki_resource_profile",
  PROFILE_TIME_DEFAULT: "ki_resource_profile_event_default_time",
  PROFILE_ALLERGIES: "ki_resource_allergy",
  PROFILE_INTOLERANCES: "ki_resource_intolerance",
  PROFILE_REMEDIES: "ki_resource_remedy",
  PROFILE_MEDICAL_INTERVENTIONS: "ki_resource_medical_intervention",
  PROFILE_DISLIKES: "ki_resource_profile_dislike",
  SUBSCRIPTIONS: "mb_item",
  SUBSCRIPTIONS_DURATION: "mb_license_duration",
  USER_SUBSCRIPTION: "mb_item_serial",
  CHATS: "mb_support",
  CHAT_MESSAGES: "mb_support_message",
  PAYMENT_METHODS: "mb_payment_mode",
};

