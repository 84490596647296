import { useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import useChatStyles from "@kimiya/custom/coach/chat.styles";

const ChatBubble = ({ message, idx }) => {
    const classes = useChatStyles();    
    const user = useSelector(({ auth }) => auth.user);

    if (message.author.id === user.id_resource) {
        return (
            <>
                <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    className={classes.chatBubbleContainer}
                >
                    <Grid item style={{ maxWidth: "75%" }}>
                        <Paper
                            elevation={0}
                            className={
                                idx === 0
                                    ? `${classes.coachMessageBubble} ${classes.coachMessageBubbleFirst}`
                                    : classes.coachMessageBubble
                            }
                        >
                            <Box p={2}>
                                <Typography className={classes.messageText}>{message.text}</Typography>
                            </Box>
                        </Paper>
                    </Grid>                    
                </Grid>
            </>
        );
    }

    return (
        <>
            <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.chatBubbleContainer}
            >
                <Grid item style={{ width: 50 }}>
                    {idx === 0 && (
                        <Tooltip title={`${message.author.firstName} ${message.author.lastName}`}>
                            <Avatar className={classes.chatAvatar}>
                                {`${message.author.firstName[0]}${message.author.lastName[0]}`}
                            </Avatar>
                        </Tooltip>
                    )}
                </Grid>
                <Grid item style={{ maxWidth: "75%" }}>
                    <Paper
                        elevation={0}
                        className={
                            idx === 0
                                ? `${classes.userMessageBubble} ${classes.userhMessageBubbleFirst}`
                                : classes.userMessageBubble
                        }
                    >
                        <Box p={2}>
                            <Typography className={classes.messageText}>{message.text}</Typography>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default ChatBubble;
