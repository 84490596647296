import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  badge: {
    right: -5,
    top: 15,
    color: theme.palette.common.white,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);