import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const ContainedButton = withStyles((theme) => ({
    root: {
        boxShadow: "none",
        textTransform: "none",
        "&:hover": {
            boxShadow: "none",
            background: theme.palette.secondary.main
        },
    },
    label: {
        color: theme.palette.primary.contrastText,
    },
}))(Button);

export default ContainedButton;
