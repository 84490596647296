import * as React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: "9999",
        color: theme.palette.common.white,
        flexDirection: "column",
    },
    loadingText: {
        marginTop: theme.spacing(2),
        color: theme.palette.common.white,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightBold,
    },
}));

const BackdropComponent = ({ open, customText }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress color="inherit" />
            <Typography className={classes.loadingText}>
                {t("Loading...Please Wait...")}
            </Typography>
            {customText && (
                <Typography className={classes.loadingText}>
                    {customText}
                </Typography>
            )}
        </Backdrop>
    );
};

BackdropComponent.defaultProps = {
    open: false,
    customText: null,
};
BackdropComponent.propTypes = {
    open: PropTypes.bool.isRequired,
    customText: PropTypes.string,
};

export default BackdropComponent;
