import { API } from '@kimiya/core/core/api.class'
import DB from '@kimiya/core/core/db-tables'

const PROJECT_PREFIX = process.env.REACT_APP_PRJ_PREFIX;

export const createSubscription = async ({id_item, s_payment_method, id_resource_billing, id_address_billing}) => {
    
    try{
        return await API.custom(
            "com.matterbit.project.ProjectAction", 
            "registerSubscription",
            {
                id_item,
                s_payment_method,
                id_resource_billing,
                id_address_billing
            }
        )

    }catch(error){
        console.log(error)
    }
}

export const getUserPaymentMethod = async () => {
    try{
        
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));
        const res = await API.query(DB.PROFILE,`id_resource~${user.id_resource}`)        

        if(!res || res.count===0){
            throw new Error("Errore nella ripresa del profilo utente. Si pregra di riprovare.")
        }

        const subscription = await API.custom(
            "work.bitframe.api.StripeAction", 
            "getPaymentMethodForResource",
            {
                id_resource_billing:  res.rows[0].id_resource_billing===0 ? ''+user.id_resource : ''+res.rows[0].id_resource_billing
            }            
        )

        return {...subscription, id_resource_billing: res.rows[0].id_resource_billing===0 ? ''+user.id_resource : ''+res.rows[0].id_resource_billing }

    }catch(error){
        console.log(error)
        return null
    }
}

export const updateSubscriptionPaymentMethod  = async ({ s_payment_method, id_resource_billing }) => {
    
    try{
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));
        await API.custom(
            "work.bitframe.api.StripeAction", 
            "updatePaymentMethodForResource",
            {                
                s_payment_method,
                id_item_serial: user.subscription ? ''+user.subscription.id_item_serial : "",
                id_resource_billing
            }
        )

        return true;

    }catch(error){
        console.log('error')
        return false;
    }
}