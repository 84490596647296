import { useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { updateCartItem, removeCartItem } from "@kimiya/core/store/actions/checkout"
import { DialogConsumer } from "@kimiya/context/dialog.context"

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

import LoginDialog from "@kimiya/dialogs/login/login.dialog"

import CartIcon from "@kimiya/core/images/ICO-cart.svg"
import TrashIcon from "@material-ui/icons/Delete"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"

import { makeStyles } from "@material-ui/core/styles"

const useStyle = makeStyles(theme => ({
    productPrice: {
        fontFamily: "Brown-Bold",
        fontSize: 16, 
        color: theme.palette.primary.main,
        letterSpacing: 0.15
    },
    productQty: {
        fontFamily: "Brown-Bold",
        fontSize: 16, 
        color: theme.palette.primary.main,                
        padding: 0,
        margin: 0
    }
}))

const ProductPriceCart = ({ itemId, price }) => {
    
    const classes = useStyle()
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const user = useSelector(({ auth }) => auth.user);
    const cartItem = useSelector(({ checkout, auth }) => {
        if(!auth.user) return  null;
        if(!checkout?.cart?.items || checkout.cart.items.length === 0) return null;
        return checkout.cart.items.find(item => item.id_item === itemId)
    })

    const addToCart = async () => {
        setLoading(true)
        await dispatch(updateCartItem({ itemId, quantity: 1 }))
        setLoading(false)
    }

    const updateQty = async (action) => {
        setLoading(true)
        if(action==='sub'){  
            if(cartItem.m_quantity===1){
                await dispatch(removeCartItem({ itemTransactionId: cartItem.id_transaction_item }));
                setLoading(false)
                return;
            }
            await dispatch(updateCartItem({ itemId, quantity: -1 }))
            setLoading(false)
            return;
        }
        await dispatch(updateCartItem({ itemId, quantity: 1 }))
        setLoading(false)
    }

    return (
        <DialogConsumer>
            {({ showDialog}) => (
                <Grid container justifyContent="space-between" alignItems="center" style={{ height: '100%', padding: '0 15px 0 0'}}>
                    <Grid item>
                        <Typography className={classes.productPrice}>{price}</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                !cartItem ? (
                                    <Grid item>
                                        <IconButton 
                                            size="small" 
                                            onClick={() => {
                                                if(user){
                                                    addToCart();
                                                    return;
                                                }
                                                showDialog(LoginDialog, {
                                                    title: 'Effettua il Login',
                                                })
                                            }}
                                        >
                                            <img src={CartIcon} alt="cart" />
                                        </IconButton>
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid item>
                                            <IconButton size="small" onClick={() => updateQty("sub")} >
                                                {cartItem.m_quantity===1 ? <TrashIcon size={14} color="primary" /> : <RemoveIcon size={14} color="primary" />}
                                            </IconButton>
                                        </Grid>
                                        <Grid item>                                        
                                            <Typography className={classes.productQty}>{cartItem.m_quantity}</Typography>
                                        </Grid>          
                                        <Grid item>
                                            <IconButton size="small" onClick={() => updateQty("add")}>
                                                <AddIcon size={14} color="primary" />
                                            </IconButton>
                                        </Grid>
                                    </>
                                )                           
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </DialogConsumer>
    )
}

export default ProductPriceCart