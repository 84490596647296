import { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux"
import {     
    getShippingTypes, 
    setCartShippingType, 
} from "@kimiya/core/store/actions/checkout"
import { toast } from "react-toastify";

import MenuItem from "@material-ui/core/MenuItem";
import StyledSelect from "@kimiya/ui/select/select.component";
import CircularProgress from "@material-ui/core/CircularProgress";

const CheckoutShippingType = () => {

    const { t } = useTranslation();    
    const dispatch = useDispatch();
    const cart = useSelector(({ checkout }) => checkout.cart || null )
    const [shippingTypes, setShippingTypes] = useState()

    const fetchShippingType = useCallback(async () => {
        const res = await getShippingTypes();
        if(!res){
            toast.error('Errore durante la ripresa delle modalità di spedizione!');
            return;
        }
        setShippingTypes(res)
    },[])

    const onShippingTypeChange = async (event) => {
        if(!event.target.value || event.target.value===0) return;
        dispatch(setCartShippingType({ idShippingType: event.target.value }))
    };

    useEffect(() => {        
        fetchShippingType()
    },[fetchShippingType])

    if(!cart || !shippingTypes) return <CircularProgress />

    return (
        <StyledSelect
            label={t("Modalità di spedizione")}
            variant="outlined"                                    
            value={cart?.id_shipping_type || 0}                                    
            fullWidth
            placeholder={t("Seleziona modalità")}
            onChange={onShippingTypeChange}
        >
            <MenuItem value={0}>
                -- {t('Seleziona modalità di spedizione')} --
            </MenuItem>
            {shippingTypes && shippingTypes.map(shippingType => (
                <MenuItem 
                    key={shippingType.id_shipping_type} 
                    value={shippingType.id_shipping_type}
                >
                    {t(shippingType.s_shipping_type)}
                </MenuItem>
            ))}
        </StyledSelect>
    )
}

export default CheckoutShippingType