import * as React from "react";
import PropTypes from "prop-types";

import DefaultLayout from "./default.layout";
import ClearLayout from "./clear.layout";
import CoachLayout from "./coach.layout";

const Layouts = ({ layout, sidebar, children }) => {
    window.scrollTo(0, 0);    

    switch (layout) {
        case "clear":
            return (
                <ClearLayout>
                    {children}
                </ClearLayout>
            );
        case "coach":
            return (
                <CoachLayout>
                    {children}
                </CoachLayout>
            );
        case "default":
            return (
                <DefaultLayout                 
                    sidebar={sidebar}
                >
                    {children}
                </DefaultLayout>
            );
        default:
            return (
                <ClearLayout>
                    {children}
                </ClearLayout>
            );
    }
};

Layouts.defaultProps = {
    layout: "default",
};

Layouts.propTypes = {
    layout: PropTypes.oneOf(["default", "clear","coach"]),    
};

export default Layouts;
