import { CardElement } from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
    hidePostalCode: true,
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

export default function CardInput({ onChange }) {
    return (
        <CardElement        
            onChange={({ empty, complete, error }) =>
                onChange({ empty, complete, error })
            }
            options={CARD_ELEMENT_OPTIONS}
        />
    );
}
