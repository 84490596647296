import { useState, useMemo, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getChat } from "@kimiya/core/store/actions/coaching";

import Grid from "@material-ui/core/Grid"

import ChatSidebar from "@kimiya/core/components/custom/coach/chat-sidebar/chat-sidebar"
import Chat from "@kimiya/core/components/custom/coach/chat/chat"
import NoChat from "@kimiya/core/components/custom/coach/no-chat/no-chat"

import useChatStyles from "@kimiya/custom/coach/chat.styles"
import ChatUserInfoPanel from "@kimiya/core/components/custom/coach/user-info-panel/user-info-panel"

const CoachDashboard = () => {

    const classes = useChatStyles()
    const dispatch = useDispatch()
    const [showUserInfo, setShowUserInfo] = useState(false)    
    const activeChat = useSelector(({ coaching }) => coaching.chat)

    const refetchChat = useCallback((id_chat) => {
        dispatch(getChat(id_chat));
    }, [dispatch]);

    const onSelectChat = chat => {
        refetchChat(chat.id_support)
        setShowUserInfo(true)
    }

    const toggleUserPanel = useCallback(() => {
        setShowUserInfo(!showUserInfo)   
    },[showUserInfo])

    const renderChat = useMemo(() => {
        if(!activeChat) return <NoChat />
        return (
            <Chat
                chat={activeChat}
                toggleUserPanel={toggleUserPanel}
                isUserPanelOpen={showUserInfo}
            />
        )
    },[activeChat, showUserInfo, toggleUserPanel])

    const renderUserPanel = useMemo(() => {
        if(!showUserInfo) return null;        
        return (
            <Grid item xs={3} className={classes.clientInfo}>
                <ChatUserInfoPanel chat={activeChat} />
            </Grid>
        )
    },[showUserInfo, classes, activeChat]) 

    return (
        <Grid container justifyContent="flex-start" alignItems="stretch" className={classes.root}>
            <Grid item xs={3} className={classes.sidebar}>
                <ChatSidebar 
                    onSelect={onSelectChat}
                />
            </Grid>
            <Grid item xs={showUserInfo ? 6 : 9} className={classes.chat}>
                {renderChat}
            </Grid>
            {renderUserPanel}
        </Grid>
    )
}

export default CoachDashboard