import { useTranslation } from "react-i18next";
import {
    CHAT_USER_RESOURCE,
    USER_SUBSCRIPTION,
    USER_SUBSCRIPTION_DETAILS,
} from "@kimiya/core/core/combined-fields-mapper";
import { format } from "date-fns"

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";

import UserIcon from "@material-ui/icons/Person";
import PaymentIcon from '@material-ui/icons/Payment';
import ScheduleIcon from '@material-ui/icons/Schedule';

import useChatStyles from "@kimiya/custom/coach/chat.styles";

const ChatUserInfoPanel = ({ chat }) => {
    const classes = useChatStyles();
    const { t } = useTranslation();

    if(!chat) return null

    return (
        <>
            <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                className={classes.userInfoPanelHeader}
            >                
                <Grid item>
                    <Typography
                        className={`${classes.chatHeaderTitle} ${classes.userInfoTitle}`}
                    >
                        User Info
                    </Typography>
                </Grid>
            </Grid>
            <List className={classes.sibebarList}>
                <ListSubheader disableGutters>
                    <ListItem component="div">
                        <ListItemAvatar>
                            <Avatar className={classes.chatAvatar}>
                                <UserIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${chat[CHAT_USER_RESOURCE].s_resource} (#${chat.id_resource_asking})`}
                            primaryTypographyProps={{
                                className: classes.coachText,
                                noWrap: true,
                            }}
                            secondary={chat[CHAT_USER_RESOURCE].s_username}
                        />
                    </ListItem>
                    <Divider />
                </ListSubheader>
                <ListItem component="div">
                    <ListItemAvatar>
                        <Avatar className={classes.chatAvatar}>
                            <PaymentIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={t('Tipo Abbonamento')}
                        primaryTypographyProps={{                            
                            noWrap: true,
                        }}
                        secondary={
                            <Grid container direction={'column'}>
                                <Grid item><Typography variant="subtitle2">{chat[CHAT_USER_RESOURCE][USER_SUBSCRIPTION][USER_SUBSCRIPTION_DETAILS].s_item}</Typography></Grid>
                                <Grid item><Typography variant="caption">{chat[CHAT_USER_RESOURCE][USER_SUBSCRIPTION][USER_SUBSCRIPTION_DETAILS].s_item_code}</Typography></Grid>
                            </Grid>                            
                        }
                    />
                </ListItem>
                <ListItem component="div">         
                    <ListItemAvatar>
                        <Avatar className={classes.chatAvatar}>
                            <ScheduleIcon />
                        </Avatar>
                    </ListItemAvatar>           
                    <ListItemText
                        primary={t('Info Abbonamento')}
                        primaryTypographyProps={{                            
                            noWrap: true,
                        }}                        
                        secondary={
                            <Grid container direction={'column'}>
                                <Grid item><Typography variant="subtitle2">{`${t('Inizio')}: ${format(new Date(chat[CHAT_USER_RESOURCE][USER_SUBSCRIPTION].d_start),'dd/MM/yyyy')}`}</Typography></Grid>
                                <Grid item><Typography variant="subtitle2">{`${t('Scadenza')}: ${format(new Date(chat[CHAT_USER_RESOURCE][USER_SUBSCRIPTION].d_end),'dd/MM/yyyy')}`}</Typography></Grid>
                            </Grid>
                        }
                    />
                </ListItem>
            </List>
        </>
    );
};

export default ChatUserInfoPanel;
