import { useState } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query"
import { getCompanyAddresses, getCompanyInfo } from "@kimiya/core/store/actions/profile"

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Backdrop from "@kimiya/ui/backdrop/backdrop"
import AddressCard from "@kimiya/ui/card/address-card"

import { makeStyles } from "@material-ui/core/styles";
import OutlinedButton from "../../ui/button/outlined-button.component";

const useStyle = makeStyles((theme) => ({
    sectionTitle: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(48),
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.pxToRem(28),
            marginBottom: theme.spacing(2)
        }
    },
    sectionSubTitle: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(28),
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,
        marginTop: 20,
        marginBottom: 10,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.pxToRem(18),
        }
    },
    subscriptionText: {
        color: theme.palette.grey5.main,
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(16),
        letterSpacing: 0.44,
    },
}));

const ProfileVAT = () => {

    const classes = useStyle();
    const { t } = useTranslation();
    const history = useHistory();    
    const [companyAddresses, setCompanyAddresses] = useState([])
    const [companyInfo, setCompanyInfo] = useState()

    const goToAddressForm = (address_id) => {        
        history.push(`/profile/vat/edit/${address_id}`)
        return;        
    }

    const refetchCompanyInfo = async () => {
        const companyInfo = await getCompanyInfo()
        const companyAddresses = await getCompanyAddresses()
        setCompanyAddresses(companyAddresses ? companyAddresses : [])
        if(companyInfo)
            setCompanyInfo(companyInfo)
        return {companyAddresses, companyInfo}
    }

    const { isLoading, error } = useQuery(["company-addresses"], refetchCompanyInfo)

    if (isLoading) return <Backdrop open customText={t('Fetching addresses...')} />
    if (error) return "An error has occurred: " + error.message;

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography className={classes.sectionTitle}>
                        {t("Gestione P.IVA")}
                    </Typography>
                </Grid>
            </Grid>

            {companyInfo && (
                <>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography className={classes.sectionSubTitle}>
                                {t("Informazioni aziendali")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <OutlinedButton onClick={() => {}}>
                                {t('Modifica')}
                            </OutlinedButton>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-start" alignItems="flex-start" direction="column" spacing={2}>
                        <Grid item xs={12} container justifyContent="flex-start" alignItems="center" spacing={1}>
                            <Grid item><strong>{t('Ragione Sociale')}:</strong></Grid>
                            <Grid item>{companyInfo.s_denomination}</Grid>
                        </Grid>
                        <Grid item xs={12} container justifyContent="flex-start" alignItems="center" spacing={1}>
                            <Grid item><strong>{t('P.IVA')}:</strong></Grid>
                            <Grid item>{companyInfo.s_tax_code}</Grid>
                        </Grid>
                        <Grid item xs={12} container justifyContent="flex-start" alignItems="center" spacing={1}>
                            <Grid item><strong>{t('C.F.')}:</strong></Grid>
                            <Grid item>{companyInfo.s_tax_code2}</Grid>
                        </Grid>
                    </Grid>
                </>
            )}

            {companyAddresses.length>0 ?
                (
                    <>
                        <Typography className={classes.sectionSubTitle}>
                            {t('Indirizzo Aziendale')}
                        </Typography>
                        <Typography className={classes.subscriptionText}>
                            {t('I dati relativi al seguente indirizzo verranno utilizzati come dati predefiniti per la fatturazione degli ordini.')}
                        </Typography>
                        {companyAddresses.map((address,index) => (
                            <AddressCard
                                key={index}
                                address={address}
                                hideFlags
                                openAddress={goToAddressForm}
                            />
                        ))}
                    </>
                )
                : (
                    <Typography className={classes.subscriptionText}>
                        {t('Se hai bisogno di creare un profilo aziendale per la ricezione delle fatture, contattaci all\'indirizzo email info@kimiya.club per creare il tuo profilo aziendale.')}
                    </Typography>
                )
            }
        </>
    );
}

export default ProfileVAT