import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { verifyEmail } from "@kimiya/core/store/actions/auth";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import KimiyaLogoBlue from "@kimiya/core/images/kimiya-logo-blue.svg";
import KimiyaLogo from "@kimiya/core/images/logo-kimiya.svg";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import PendingIcon from "@material-ui/icons/Schedule";
import ErrorIcon from "@material-ui/icons/Cancel";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    registrationCompletedTitle: {
        fontFamily: "Brown-Bold",
        fontSize: 20,
        color: theme.palette.primary.main,
    },
    registrationErrorTitle: {
        fontFamily: "Brown-Bold",
        fontSize: 20,
        color: theme.palette.error.main,
    },
    successIcon: {
        color: theme.palette.success.main,
        fontSize: theme.typography.pxToRem(50),
    },
    warningIcon: {
        color: theme.palette.warning.main,
        fontSize: theme.typography.pxToRem(50),
    },
    pendingIcon: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(50),
    },
    errorIcon: {
        color: theme.palette.error.main,
        fontSize: theme.typography.pxToRem(50),
    },
}));

const UserVerificationSuccess = ({ history }) => {
    const [verified, setVerified] = useState();
    const [error, setError] = useState();
    const classes = useStyles();
    const { t } = useTranslation();
    const { token, secret, id_resource, email } = useParams();

    const verifyEmailHandler = async () => {
        const res = await verifyEmail(token, secret, id_resource, email);
        if (res !== true) {
            setVerified(false);
            if (res !== false) {
                setError(res);
            }
            return false;
        }
        setVerified(res);
        return res;
    };

    useQuery(["verify-email", token, secret, id_resource, email], verifyEmailHandler, {
        enabled: token && secret && id_resource && email ? true : false,
    });

    const renderVerificationBody = useMemo(() => {
        if (verified === undefined) {
            return (
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item>
                        <PendingIcon className={classes.pendingIcon} />
                    </Grid>
                    <Grid item>
                        <Typography
                            className={classes.registrationCompletedTitle}
                        >
                            {t("Attendere...Verifica della mail in corso...")}
                        </Typography>
                    </Grid>
                </Grid>
            );
        }
        if (verified === false) {

            return (
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item>
                        <ErrorIcon className={classes.errorIcon} />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.registrationErrorTitle}>
                            {!error
                                ? t(
                                      "Attenzione! Non è stato possibile verificare la mail. Si prega di riprovare o di contattarci per ricevere assistenza."
                                  )
                                : error}
                        </Typography>
                    </Grid>
                </Grid>
            );
        }

        return (
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
            >
                <Grid item>
                    <DoneAllIcon className={classes.successIcon} />
                </Grid>
                <Grid item>
                    <Typography className={classes.registrationCompletedTitle}>
                        {t("Email verificata con successo!")}
                    </Typography>
                </Grid>
            </Grid>
        );
    }, [verified, error, t, classes]);

    return (
        <Container component="main" maxWidth="xs" className={classes.root}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={3}
            >
                <Grid item xs={12} align="center">
                    <img src={KimiyaLogoBlue} alt="kimiya logo blue" />
                </Grid>
                <Grid item xs={12} align="center">
                    <img src={KimiyaLogo} alt="kimiya logo" />
                </Grid>
                <Grid item xs={12}>
                    {renderVerificationBody}
                </Grid>                
            </Grid>
        </Container>
    );
};

export default UserVerificationSuccess;
