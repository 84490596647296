import { useState, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMutation } from 'react-query'
import { login, resendVerifactionEmail } from "@kimiya/core/store/actions/auth";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux"
import { login as loginReducer } from "@kimiya/core/store/reducers/auth.reducer";

import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";

import DialogTitle from "@kimiya/dialogs/components/dialog-title";
import ContainedButton from "@kimiya/ui/button/contained-button.component";
import OutlinedButton from "@kimiya/ui/button/outlined-button.component";
import Input from "@kimiya/ui/input/input.component";

import { makeStyles } from "@material-ui/core/styles";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    menuDialogContent: {
        backgroundColor: theme.palette.primary.main,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    inputMargin: {
        marginBottom: theme.spacing(2),
    },
    dialogActions: {
        marginTop: theme.spacing(4),
    },
    forgotLink: {
        fontSize: 14,
        color: theme.palette.secondary.main
    }
}));

const ResendEmailTost = ({ resendEmail }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Typography>
                    {t("Utente non confermato. Segui le istruzioni che ti sono state inviate via posta elettronica per effettuare la conferma. Oppure se non hai ricevuto la mail clicca qui sotto")}:
                </Typography>
            </Grid>
            <Grid item>
                <ContainedButton variant="contained" classes={{ root: classes.resendEmailButton, label: classes.resendEmailButtonLabel }} onClick={resendEmail}>
                    {t("Re-invia Email di Conferma")}
                </ContainedButton>
            </Grid>            
        </Grid>
    )
}

const LoginDialog = ({ onRequestClose, title, bottomComponent }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const closeDialog = () => {
        onRequestClose();
    };

    const resendVerificationEmailHandler = async () => {
        const res = await resendVerifactionEmail(email);
        if(!res){
            toast.error(t("Errore durante l'invio della mail di verifica"))
            return false
        }

        toast.success(t("Email di verifica inviata nuovamente con successo!"))
        return true;
    }

    const loginMutation = useMutation(async () => {
        const user = await login(email, password)        
        if(!user){
            toast.error(t('Ops! Could not complete the operation'))            
            return false
        }else{                
            if(user==="NOT_CONFIRMED"){                    
                toast.error(<ResendEmailTost resendEmail={resendVerificationEmailHandler} />,{
                    autoClose: 20000,    
                })
                return false;
            }
            toast.error(user,{ autoClose: 10000 })            
        }
        dispatch(loginReducer(user))
        toast.success(t('Operation completed successfully'))
        onRequestClose();
    });

    const handleFormSubmit = () => {
        return !email || !password;
    };

    const goToRegister = () => history.push("/register");
    const goToResetPassword = () => history.push("/reset-password");

    return (
        <Dialog
            maxWidth={"xs"}
            open
            TransitionComponent={Transition}
            onClose={closeDialog}
            >
            <form
                className={classes.form}
                noValidate
                onSubmit={(e) => {
                    e.preventDefault();
                    loginMutation.mutate()
                }}
                autoComplete="off"
            >
                <DialogTitle
                    title={title}
                    bottomComponent={bottomComponent}
                    closeDialog={closeDialog}
                />
                <DialogContent className={classes.dialogContent}>
                        <Input
                            autoComplete="off"
                            required
                            fullWidth
                            id="email"
                            label="Email address"
                            name="email"
                            type="email"
                            value={email}
                            autoFocus
                            onChange={(e) => setEmail(e.target.value)}
                            className={classes.inputMargin}
                        />
                        <Input
                            autoComplete="off"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className={classes.inputMargin}
                        />
                        <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Grid item>
                                <Typography
                                    onClick={goToResetPassword}
                                    className={classes.forgotLink}
                                >
                                    {t("Forgot your password?")}
                                </Typography>
                            </Grid>
                        </Grid>                
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <OutlinedButton
                        fullWidth
                        onClick={goToRegister}
                        variant="outlined"
                    >
                        {t("Registrati")}
                    </OutlinedButton>
                    <ContainedButton
                        type="submit"
                        fullWidth
                        disabled={handleFormSubmit()}
                        color="secondary"
                        variant="contained"
                    >
                        {t("Login")}
                    </ContainedButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default LoginDialog;
