import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { requestResetPassword } from "@kimiya/core/store/actions/auth" 

import Typography from "@material-ui/core/Typography";

import ContainedButton from "@kimiya/core/components/ui/button/contained-button.component";
import OutlinedButton from "@kimiya/core/components/ui/button/outlined-button.component";
import Input from "@kimiya/core/components/ui/input/input.component";
import Backdrop from "@kimiya/ui/backdrop/backdrop";

import useStyles from "@kimiya/custom/reset-password/reset-password.styles"

const RequestResetPassword = () => {

    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [error, setError] = useState();

    const goToLogin = () => history.push("/login");

    const isRequestResetPassDisabled = () => {
        return !email || (email && email.trim() === "");
    };

    const requestResetPassowrdHandler = async () => {
        setLoading(true);            
        const sent = await requestResetPassword({ s_username: email });
        if (!sent) {
            toast.error(t("Errore durante l'invio della mail di reset della password. Si prega di riprovare o di contattare il nostro team di supporto. Grazie"));
            setLoading(false);
            return false;
        }

        toast.success(t("Email di reset inviata con successo all'indirizzo fornito. Clicca sul link per procedere con il reset della password."));
        setLoading(false);
        history.push("/reset-password-request-success");            
    };

    return (
        <div>
            <Input
                error={error ? true : false}
                variant="outlined"                        
                required
                fullWidth
                id="email"
                label={t("La tua email")}
                placeholder={t("Inserisci la tua email")}
                name="email"
                value={email}
                autoComplete="email"
                onChange={(e) => {
                    setEmail(e.target.value);
                    setError(null);
                }}
                autoFocus
                helperText={error ? error : null}
                className={classes.inputMargin}
            />
            <ContainedButton
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isRequestResetPassDisabled()}
                onClick={requestResetPassowrdHandler}
            >
                {t("Richiedi reset passowrd")}
            </ContainedButton>
            <Typography className={classes.buttonsSeparator}>{t('oppure')}</Typography>
            <OutlinedButton
                fullWidth
                variant="outlined"
                color="secondary"
                className={classes.goBackButton}
                onClick={goToLogin}
            >
                {t("Torna Indietro")}
            </OutlinedButton>
            <Backdrop open={loading} />                    
        </div>
    )
}

export default RequestResetPassword