import { useTranslation } from "react-i18next";
import { DialogConsumer } from "@kimiya/context/dialog.context";
import { useSelector, useDispatch } from "react-redux"
import { setCartShippingAddress } from "@kimiya/core/store/actions/checkout"
import { CHECKOUT_SHIPPING_ADDRESS } from "@kimiya/core/core/combined-fields-mapper"

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import OutlinedButton from "@kimiya/ui/button/outlined-button.component";

import CheckoutShippingAddressDialog from "@kimiya/core/components/dialogs/checkout-shipping-address/checkout-shipping-address.dialog";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({    
    sectionTitle: {
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.secondary.main,
        marginBottom: theme.spacing(1),
    },
    sectionTextBold: {
        fontFamily: "Brown-Bold",
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.grey5.main,
        letterSpacing: 0.44,
    },
    sectionText: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.grey5.main,
        letterSpacing: 0.44,
    },
    sectionTextMargin: {
        marginBottom: theme.spacing(1)
    },
    editAddressButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

const CheckoutShippingAddress = ({ sectionTitle }) => {
    
    const { t } = useTranslation();    
    const classes = useStyle();
    const dispatch = useDispatch();
    const cart = useSelector(({ checkout }) => checkout.cart || null )    

    const onShippingAddressChange = async (idShippingAddress) => {
        if(!idShippingAddress) return;
        dispatch(setCartShippingAddress({ idShippingAddress }))
    };

    return (
        <DialogConsumer>
            {({ showDialog }) => (       
                <>
                    <Typography className={classes.sectionTitle}>
                        {t('spedito a')}:
                    </Typography>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography className={classes.sectionTextBold}>
                                {cart[CHECKOUT_SHIPPING_ADDRESS].s_denomination}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <OutlinedButton 
                                size="small"
                                className={classes.editAddressButton}
                                onClick={() => showDialog(CheckoutShippingAddressDialog, {
                                        title: t("Seleziona indirizzo di spedizione"),                                        
                                        callback: (address) => onShippingAddressChange(address)  
                                    })
                                }
                            >
                                {t("Modifica")}
                            </OutlinedButton>
                        </Grid>
                    </Grid>                    
                    <Typography className={classes.sectionText}>
                        {`${cart[CHECKOUT_SHIPPING_ADDRESS].s_address} ${cart[CHECKOUT_SHIPPING_ADDRESS].s_zip_code || ''}`}
                    </Typography>
                    <Typography className={classes.sectionText}>                                        
                        {`${cart[CHECKOUT_SHIPPING_ADDRESS].s_city} ${cart[CHECKOUT_SHIPPING_ADDRESS].s_county} ${cart[CHECKOUT_SHIPPING_ADDRESS].s_country}`}
                    </Typography>
                </>         
            )}
        </DialogConsumer>
    )
}

export default CheckoutShippingAddress