import * as yup from "yup";
import i18n from "@kimiya/core/i18n/";

export const addressValidationSchema = {
    s_denomination: yup
        .string(i18n.t("Inserisci il nome dell'indirizzo"))
        .required(i18n.t("Il nome dell'indirizzo è obbligatorio")),
    s_address: yup
        .string(i18n.t("Inserisci l'indirizzo con il civico"))
        .required(i18n.t("L'indirizzo è obbligatorio")),
    s_zip_code: yup
        .string(i18n.t("Inserisci il CAP"))
        .required(i18n.t("Il CAP è obbligatorio")),
    id_city: yup
        .string(i18n.t("Inserisci la città"))
        .required(i18n.t("La città è obbligatoria")),
    id_county: yup
        .string(i18n.t("Inserisci il comune"))
        .required(i18n.t("Il comune è obbligatorio")),
    id_region: yup
        .string(i18n.t("Inserisci la regione"))
        .required(i18n.t("La regione è obbligatoria")),
    id_country: yup
        .string(i18n.t("Seleziona la nazione"))
        .required(i18n.t("La nazione è obbligatoria")),  
}