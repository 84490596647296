import '@kimiya/core/themes/landing-pages.css';

import Container from '@material-ui/core/Container';
import ContainedButton from "@kimiya/core/components/ui/button/contained-button.component";
import Grid from '@material-ui/core/Grid';
import AziendaMain from '@kimiya/core/images/azienda-main-img.jpg'
import ArrowsDownIcon from '@kimiya/core/images/ICO-arrows-down.svg'
import placeholderContentImg from '@kimiya/core/images/placeholder-content-img.png'

const TeamPage = () => (
    <div>
        <div className="home-full">
            <div className="page-mainImg" style={{ backgroundImage: `url(${AziendaMain})`}}>
                <h2>La nostra azienda</h2>
                <img className="arrows-down" src={ArrowsDownIcon} alt="scroll-down" />
            </div>
        </div>
        <Container maxWidth="lg" className="inner-mainCont">
            <Grid container justifyContent="center" className="landing-mainCont">
                <Grid item xs={12} sm={10} md={8} className="pages-content">
                    <Grid container justifyContent="space-evenly" className="pages-intro-text">
                        <Grid item xs={3}>
                            <h5 className="page-titleSml azienda-txt">Esperti</h5>
                        </Grid>
                        <Grid item xs={9}>
                            <p className="page-textMain">
                                Crediamo fortemente nell’approccio integrato tra esperti del benessere. <br></br><br></br>
                                Proprio per questo Il nostro team di esperti vanta la presenza di professionisti provenienti da diversi ambiti, quali la sfera naturopatica, medica, psicologica, culinaria e sportiva.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <img src={placeholderContentImg} alt="scroll-down" width={'100%'}/>
                    </Grid>
                </Grid>
            </Grid>
            <div className="social">
                    <div className="liner"></div>
                    <h4>Seguici sui social</h4>
                    <div className="social_buttons">
                        <p>Seguici su nostri canali social e rimani sempre aggiornato sulle nostre novità e promozioni</p>
                        <ContainedButton 
                            variant="contained" 
                            className="instaBut landingBut bigBut"
                            href="https://www.instagram.com/kimiya_nutrition/"
                            target="_blank"
                            rel="noopener"
                        >
                            @KIMIYA_OFFICIAL
                        </ContainedButton>
                        <ContainedButton 
                            variant="contained" 
                            className="fbBut landingBut bigBut"
                            href="https://www.facebook.com/profile.php?id=100086437812998"
                            target="_blank"
                            rel="noopener"
                        >
                            /KIMIYA_OFFICIAL
                        </ContainedButton>
                    </div>
                </div>
        </Container>
    </div>
)

export default TeamPage