import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

import DialogTitle from "@kimiya/dialogs/components/dialog-title";
import ContainedButton from "@kimiya/ui/button/contained-button.component";
import OutlinedButton from "@kimiya/ui/button/outlined-button.component";
import Input from "@kimiya/ui/input/input.component"

import { makeStyles } from "@material-ui/core/styles";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    dialogText: {
        fontFamily: "Brown-Light",
        color: theme.palette.grey5.main,
        fontSize: theme.typography.pxToRem(16),
        letterSpacing: 0.44,
        marginBottom: theme.spacing(2)
    },
    dialogTextQuit: {
        fontFamily: "Brown-Bold",
        color: theme.palette.primary.main,
    },    
    dialogActions: {
        marginTop: theme.spacing(4),
    }
}));

const LoginDialog = ({ onRequestClose, title, bottomComponent }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const closeDialog = () => {
        onRequestClose();
    };

    return (
        <Dialog
            maxWidth={"xs"}
            open
            TransitionComponent={Transition}
            onClose={closeDialog}
            >            
            <DialogTitle
                title={title}
                bottomComponent={bottomComponent}
                closeDialog={closeDialog}
            />
            <DialogContent className={classes.dialogContent}>
                <Typography className={classes.dialogText}>Sei sicuro di voler disdire il tuo abbonamento?</Typography>
                <Typography className={classes.dialogText}>Disdicendo il tuo abbonamento dall'attuale data di scadenza non potrai più accedere alle funzioni relative.</Typography>
                <Typography className={classes.dialogText}>Se vuoi confermare scrivi <span className={classes.dialogTextQuit}>DISDICI</span> in questo form.</Typography>                
                <Input
                    fullWidth
                    label={t("conferma disdetta")}
                    placeholder={t("DISDICI")}
                    helperText={t("Conferma scrivendo DISDICI nel campo qui sopra")}
                    className={classes.inputMargin}
                />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <OutlinedButton
                    fullWidth
                    onClick={closeDialog}
                    variant="outlined"
                >
                    {t("Annulla")}
                </OutlinedButton>
                <ContainedButton                    
                    fullWidth
                    onClick={closeDialog}
                    color="secondary"
                    variant="contained"
                >
                    {t("Disdici")}
                </ContainedButton>
            </DialogActions>            
        </Dialog>
    );
};

export default LoginDialog;
