import * as yup from "yup";
import i18n from "@kimiya/core/i18n/";

const fiscalCodeRegex =
    /^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$/;

export const registrationPersonalValidationSchema = {
    name: yup
        .string(i18n.t("Inseriscio il tuo nome"))
        .required(i18n.t("Il nome è obbligatorio")),
    surname: yup
        .string(i18n.t("Inserisci il tuo cognome"))
        .required(i18n.t("Il cognome è obbligatorio")),
    email: yup
        .string(i18n.t("Inserisci la tua email"))
        .email(i18n.t("Inserisci un indirizzo email valido"))
        .required(i18n.t("L' indirizzo email è obbligatorio")),
    // phone: yup
    //     .string(i18n.t("Inserisci il tuo numero di cellulare"))
    //     .required(i18n.t("Il numero di cellulare è obbligatorio")),
    gender: yup
        .string(i18n.t("Seleziona il sesso"))
        .required(i18n.t("Il sesso è obbligatorio")),
    password: yup
        .string(i18n.t("Inserisci la tua password"))
        .min(8, i18n.t("La password deve essere lunga almeno 8 caratteri"))
        .required(i18n.t("La password è obbligatoria")),
    confirmPassword: yup
        .string(i18n.t("Ripeti password"))
        .required(i18n.t("La password di conferma è obbligatoria"))
        .oneOf(
            [yup.ref("password"), null],
            i18n.t("Le password non coincidono")
        ),
    b_terms: yup.bool().oneOf([true], "Campo obbligatorio"),
    b_privacy: yup.bool().oneOf([true], "Campo obbligatorio"),
};

export const registrationBillingValidationSchema = {
    s_tax_code: yup
        .string(i18n.t("Inserisci il codice fiscale"))
        .required(i18n.t("Il codice fiscale è obbligatorio"))
        .matches(fiscalCodeRegex, i18n.t("Codice Fiscale non valido")),
    s_address: yup
        .string(i18n.t("Inserisci il tuo indirizzo"))
        .required(i18n.t("L'indirizzo è obbligatorio")),
    s_zip_code: yup
        .string(i18n.t("Inserisci il tuo CAP"))
        .required(i18n.t("Il CAP è obbligatorio"))
        .matches(/^[0-9]+$/, i18n.t("Il CAP deve contenere solo numeri"))
        .min(5, i18n.t('Il CAP deve essere di 5 caratteri'))
        .max(5, i18n.t('Il CAP deve essere di 5 caratteri')),
    id_city: yup
        .string(i18n.t("Seleziona il Comune"))
        .required(i18n.t("Il Comune è obbligatoria")),
    id_county: yup
        .string(i18n.t("Seleziona Provincia"))
        .required(i18n.t("La Provincia è obbligatoria")),
    id_region: yup
        .string(i18n.t("Seleziona Regione"))
        .required(i18n.t("La Regione è obbligatoria")),
    b_company: yup.bool(),
    s_denomination: yup
        .string(i18n.t("Inserisci la ragione sociale"))
        .when("b_company", {
            is: true,
            then: yup
                .string()
                .required(i18n.t("La ragione sociale è obbligatoria")),
        }),
    s_tax_code_company: yup
        .string(i18n.t("Inserisci il codice fiscale aziendale"))
        .when("b_company", {
            is: true,
            then: yup
                .string()
                .required(i18n.t("Il codice fiscale aziendale è obbligatorio")),
        }),
    s_vat_code_company: yup
        .string(i18n.t("Inserisci P.IVA"))
        .when("b_company", {
            is: true,
            then: yup
                .string()
                .required(i18n.t("La P.IVA è obbligatoria"))
                .min(11, i18n.t('Il P.IVA aziendale deve essere di 11 caratteri'))
                .max(11, i18n.t('Il P.IVA aziendale deve essere di 11 caratteri')),
        }),
    s_address_company: yup
        .string(i18n.t("Inserisci indirizzo aziendale"))
        .when("b_company", {
            is: true,
            then: yup
                .string()
                .required(i18n.t("L'indirizzo aziendale è obbligatorio")),
        }),
    s_zip_code_company: yup
        .string(i18n.t("Inserisci CAP aziendale"))
        .when("b_company", {
            is: true,
            then: yup
                .string()
                .required(i18n.t("Il CAP aziendale è obbligatorio"))
                .matches(/^[0-9]+$/, i18n.t("Il CAP deve contenere solo numeri"))
                .min(5, i18n.t('Il CAP aziendale deve essere di 5 caratteri'))
                .max(5, i18n.t('Il CAP aziendale deve essere di 5 caratteri')),
        }),
    id_city_company: yup
        .string(i18n.t("Seleziona il Comune aziendale"))
        .when("b_company", {
            is: true,
            then: yup
                .string()
                .required(i18n.t("Il Comune dell'azienda è obbligatoria")),
        }),
    id_county_company: yup
        .string(i18n.t("Seleziona Provincia aziendale"))
        .when("b_company", {
            is: true,
            then: yup
                .string()
                .required(i18n.t("La Provincia dell'azienda è obbligatoria")),
        }),
    id_region_company: yup
        .string(i18n.t("Seleziona Regione aziendale"))
        .when("b_company", {
            is: true,
            then: yup
                .string()
                .required(i18n.t("La Regione dell'azienda è obbligatoria")),
        }),
    
    s_fe_sdi: yup
        .string(i18n.t("Inserisci codice SDI"))
        .when(["b_company","s_fe_type"], {
            is: (b_company, s_fe_type) => {                
                return (b_company===true && s_fe_type==='sdi');
            },
            then: yup
                .string()
                .min(7, i18n.t("Il codice SDI deve essere lungo 7 caratteri"))
                .max(7, i18n.t("Il codice SDI deve essere lungo 7 caratteri"))
        }),
    s_fe_pec: yup
        .string(i18n.t("Inserisci PEC per Fatturazione Elettronica"))
        .when(["b_company", "s_fe_type"], {
            is: (b_company, s_fe_type) => {                
                return (b_company===true && s_fe_type==='pec');
            },
            then: yup
                .string()
                .email(i18n.t("Inserisci un indirizzo email valido"))
                .required(i18n.t("La PEC è obbligatoria")),
        }),    
    b_ads: yup.bool().oneOf([true], "Campo obbligatorio"),
    b_ads2: yup.bool().oneOf([true], "Campo obbligatorio"),
    b_privacy_medical: yup.bool().oneOf([true], "Campo obbligatorio"),
};
