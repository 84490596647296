import { red } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createTheme({
    typography: {
        h1: {
            fontFamily: "Brown-Bold",
            fontWeight: "bold",
        },
        h2: {
            fontFamily: "Brown-Bold",
            fontWeight: "bold",
        },
        h3: {
            fontFamily: "Brown-Bold",
            fontWeight: "bold",
        },
        h4: {
            fontFamily: "Brown-Bold",
            fontWeight: "bold",
        },
        h5: {
            fontFamily: "Brown-Bold",
            fontWeight: "bold",
        },
        h6: {
            fontFamily: "Brown-Bold",
            fontWeight: "bold",
        },
        body1: {
            fontFamily: "Brown-Regular",
        },
        body2: {
            fontFamily: "Brown-Regular",
        },
        subtitle1: {
            fontFamily: "Brown-Regular",
        },
        subtitle2: {
            fontFamily: "Brown-Regular",
        },
        button: {
            fontFamily: "Brown-Regular",
        },
        caption: {
            fontFamily: "Brown-Regular",
        },
        overline: {
            fontFamily: "Brown-Regular",
        },
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                "@font-face": ['Brown-Regular', 'Brown-Bold','Brown-Light'],
                html: {
                    height: "100%"
                },
                body: { 
                    height: "100%",
                    background: "#faefe5",
                },
                "#root": {
                    height: "100%" 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: 20,
            },
            label: {
                textTransform: "uppercase",
            },
            outlinedSecondary: {
                border: "1px solid #D9AA90",
            },
        },        
    },
    palette: {
        primary: {
            main: "#171717",
            contrastText: "#fff",
        },
        secondary: {
            main: "#D9AA90",
            contrastText: "#D9AA90",
        },
        error: {
            main: red.A400,
        },
        background: {
            default: "#faefe5",
        },                        
        grey1: { main: "#EFEFEF" },
        grey2: { main: "#BDBDBD" },
        grey3: { main: "#9E9E9E" },
        grey5: { main: "#313131" },        
    },
});

export default theme;
