import { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-query"
import { getSettings, updateSettings } from "@kimiya/core/store/actions/profile"
import { toast } from "react-toastify";
import { keysValuesGenerator } from "@kimiya/core/utils"

import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';

import Backdrop from "@kimiya/ui/backdrop/backdrop"

import { makeStyles } from "@material-ui/core/styles"

const useStyle = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(4)
    },
    fullWidth: {
        width: "100%"
    },    
    divider: {
        margin: theme.spacing(1,0,3,0)
    },
    notificationText: {
        fontFamily: "Brown-Light",
        color: theme.palette.grey3.main,
        fontSize: theme.typography.pxToRem(24),        
    },
    switchContainer: {
        margin:theme.spacing(2,0)
    },
    switchText: {
        fontFamily: "Brown-Light",
        color: theme.palette.grey5.main,
        fontSize: theme.typography.pxToRem(16),        
        marginLeft: theme.spacing(3)
    }
}))

const ProfileSettingsForm = () => {

    const { t } = useTranslation();
    const classes = useStyle()

    const [form, setForm] = useState();    

    const handleChange = async (event) => {
        await setForm({ ...form, [event.target.name]: event.target.checked });
        settingsMutation.mutate()
    };

    const settingsMutation = useMutation(async () => {
        const body = await keysValuesGenerator(form)        
        const res = await  updateSettings(body)
        if(!res){
            toast.error(t('Ops! Could not complete the operation'))            
            return false
        }
        
        toast.success(t('Operation completed successfully'))                        
        return res
    })


    const refetchSettings = async () => {        
        const settings = await getSettings()
        setForm({
            id_resource_profile: settings.id_resource_profile,
            b_notify_push: settings.b_notify_push,
            b_notify_email: settings.b_notify_email,
            b_notify_sms: settings.b_notify_sms,
        })
        return settings
    }
    
    const { isLoading, error } = useQuery(["settings"], refetchSettings)

    if (isLoading || !form) return <Backdrop open customText={t('Fetching settings...')} />
    if (error) return "An error has occurred: " + error.message;

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <FormControl component="fieldset" className={classes.fullWidth}>
                    <FormLabel component="legend" className={classes.notificationText}>{t("Notifiche")}</FormLabel>
                    <Divider className={classes.divider} />
                    <FormGroup>
                        {/* <FormControlLabel
                            control={
                                <Switch
                                    checked={form.b_notify_push}
                                    onChange={handleChange}
                                    name="b_notify_push"
                                />
                            }
                            classes={{ root: classes.switchContainer, label: classes.switchText }}
                            label="Notifiche Push"
                        /> */}
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={form.b_notify_email}
                                    onChange={handleChange}
                                    name="b_notify_email"
                                />
                            }
                            classes={{ root: classes.switchContainer, label: classes.switchText }}
                            label="Notifiche Email"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={form.b_notify_sms}
                                    onChange={handleChange}
                                    name="b_notify_sms"
                                />
                            }
                            classes={{ root: classes.switchContainer, label: classes.switchText }}
                            label="Notifiche SMS"
                        />
                    </FormGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default ProfileSettingsForm;
