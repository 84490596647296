import { axios } from '@kimiya/core/utils/axios'

const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const createMobilePaymentIntent = async ({ id_transaction, auth_token, id_resource_nav }) => {

    try {        
        const customBody = {      
            auth_token,
            id_resource_nav,          
            action_type: 'custom',
            action_custom_class: "work.bitframe.api.StripeAction",
            action_custom_method: "createPaymentIntent",
            id_transaction
        }

        const { data } = await axios.post(ENDPOINT,customBody)
        
        return data.s_stripe_payment_intent_client_secret;

    } catch (error) {
        console.dir(error)
        return false;
    }
};

export const cartMobilePaymentCompleted = async ({ s_uuid, id_transaction, auth_token, id_resource_nav }) => {

    try {        
        const customBody = {  
            action_type: 'custom',
            action_custom_class: "com.matterbit.project.ProjectAction",
            action_custom_method: "createOrderFromCart",
            auth_token,
            id_resource_nav,
            id_transaction: id_transaction,
            s_uuid: s_uuid,
        }

        await axios.post(ENDPOINT,customBody)
        
        return true

    } catch (error) {
        console.dir(error)
        return false;
    }
};