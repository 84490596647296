import { axios } from '@kimiya/core/utils/axios'

const PROJECT_PREFIX = process.env.REACT_APP_PRJ_PREFIX;
const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const PUBLIC_TKN = process.env.REACT_APP_PUBLIC_TKN;
const PUBLIC_SCRT = process.env.REACT_APP_PUBLIC_SCRT;

class Api {    

    constructor(){                
        this._auth_token = localStorage.getItem(`${PROJECT_PREFIX}TOKEN`)
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`))
        this._id_resource_nav = user ? user.id_resource : null
    }    
    

    async custom(action_custom_class,action_custom_method,body){
        try {
            const token = localStorage.getItem(`${PROJECT_PREFIX}TOKEN`)
            const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`))
                    
            let customBody = {                
                action_type: 'custom',
                action_custom_class,
                action_custom_method,
                ...body
            }
            if(token) customBody.auth_token = token;
            if(user) customBody.id_resource_nav = user.id_resource;
                        
            const { data } = await axios.post(ENDPOINT,customBody)
            
            return data 

        } catch (error) {
            console.dir(error)
            throw (error)
        }
    }

    async query(criteria_table, criteria_where = '', criteria_select = '', criteria_order = '', criteria_include = ''){
        try {
            const token = localStorage.getItem(`${PROJECT_PREFIX}TOKEN`)
            const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`))

            let body = {                
                action_type: 'read',
                criteria_table,
                criteria_select,
                criteria_where,
                criteria_order,
                criteria_include
            }

            if(!user || !token){
                body = {
                    ...body,
                    auth_token: PUBLIC_TKN,
                    auth_secret: PUBLIC_SCRT,
                    id_resource_nav: "-1",
                }    
            }else{
                body = {
                    ...body,
                    auth_token: token,
                    id_resource_nav: user.id_resource
                }
            }            

            const { data } = await axios.post(ENDPOINT,body)

            return data 

        } catch (error) {
            console.dir(error.response)
            throw new Error(error.response)
        }        
    }

    async create(criteria_table, keys_values){        
        try {            
            const token = localStorage.getItem(`${PROJECT_PREFIX}TOKEN`)
            const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`))
            this._id_resource_nav = user ? user.id_resource : null

            let body = {                                
                action_type: 'create',
                criteria_table,
                keys_values
            }

            if(!user || !token){
                body = {
                    ...body,
                    auth_token: PUBLIC_TKN,
                    auth_secret: PUBLIC_SCRT,
                    id_resource_nav: "-1",
                }    
            }else{
                body = {
                    ...body,
                    auth_token: token,
                    id_resource_nav: user.id_resource,
                }
            }            

            const { data } = await axios.post(ENDPOINT,body)
            
            return data

        } catch (error) {
            console.dir(error.response)
            throw new Error(error.response)
        }
    }

    async update(criteria_table, keys_values){
        try {      
            const token = localStorage.getItem(`${PROJECT_PREFIX}TOKEN`)
            const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`))            

            let body = {                                
                action_type: 'update',
                criteria_table,
                keys_values
            }

            if(!user || !token){
                body = {
                    ...body,
                    auth_token: PUBLIC_TKN,
                    auth_secret: PUBLIC_SCRT,
                    id_resource_nav: "-1",
                }    
            }else{
                body = {
                    ...body,
                    auth_token: token,
                    id_resource_nav: user.id_resource,
                }
            }            

            const { data } = await axios.post(ENDPOINT,body)

            return data

        } catch (error) {
            console.dir(error.response)
            throw new Error(error.response)
        }
    }

    async delete(criteria_table, keys_values){        
        try {
            const token = localStorage.getItem(`${PROJECT_PREFIX}TOKEN`)
            const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`))            

            let body = {                                
                action_type: 'delete',
                criteria_table,
                keys_values
            }

            if(!user || !token){
                body = {
                    ...body,
                    auth_token: PUBLIC_TKN,
                    auth_secret: PUBLIC_SCRT,
                    id_resource_nav: "-1",
                }    
            }            
            body = {
                ...body,
                auth_token: token,
                id_resource_nav: user.id_resource,
            }

            const { data } = await axios.post(ENDPOINT,body);

            return data

        } catch (error) {
            console.dir(error.response)
            throw new Error(error.response)
        }
    } 
    
}


export const API = new Api()