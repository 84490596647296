import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";

import SubscriptionCard from "@kimiya/ui/card/subscription-card";
import ContainedButton from "@kimiya/ui/button/contained-button.component";
import OutlinedButton from "@kimiya/ui/button/outlined-button.component";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
    pageTitle: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(48),
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,
    },
    sectionTitle: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(24),
        color: theme.palette.grey3.main,
        margin: theme.spacing(4, 0, 1),
    },    
    paymentTypeText: {
        color: theme.palette.secondary.main,
        fontSize: theme.typography.pxToRem(14),
    },
    paymentTypeValueText: {
        fontFamily: "Brown-Light",
        color: theme.palette.grey5.main,
        fontSize: theme.typography.pxToRem(16),
    },
}));

const subscriptions = [
    {
        id: 1,
        name: "FULL",
        price: 669.9,
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },
    {
        id: 2,
        name: "PREMIUM",
        price: 1069.9,
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },
];

const SubscriptionUpgrade = () => {
    const classes = useStyle();
    const { t } = useTranslation();
    const history = useHistory();

    const [paymentType, setPaymentType] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState();

    const goBackToSubscription = () => history.push("/profile/subscription");
    const goToCheckout = () => history.push("/profile/subscription/checkout");

    return (
        <>
            <Typography className={classes.pageTitle}>
                {t("Effettua un upgrade dell'abbonamento")}
            </Typography>
            <Grid container direction="column">
                <Grid item xs={12}>
                    <Typography className={classes.sectionTitle}>
                        {t("Il tuo attuale abbonamento")}:
                    </Typography>
                    <SubscriptionCard
                        subscription={{
                            name: "BASIC",
                            price: 10.00,
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography className={classes.sectionTitle}>
                        {t("Scegli il tuo upgrade")}:
                    </Typography>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography className={classes.paymentTypeText}>
                                {t("Pagamento")}:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid
                                container
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item>
                                    <Typography
                                        className={classes.paymentTypeValueText}
                                    >
                                        Mensile
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Switch
                                        checked={paymentType}
                                        onChange={() =>
                                            setPaymentType(!paymentType)
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className={classes.paymentTypeValueText}
                                    >
                                        Annuale
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {subscriptions.map((subscription, index) => (
                        <SubscriptionCard
                            key={index}
                            selected={selectedSubscription === (index+1)}
                            subscription={subscription}
                            onSelect={setSelectedSubscription}
                        />
                    ))}
                </Grid>
            </Grid>

            <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={4}
            >
                <Grid item>
                    <OutlinedButton
                        variant="outlined"
                        onClick={goBackToSubscription}
                    >
                        {t("Annulla Upgrade")}
                    </OutlinedButton>
                </Grid>
                <Grid item>
                    <ContainedButton
                        variant="contained"
                        color="secondary"
                        onClick={goToCheckout}
                        disabled={!selectedSubscription}
                    >
                        {t("Seleziona e Prosegui")}
                    </ContainedButton>
                </Grid>
            </Grid>
        </>
    );
};

export default SubscriptionUpgrade;
