import { configureStore } from "@reduxjs/toolkit";
import authReducer from "@kimiya/core/store/reducers/auth.reducer";
import coachingReducer from "@kimiya/core/store/reducers/coaching.reducer";
import checkoutSlice from "@kimiya/core/store/reducers/checkout.reducer"

export default configureStore({
    reducer: {
        auth: authReducer,
        coaching: coachingReducer,
        checkout: checkoutSlice
    },
});
