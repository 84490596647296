import { useState, useMemo, Fragment } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useQuery } from 'react-query'
import { getShopCategory, getItems } from "@kimiya/core/store/actions/shop";
import _ from "lodash"

import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"

import ProductCard from "@kimiya/ui/card/product-card"
import Backdrop from "@kimiya/ui/backdrop/backdrop"

import { makeStyles } from "@material-ui/core/styles"

const useStyle = makeStyles(theme => ({
    root: {
        paddingBottom: theme.spacing(5)
    },
    sectionTitle: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(48),
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0
    },
    sectionSubTitle: { 
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(22),
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,    
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.pxToRem(18),
        }    
    },
    sectionSpacer: {
        marginTop: theme.spacing(4)
    }
}))

const Shop = () => {

    const classes = useStyle()
    const { t } = useTranslation();
    const { product_category } = useParams();
    const [category, setCategory] = useState()
    const [categories, setCategories] = useState()
    const [items, setItems] = useState([])


    const refretchShopCategories = async () => {
        const category = await getShopCategory(product_category)
        setCategory(category)
        return category
    }

    const refretchShopItems = async () => {        
        const items = await getItems(product_category)
        if(product_category){
            setItems(items ? items : [])
            return;
        }
        const groupedItems = _.groupBy(items,"id_subcategory");
        setItems(groupedItems)
        let categories = _.uniqBy([...items.map(i => i['id_subcategory|mb_item|id_subcategory|mb_subcategory'])],"id_subcategory")        
        setCategories(_.orderBy(categories,"n_priority"))
        return items
    }

    const renderShopTitle = useMemo(() => {        
        if(!product_category) return t("Shop")
        return category?.s_subcategory
    },[t, product_category, category])

    const { isLoading, error } = useQuery(["shop-categories", product_category], refretchShopCategories,{
        enabled: product_category ? true : false
    });
    
    const { isLoadingItems, errorItems } = useQuery(["shop-items", product_category], refretchShopItems);

    if (isLoading || isLoadingItems) return <Backdrop open customText={t('Caricando lo Store...')} />
    if (error || errorItems) return "An error has occurred: " + error.message;

    return (
        <div className={classes.root}>
            <Typography className={classes.sectionTitle}>{renderShopTitle}</Typography>
            <Grid container justifyContent="flex-start" alignItems="stretch" spacing={2}>
                {items 
                    ? product_category 
                        ? items.length>0 
                            ? items.map((item,index) => 
                                <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
                                    <ProductCard 
                                        id={item.id_item}
                                        code={item.s_item_code}
                                        category={item.s_code}
                                        title={item.s_item}
                                        descr={item.s_item_descr}
                                        price={`${item.m_sell_price_taxed.toFixed(2)}€`}
                                    />
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <Typography>{t("Ops! Al momento non sono presenti articoli.")}</Typography>
                                </Grid>
                            )
                    : categories?.map((category) => {

                        if(!items[category?.id_subcategory]){
                            return (
                                <Fragment key={category?.id_subcategory}>
                                    <Grid item xs={12} className={classes.sectionSpacer}>
                                        <Typography className={classes.sectionSubTitle}>{category?.s_subcategory || 'n.d.'}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>{t("Ops! Al momento non sono presenti articoli.")}</Typography>
                                    </Grid>
                                </Fragment>
                            )
                        }

                        return (
                            <Fragment key={category?.id_subcategory}>
                                <Grid item xs={12} className={classes.sectionSpacer}>
                                    <Typography className={classes.sectionSubTitle}>{category?.s_subcategory || 'n.d.'}</Typography>
                                </Grid>
                                {items[category?.id_subcategory].length>0 
                                    ? items[category.id_subcategory].map((item,index) => 
                                        <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
                                            <ProductCard 
                                                id={item.id_item}
                                                code={item.s_item_code}
                                                category={item.s_code}
                                                title={item.s_item}
                                                descr={item.s_item_descr}
                                                price={`${item.m_sell_price_taxed.toFixed(2)}€`}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12}>
                                            <CircularProgress />
                                        </Grid>
                                    )
                                }
                            </Fragment>
                        )
                    })
                : <Backdrop open customText={t('Caricando gli articoli...Attendere...')} />                    
            }
            </Grid>
        </div>
    )
}
export default Shop