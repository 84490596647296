import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "@kimiya/core/themes/theme";
import { Provider } from "react-redux";
import store from "@kimiya/core/store";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ToastContainer, toast } from 'react-toastify';
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import 'react-toastify/dist/ReactToastify.css';
import {
    DialogProvider,
    DialogRoot,
} from "@kimiya/core/context/dialog.context";
import Router from "@kimiya/core/routes";
import reportWebVitals from "./reportWebVitals";
import './i18n'
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

ReactDOM.render(    
    <Elements stripe={stripePromise}>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DialogProvider>
                            <DialogRoot />
                            <CssBaseline />
                            <Router />
                            <ToastContainer closeOnClick={true} autoClose={2000} position={toast.POSITION.BOTTOM_RIGHT} />
                            <ReactQueryDevtools />
                        </DialogProvider>
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
            </QueryClientProvider>
        </Provider>
    </Elements>,
    document.getElementById("root")
);

reportWebVitals();
