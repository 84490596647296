import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import { logout } from "@kimiya/core/store/reducers/auth.reducer";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";

import OutlinedButton from "@kimiya/ui/button/outlined-button.component";

import ProfileIcon from "@material-ui/icons/Person";
import OrdersIcon from "@material-ui/icons/PlaylistAddCheck";

import useStyles from "@kimiya/ui/toolbar/toolbar.styles";

const UserMenu = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory()
    const user = useSelector(({ auth }) => (auth ? auth.user : null));

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logoutHandler = async (e) => {
        e.preventDefault();
        handleClose();
        dispatch(logout());
        history.push('/login')
    };

    const goToOrders = () => history.push("/orders");
    const goToProfile = () => history.push("/profile/account");

    return (
        <div>
            <Button
                startIcon={<Avatar className={classes.userAvatar}><ProfileIcon /></Avatar>}
                onClick={handleClick}
                aria-controls="user-menu"
                className={classes.userName}
            >
                {user.s_resource}
            </Button>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                elevation={2}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                getContentAnchorEl={null}
                classes={{ paper: classes.userDropdownMenu }}
            >
                {!user.isCoach && user.b_registration_completed && (
                    <div>
                        <MenuItem
                            className={classes.userDropdownMenuItem}                    
                            onClick={goToProfile}
                        >
                            <ListItemIcon className={classes.userDropDownMenuItemIcon}>
                                <ProfileIcon />
                            </ListItemIcon>
                            <Typography
                                className={classes.userDropDownMenuItemText}
                                variant="inherit"
                            >
                                {t("Profilo")}
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            className={classes.userDropdownMenuItem}
                            onClick={goToOrders}
                        >
                            <ListItemIcon className={classes.userDropDownMenuItemIcon}>
                                <OrdersIcon />
                            </ListItemIcon>
                            <Typography
                                className={classes.userDropDownMenuItemText}
                                variant="inherit"
                            >
                                {t("I miei ordini")}
                            </Typography>
                        </MenuItem>
                        <Divider className={classes.userDropdownMenuDivider} />
                    </div>
                )}
                <MenuItem className={classes.userDropDownLogoutContainer}>
                    <OutlinedButton variant="outlined" onClick={logoutHandler}>
                        Logout
                    </OutlinedButton>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default UserMenu;
