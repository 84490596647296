import * as React from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(4),
    },
    fieldLabel: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: "600",
    },
}));

const ChangePasswordForm = ({ form, handleChange }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid item xs={12} sm={4}>
                        <Typography className={classes.fieldLabel}>
                            {t("New Password")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type={!form.showPassword ? "password" : "text"}
                            placeholder={t("New Password")}
                            value={form.password}
                            onChange={(e) =>
                                handleChange("password", e.target.value)
                            }
                            InputProps={{
                                endAdornment: form.showPassword ? (
                                    <VisibilityOffIcon
                                        onClick={() =>
                                            handleChange("showPassword", false)
                                        }
                                    />
                                ) : (
                                    <VisibilityIcon
                                        onClick={() =>
                                            handleChange("showPassword", true)
                                        }
                                    />
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid item xs={12} sm={4}>
                        <Typography className={classes.fieldLabel}>
                            {t("Repeat New Password")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type={!form.showConfPassword ? "password" : "text"}
                            placeholder={t("Repeat New Password")}
                            value={form.confirmPassword}
                            onChange={(e) =>
                                handleChange("confirmPassword", e.target.value)
                            }
                            InputProps={{
                                endAdornment: form.showConfPassword ? (
                                    <VisibilityOffIcon
                                        onClick={() =>
                                            handleChange(
                                                "showConfPassword",
                                                false
                                            )
                                        }
                                    />
                                ) : (
                                    <VisibilityIcon
                                        onClick={() =>
                                            handleChange(
                                                "showConfPassword",
                                                true
                                            )
                                        }
                                    />
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default ChangePasswordForm;
