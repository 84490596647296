import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'

import { makeStyles } from '@material-ui/core/styles'

import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        fontFamily: "Brown-Light",
        fontSize: 20,
        color: theme.palette.primary.main,        
        textTransform: "uppercase"
    },    
    closeIcon: {
        padding: 0,
        '&:hover':{
            backgroundColor: 'transparent'
        }
    }
}))

const DialogTitleComponent = ({ title, bottomComponent, closeDialog }) => {

    const classes = useStyles()

    return (
        <DialogTitle>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
                <Grid item xs={10}>
                    <Typography className={classes.dialogTitle}>{title}</Typography>
                    {bottomComponent}
                </Grid>
                <Grid item>
                    <IconButton edge="end" onClick={closeDialog} color="secondary" className={classes.closeIcon}>
                        <CancelIcon />
                    </IconButton>
                </Grid>                
            </Grid>
        </DialogTitle>
    )
}

DialogTitleComponent.defaultProps = {
    title: '',
    closeDialog: () => {},
    bottomComponent: null    
}

DialogTitleComponent.propTypes = {
    title: PropTypes.string.isRequired,
    closeDialog: PropTypes.func.isRequired,
    bottomComponent: PropTypes.object
}

export default DialogTitleComponent