import { useState } from "react"
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { getFaqs } from "@kimiya/core/store/actions/faq";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Backdrop from "@kimiya/ui/backdrop/backdrop"
import FaqCard from "@kimiya/ui/card/faq-card"

import { makeStyles } from "@material-ui/core/styles"

const useStyle = makeStyles(theme => ({    
    root: {
        minHeight: '80%'
    },
    titleContainer: {
        textAlign: "center"
    },
    pageTitle: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(48),
        color: theme.palette.primary.main,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}))

const Shop = () => {
    const { t } = useTranslation()
    const classes = useStyle()
    const [faqs, setFaqs] = useState()

    const refetchFaqs = async () => {
        const faqs = await getFaqs()        
        setFaqs(faqs ? faqs : [])
        return faqs
    }

    const { isLoading, error } = useQuery(["faqs"], refetchFaqs);

    if (isLoading || !faqs) return <Backdrop open customText={t('Fetching shop categories...')} />
    if (error) return "An error has occurred: " + error.message;

    return (
        <Container maxWidth="md" className={classes.root}>
            <div className={classes.titleContainer}>
                <Typography className={classes.pageTitle}>{t("Domande e Risposte")}</Typography>
            </div>
                <Grid container direction="column" spacing={2}>
                    {faqs.length>0 ? 
                        faqs.map((faq,index) => (
                            <Grid item key={index}>
                                <FaqCard
                                    question={faq.s_help_title}
                                    answer={faq.s_help}
                                />
                            </Grid>
                        ))
                    : (
                        <Typography>{t("Non ci sono FAQ disponibili al momento")}</Typography>
                    )}
                </Grid>
        </Container>
    )
}

export default Shop