import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useSelector } from "react-redux"
import * as yup from "yup";
import { useQuery, useMutation } from "react-query"
import { getCities, getRegions, getCounties } from "@kimiya/core/store/actions/shared"
import { getAddress, createAddress, updateAddress } from "@kimiya/core/store/actions/profile"
import { toast } from "react-toastify";
import { keysValuesGenerator } from "@kimiya/core/utils"
import { addressValidationSchema } from "@kimiya/core/utils/validation_schemas"

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import Backdrop from "@kimiya/ui/backdrop/backdrop"
import ContainedButton from "@kimiya/ui/button/contained-button.component";
import Input from "@kimiya/ui/input/input.component";
import StyledSelect from "@kimiya/ui/select/select.component";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
    sectionTitle: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(48),
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,
    },
    switchContainer: {
        margin:theme.spacing(2,0)
    },
    switchText: {
        fontFamily: "Brown-Light",
        color: theme.palette.grey5.main,
        fontSize: theme.typography.pxToRem(16),        
        marginLeft: theme.spacing(3)
    }
}));


const initAddressForm = {
    id_address: null,
    s_denomination: "",
    s_address: "",        
    s_zip_code: "",
    id_city: "",
    id_county: "",
    id_region: "",
    id_country: 380,
    s_note: "",
    b_billing: false,
    b_shipping: false,
    b_active: true
};

const AddressForm = ({ history, addressId, callback }) => {
    const classes = useStyle();
    const { t } = useTranslation();
    const { address_id, profile_section } = useParams();
    const user = useSelector(({ auth}) => auth.user)
    
    const [address, setAddress] = useState(initAddressForm)
    const [cities, setCities] = useState()
    const [regions, setRegions] = useState()
    const [counties, setCounties] = useState()        

    const validationSchema = yup.object(addressValidationSchema);
    
    const formik = useFormik({
        initialValues: address,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => addressMutation.mutate(values)
    });

    const addressMutation = useMutation(async (address) => {            
        if(!address.id_address) delete address.id_address;
        const body = await keysValuesGenerator(address)        
        const res = address.id_address ? await  updateAddress(body) : await createAddress(body)        
        if(!res){
            toast.error(t('Ops! Could not complete the operation'))            
            return false
        }
        
        toast.success(t('Operation completed successfully'))                
        if(address_id){
            history.push(`/profile/${profile_section}`)
        }
        if(callback){
            callback(res)
        }
        return res
    })

    const refetchAddress = async () => {        
        const address = await getAddress(address_id || addressId)        
        let payload = {
            id_address: address.id_address,
            s_denomination: address.s_denomination,
            s_address: address.s_address,        
            s_zip_code: address.s_zip_code,
            id_city: address.id_city,
            id_county: address.id_county,
            id_region: address.id_region,
            id_country: 380,
            s_note: address.s_note,            
            b_active: true
        }
        if(profile_section!=='vat'){
            payload.b_billing = address.b_billing;
            payload.b_shipping = address.b_shipping;
        }
        setAddress(payload)        
        return address
    }

    const { isLoadingAddress, errorAddress } = useQuery(["address",address_id], refetchAddress, {
        enabled: (!!address_id || !!addressId)
    })

    const refetchRegions = async () => {        
        const regions = await getRegions(formik.values.id_country)
        setRegions(regions ? regions : [])
        return regions
    }
    
    const refetchCounties = async () => {        
        const counties = await getCounties(formik.values.id_region)
        setCounties(counties ? counties : [])
        return counties
    }    

    const refetchCities = async () => {        
        const cities = await getCities(formik.values.id_county)
        setCities(cities ? cities : [])
        return cities
    }
    
    const { isLoadingRegions, errorRegions } = useQuery(["regions"], refetchRegions, {
        enabled: !!formik.values.id_country
    })
    const { isLoadingCounties, errorCounties } = useQuery(["counties"], refetchCounties,{
        enabled: !!formik.values.id_region
    })
    const { isLoadingCities, errorCities } = useQuery(["cities"], refetchCities,{
        enabled: !!formik.values.id_county
    })
    
    if (isLoadingAddress) return <Backdrop open customText={t('Fetching address...')} />
    if (isLoadingRegions) return <Backdrop open customText={t('Fetching regions...')} />
    if (isLoadingCounties) return <Backdrop open customText={t('Fetching counties...')} />
    if (isLoadingCities) return <Backdrop open customText={t('Fetching cities...')} />

    if (errorAddress) return "An error has occurred: " + errorAddress.message;
    if (errorCities) return "An error has occurred: " + errorCities.message;
    if (errorRegions) return "An error has occurred: " + errorRegions.message;
    if (errorCounties) return "An error has occurred: " + errorCounties.message;

    return (                    
        <form
            className={classes.root}
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
        >
            <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >
                <Grid item xs={12}>
                    <Input
                        autoComplete="off"
                        id="s_denomination"
                        name="s_denomination"
                        label={t("Intestazione Indirizzo")}
                        variant="outlined"
                        fullWidth
                        required
                        value={formik.values.s_denomination}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.s_denomination &&
                            Boolean(formik.errors.s_denomination)
                        }
                        helperText={
                            formik.touched.s_denomination &&
                            formik.errors.s_denomination
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        autoComplete="off"
                        id="s_address"
                        name="s_address"
                        label={t("Indirizzo e Civico")}
                        variant="outlined"
                        fullWidth
                        required
                        value={formik.values.s_address}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.s_address &&
                            Boolean(formik.errors.s_address)
                        }
                        helperText={
                            formik.touched.s_address && formik.errors.s_address
                        }
                    />
                </Grid>                    
                <Grid item xs={12}>
                    <Input
                        autoComplete="off"
                        id="s_zip_code"
                        name="s_zip_code"
                        label={t("CAP")}
                        variant="outlined"
                        fullWidth
                        required
                        value={formik.values.s_zip_code}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.s_zip_code && Boolean(formik.errors.s_zip_code)
                        }
                        helperText={formik.touched.s_zip_code && formik.errors.s_zip_code}
                    />
                </Grid>
                
                
                {regions && (
                    <Grid item xs={12}>
                        <StyledSelect
                            autoComplete="off"
                            id="id_region"
                            name="id_region"
                            label={t("Regione")}
                            variant="outlined"
                            fullWidth
                            required
                            value={formik.values.id_region}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.id_region &&
                                Boolean(formik.errors.id_region)
                            }
                            helperText={
                                formik.touched.id_region && formik.errors.id_region
                            }
                        >
                            <MenuItem value="">
                                <em>{t("Scegli Regione")}</em>
                            </MenuItem>
                            {regions.map((region,index) => (
                                <MenuItem key={index} value={region.id_region}>
                                    {region.s_region}
                                </MenuItem>
                            ))}
                        </StyledSelect>
                    </Grid>
                )}
                
                {counties && (
                    <Grid item xs={12}>
                        <StyledSelect
                            autoComplete="off"
                            id="id_county"
                            name="id_county"
                            label={t("Provincia")}
                            variant="outlined"
                            fullWidth
                            required
                            value={formik.values.id_county}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.id_county &&
                                Boolean(formik.errors.id_county)
                            }
                            helperText={
                                formik.touched.id_county && formik.errors.id_county
                            }
                        >
                            <MenuItem value="">
                                <em>{t("Scegli Provincia")}</em>
                            </MenuItem>
                            {counties.map((county,index) => (
                                <MenuItem key={index} value={county.id_county}>
                                    {county.s_county}
                                </MenuItem>
                            ))}
                        </StyledSelect>
                    </Grid>
                )}
                
                {cities && (
                    <Grid item xs={12}>
                        <StyledSelect
                            autoComplete="off"
                            id="id_city"
                            name="id_city"
                            label={t("Città")}
                            variant="outlined"
                            fullWidth
                            required
                            value={formik.values.id_city}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.id_city &&
                                Boolean(formik.errors.id_city)
                            }
                            helperText={
                                formik.touched.id_city && formik.errors.id_city
                            }
                        >
                            <MenuItem value="">
                                <em>{t("Scegli Città")}</em>
                            </MenuItem>
                            {cities.map((city,index) => (
                                <MenuItem key={index} value={city.id_city}>
                                    {city.s_city}
                                </MenuItem>
                            ))}
                        </StyledSelect>
                    </Grid>
                )}
                
                {profile_section !== 'vat' && (
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormGroup>
                                {!user.id_resource_billing && (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={
                                                    formik.values.b_billing
                                                }
                                                onChange={formik.handleChange}
                                                name="b_billing"
                                            />
                                        }
                                        classes={{ root: classes.switchContainer, label: classes.switchText }}
                                        label={t("Predefinito per la fatturazione")}
                                    />
                                )}
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={
                                                formik.values.b_shipping
                                            }
                                            onChange={formik.handleChange}
                                            name="b_shipping"
                                        />
                                    }
                                    classes={{ root: classes.switchContainer, label: classes.switchText }}
                                    label={t("Predefinito per la spedizione")}
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
            <Box my={4}>
                <ContainedButton
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                >
                    {t("Salva")}
                </ContainedButton>
            </Box>
        </form>      
    );
};

export default AddressForm;
