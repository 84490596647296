import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom'
import { useMutation } from 'react-query'
import { login, resendVerifactionEmail } from "@kimiya/core/store/actions/auth";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux"
import { login as loginReducer } from "@kimiya/core/store/reducers/auth.reducer";

import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid'
import Container from "@material-ui/core/Container";

import ContainedButton from "@kimiya/core/components/ui/button/contained-button.component";
import OutlinedButton from "@kimiya/core/components/ui/button/outlined-button.component";
import Input from "@kimiya/core/components/ui/input/input.component";

import KimiyaLogo from '@kimiya/core/images/logo-kimiya.svg'

import { useTheme } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({    
    root: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    welcomeText: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(26),
    },
    welcomeName: {
        color: theme.palette.primary.main,
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(2),
        },
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    inputMargin:{
        marginBottom: theme.spacing(3)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    register: {
        marginTop: theme.spacing(2),
    },
    forgotLink: {
        color: theme.palette.secondary.main,
        cursor: "pointer",
        fontSize: theme.typography.pxToRem(14),
        marginBottom: theme.spacing(2)
    },
    buttonsSeparator:{
        textTransform: "uppercase",
        color: theme.palette.secondary.main,
        textAlign: "center"
    },
    resendEmailButton: {
        backgroundColor: theme.palette.common.white,        
        "&:hover": {
            boxShadow: "none",
            backgroundColor: theme.palette.common.white,
        },
    },
    resendEmailButtonLabel: {
        color: theme.palette.primary.main
    }
}));

const ResendEmailTost = ({ resendEmail }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Typography>
                    {t("Utente non confermato. Segui le istruzioni che ti sono state inviate via posta elettronica per effettuare la conferma. Oppure se non hai ricevuto la mail clicca qui sotto")}:
                </Typography>
            </Grid>
            <Grid item>
                <ContainedButton variant="contained" classes={{ root: classes.resendEmailButton, label: classes.resendEmailButtonLabel }} onClick={resendEmail}>
                    {t("Re-invia Email di Conferma")}
                </ContainedButton>
            </Grid>            
        </Grid>
    )
}

const Login = ({ history }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");    
    const location = useLocation();
    const dispatch = useDispatch()

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const resendVerificationEmailHandler = async () => {
        const res = await resendVerifactionEmail(email);
        if(!res){
            toast.error(t("Errore durante l'invio della mail di verifica"))
            return false
        }

        toast.success(t("Email di verifica inviata nuovamente con successo!"))
        return true;
    }

    const loginMutation = useMutation(async () => {
        const user = await login(email, password)        
        if(typeof user === "string" || user===false){
            if(user===false){
                toast.error(t('Ops! Could not complete the operation'))            
            }else{                
                if(user==="NOT_CONFIRMED"){                    
                    toast.error(<ResendEmailTost resendEmail={resendVerificationEmailHandler} />,{
                        autoClose: 20000,    
                    })
                    return false;
                }
                toast.error(user,{ autoClose: 10000 })            
            }
            return false
        }
        dispatch(loginReducer(user))
        toast.success(t('Operation completed successfully'))        

        if(!user.b_registration_completed){
            history.push("/complete-registration/billing")
            return;
        }

        if(location?.state?.redirect_to){
            history.push(location.state.redirect_to)            
            return;
        }
        history.push("/shop")
        return user
    })

    const handleFormSubmit = () => {
        return !email || !password;
    };

    const goToRegister = () => isMobile ? history.push('/register') : history.push('/register')
    const goToResetPassword = () => history.push('/reset-password')    

    return (
        <Container component="main" maxWidth="xs" className={classes.root}>
            <div className={classes.paper}>
                <img src={KimiyaLogo} width="100%" alt="kimiya logo" />
                <form
                    className={classes.form}
                    noValidate
                    onSubmit={(e) => {
                        e.preventDefault();
                        loginMutation.mutate()
                    }}
                    autoComplete="off"
                >
                    <Input
                        autoComplete="off"                                                
                        required
                        fullWidth
                        id="email"
                        label="Email address"
                        name="email"
                        type="email"
                        value={email}
                        autoFocus
                        onChange={(e) => setEmail(e.target.value)}
                        className={classes.inputMargin}
                    />
                    <Input
                        autoComplete="off"                                                
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Grid container justifyContent="flex-end" alignItems="center">
                        <Grid item>
                            <Typography onClick={goToResetPassword} className={classes.forgotLink}>
                                {t('Hai dimenticato la tua password?')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <ContainedButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        disabled={handleFormSubmit()}
                    >
                        {t("login")}
                    </ContainedButton>
                    <Typography className={classes.buttonsSeparator}>{t('oppure')}</Typography>
                    <OutlinedButton
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        className={classes.register}
                        onClick={goToRegister}
                    >
                        {t("Registrati")}
                    </OutlinedButton>                    
                </form>
            </div>
        </Container>
    );
};

export default Login;
