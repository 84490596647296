import { useState, createContext } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "@kimiya/core/store";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { QueryClient, QueryClientProvider } from "react-query";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const DialogContext = createContext({
    component: null,
    props: {},
    showDialog: () => {},
    hideDialog: () => {},
});

export const DialogProvider = (props) => {
    const showDialog = (component, props = {}) =>
        setState({ ...state, component, props });
    const hideDialog = () => setState({ ...state, component: null, props: {} });

    const [state, setState] = useState({
        component: null,
        props: {},
        showDialog,
        hideDialog,
    });

    return (
        <DialogContext.Provider value={state}>
            <QueryClientProvider client={queryClient}>
                {props.children}
            </QueryClientProvider>
        </DialogContext.Provider>
    );
};

export const DialogConsumer = DialogContext.Consumer;

export const DialogRoot = () => (
    <Elements stripe={stripePromise}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Provider store={store}>
                <Router>
                    <DialogConsumer>
                        {({ component: Component, props, hideDialog }) =>
                            Component ? (
                                <Component {...props} onRequestClose={hideDialog} />
                            ) : null
                        }
                    </DialogConsumer>
                </Router>
            </Provider>
        </MuiPickersUtilsProvider>
    </Elements>
);
