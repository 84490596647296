import { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import passwordValidator from "password-validator";
import { toast } from "react-toastify";
import { resetPassword } from "@kimiya/core/store/actions/auth" 

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import ContainedButton from "@kimiya/core/components/ui/button/contained-button.component";
import Input from "@kimiya/core/components/ui/input/input.component";
import ChangePasswordForm from "@kimiya/forms/change-password/change-password.form";
import Card from "@kimiya/ui/card/card";
import Backdrop from "@kimiya/ui/backdrop/backdrop";

import DoneIcon from "@material-ui/icons/Done";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import useStyles from "@kimiya/custom/reset-password/reset-password.styles"

let schema = new passwordValidator();
schema
    .is().min(8)
    .is().max(24)
    .has().uppercase()
    .has().lowercase()
    .has().digits(2)
    .has().not().spaces();

const validationFields = [
    { field: "min", text: "Minimo 8 caratteri" },
    { field: "max", text: "Massimo 24 caratteri" },
    { field: "lowercase", text: "Contenere caretteri minuscoli" },
    { field: "uppercase", text: "Contenere caretteri maiuscoli" },
    { field: "digits", text: "Contenere almeno 2 numeri" },
    { field: "spaces", text: "Non deve avere spazi" },
];

const ResetPassword = () => {

    const classes = useStyles();
    const { t } = useTranslation();    
    const history = useHistory();
    const { username, token, secret } = useParams();
    
    const [email, setEmail] = useState(username || "");
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        showPassword: false,
        confirmPassword: "",
        showConfPassword: false,
    });

    const handleChange = useCallback(
        (name, value) => {
            setForm({ ...form, [name]: value });
        },
        [form, setForm]
    );

    const resetPassowrdHandler = async () => {
        setLoading(true);
        const res = await resetPassword({s_username: email, s_api_token: token, s_api_secret: secret, s_password: form.password});
        setLoading(false);
        if (!res) {
            toast.error(t("Errore durante il salvataggio della nuova password. Si prega di riprovare. Grazie"));
            return false;
        }
        toast.success(t("Nuova password impostata correttamente. Abbiamo inviato una mail di conferma al tuo indirizzo di posta."));        
        history.push("/reset-password-success");
    };

    const renderValidationFields = useMemo(() => {
        return validationFields.map((item, index) => {
            const validator = form.password
                ? schema.validate(form.password, { list: true })
                : null;
            let itemClass, itemIcon;
            if (!validator) {
                itemClass = classes.inactiveStatus;
                itemIcon = null;
            } else if (validator.indexOf(item.field) !== -1) {
                itemClass = classes.invalidStatus;
                itemIcon = (
                    <ErrorOutlineIcon className={classes.invalidStatus} />
                );
            } else {
                itemClass = classes.validStatus;
                itemIcon = <DoneIcon className={classes.validStatus} />;
            }

            return (
                <ListItem key={index} className={itemClass}>
                    {itemIcon && <ListItemIcon>{itemIcon}</ListItemIcon>}
                    <ListItemText
                        classes={{ primary: classes.validationText }}
                        primary={t(item.text)}
                    />
                </ListItem>
            );
        });
    }, [form, classes, t]);

    const isDisabled = useCallback(() => {
        const validator = schema.validate(form.password, { list: true });
        if (!validator) return true;
        else if (validator && validator.length === 0) {
            if (form.password === form.confirmPassword) {
                return false;
            } else {
                if (!email) {
                    return false;
                } else {
                    return true;
                }
            }
        } else {
            return true;
        }
    }, [form, email]);

    return (
        <>            
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid item xs={12} sm={4}>
                        <Typography className={classes.fieldLabel}>
                            {t("Nuova Password")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Input
                            fullWidth
                            variant="outlined"
                            type={email}
                            placeholder={t("La tua email")}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <ChangePasswordForm form={form} handleChange={handleChange} />
            <Card>
                <Typography>
                    {t("La nuova password deve rispettare le seguenti regole")}:
                </Typography>
                <List dense disablePadding className={classes.validationList}>
                    {renderValidationFields}
                </List>
            </Card>
            <ContainedButton
                type="button"
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
                disabled={isDisabled()}
                onClick={resetPassowrdHandler}
            >
                {t("Imposta la nuova password")}
            </ContainedButton>            
            <Backdrop open={loading} />
        </>
    );
}

export default ResetPassword