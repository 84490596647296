import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DialogConsumer } from "@kimiya/context/dialog.context";
import { useSelector, useDispatch } from "react-redux";
import { resetCartItems } from "@kimiya/core/store/actions/checkout";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import OutlinedButton from "@kimiya/ui/button/outlined-button.component";
import Card from "@kimiya/ui/card/card";
import ConfirmActionDialog from "@kimiya/dialogs/confirm-action/confirm-action.dialog";

import DeleteIcon from "@material-ui/icons/Delete";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  cardTitle: {
    fontFamily: "Brown-Bold",
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(26),
  },
  emptyCartContainer: {
    marginTop: theme.spacing(3),
  },
  emptyCartButton: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.secondary.main,
  },
  cartItemRow: {
    margin: theme.spacing(2, 0),
  },
  cartText: {
    fontFamily: "Brown-Light",
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.grey5.main,
    letterSpacing: 0.44,
  },
  checkoutDivider: {
    margin: theme.spacing(4, 0),
  },
}));

const CheckoutItems = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const cart = useSelector(({ checkout }) => checkout.cart || null);

  const goToCart = () => history.push("/cart");

  const onResetCart = async () => {
    await dispatch(resetCartItems());
    history.push("/shop");
  };

  return (
    <DialogConsumer>
      {({ showDialog }) => (
        <>
          <Card>
            <Typography className={classes.cardTitle}>
              Dettagli ordine
            </Typography>
            <Divider className={classes.checkoutDivider} />
            {!cart ? (
              <CircularProgress />
            ) : (
              cart.items &&
              cart.items.map((item) => (
                <Grid
                  key={item.id_item}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.cartItemRow}
                >
                  <Grid item>
                    <Typography className={classes.cartText}>
                      {item.m_quantity}{t('pz')}. - {item.s_item}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.cartText}>
                      {item.m_price_total_final_gross.toFixed(2)} €
                    </Typography>
                  </Grid>
                </Grid>
              ))
            )}
            <Divider className={classes.checkoutDivider} />
            <OutlinedButton fullWidth variant="outlined" onClick={goToCart}>
              {t("Modifica")}
            </OutlinedButton>
          </Card>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            className={classes.emptyCartContainer}
          >
            <Grid item>
              <Button
                variant="text"
                className={classes.emptyCartButton}
                startIcon={<DeleteIcon />}
                onClick={() =>
                  showDialog(ConfirmActionDialog, {
                    title: t("Conferma Azione"),
                    text: t(
                      "Attenzione! Procedendo si perderanno i tutti i dati inseriti finora."
                    ),
                    callback: () => onResetCart(),
                  })
                }
              >
                {t("Svuota il carrello")}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </DialogConsumer>
  );
};

export default CheckoutItems;

