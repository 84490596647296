import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";

import Card from "@kimiya/ui/card/card"
import AccountProfilePicture from "@kimiya/custom/profile/profile-picture"
import AccountUsernameForm from "@kimiya/forms/profile/username.account.form"
import AccountMobileNumberForm from "@kimiya/core/components/forms/profile/account.form"
import CloseAccountCard from "@kimiya/ui/card/close-account-card"

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
    sectionTitle: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(48),
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.pxToRem(28),
        }
    },
    sectionSubTitle: { 
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(22),
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,    
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.pxToRem(18),
        }    
    },
    cardSection: {
        marginBottom: theme.typography.pxToRem(25)
    }
}));

const ProfileAccount = () => {
    const classes = useStyle();
    const { t } = useTranslation();    

    return (
        <>
            <Typography className={classes.sectionTitle}>
                {t("Il tuo account")}
            </Typography>
            <AccountProfilePicture />
            <Card customClasses={classes.cardSection}>
                <Typography className={classes.sectionSubTitle}>
                    {t("Modifica la tua email")}
                </Typography>
                <Typography>{t('Utilizza questa sezione per gestire il lo username con cui potrai accedere a Kimiya.')}</Typography>
                <Typography>{t('N.B. Al cambio di email verrà richiesto di verificare il nuovo indirizzo per poterlo attivare.')}</Typography>
                <AccountUsernameForm />
            </Card>
            <Card customClasses={classes.cardSection}>
                <Typography className={classes.sectionSubTitle}>
                    {t("Modifica le informazioni del tuo account")}
                </Typography>
                <AccountMobileNumberForm />
            </Card>     
            <CloseAccountCard />       
        </>
    );
};

export default ProfileAccount;
