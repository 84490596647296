import { useMemo, useRef, useState, useEffect } from 'react'
import Container from "@material-ui/core/Container";

import Toolbar from "@kimiya/ui/toolbar/toolbar";
import ShopSidebar from "@kimiya/ui/sidebar/shop-sidebar"
import ProfileSidebar from "@kimiya/core/components/ui/sidebar/profile-sidebar";

import MobileShopMenu from '@kimiya/ui/sidebar/mobile/shop-menu';
import MobileProfileMenu from '@kimiya/ui/sidebar/mobile/profile-menu';
import Footer from "@kimiya/ui/footer/footer"

import { useTheme, useMediaQuery } from "@material-ui/core";
import useStyles from "@kimiya/ui/toolbar/toolbar.styles";

const DefaultLayout = ({ sidebar, children }) => {    
    const theme = useTheme();
    const containerRef = useRef();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));    
    const [height, setHeight] = useState(0);

    const classes = useStyles({ height: height });


    const renderSidebar = useMemo(() => {
        
        switch (sidebar) {
            case "shop": return isMobile ? <MobileShopMenu /> : <ShopSidebar />            
            case "profile": return isMobile ? <MobileProfileMenu /> : <ProfileSidebar />
            
            case "invisible":        
            default: return ''
        }
    }, [sidebar, isMobile])

    
    

    useEffect(() => {
        setHeight(containerRef.current.clientHeight - 64);        
    }, [])

    return (
        <>
            <div 
                className={classes.root} 
                ref={containerRef}
                style={{ position: 'relative' }}
            >
                {!isMobile && renderSidebar}
                <Toolbar />
                <Container maxWidth={false} style={{ overflowY: 'scroll'}}>
                    <main className={sidebar ? isMobile ? classes.mobileContent :classes.content : classes.centeredContent}>
                        <div className={classes.leftContent}>
                            {isMobile && renderSidebar}
                            {children}
                            <Footer />
                        </div>                    
                    </main>                
                </Container>            
            </div>            
        </>
    );
};

export default DefaultLayout;
