import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { registerBilling, getBillingData } from "@kimiya/core/store/actions/auth";
import { refreshUser } from "@kimiya/core/store/reducers/auth.reducer";

import Box from "@material-ui/core/Box";

import Backdrop from "@kimiya/ui/backdrop/backdrop"
import BillingDataForm from "@kimiya/forms/registration/billing-data.form";

import { makeStyles } from "@material-ui/core/styles";

const initForm = {
    s_tax_code: "",
    s_address: "",
    s_zip_code: "",
    id_city: "",
    id_county: "",
    id_region: "",
    id_country: 380,
    b_company: false,
    s_denomination: "",
    s_tax_code_company: "",
    s_vat_code_company: "",
    s_address_company: "",
    s_zip_code_company: "",
    id_city_company: "",
    id_county_company: "",
    id_region_company: "",
    id_country_company: 380,
    s_fe_type: "sdi",
    s_fe_sdi: "",
    s_fe_pec: "",
    b_ads: false,
    b_ads2: false,
    b_privacy_medical: false,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100vh",
    },
    subscriptionTitle: {
        fontFamily: "Brown-Light",
        fontSize: 20,
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.main,
    },
    backButton: {
        textTransform: "Capitalize",
    },
    recapText: {
        fontFamily: "Brown-Light",
        fontSize: 16,
        color: theme.palette.grey5.main,
        letterSpacing: 0.44,
    },
    paymentText: {
        fontSize: 16,
        color: theme.palette.secondary.main,
        marginBottom: theme.typography.pxToRem(15)
    },
    paymentContainer: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid  ${theme.palette.secondary.main}`,
        borderRadius: 20,
        padding: 20
    }    
}));

const BillingDataStep = ({ handleBack, handleNext }) => {
    const dispatch = useDispatch()
    const classes = useStyles();
    const { t } = useTranslation();    
    const billingData = useSelector(({ auth }) => auth.billingData)

    const [form, setForm] = useState(billingData || initForm);
    const [loading, setLoading] = useState(false)    

    useEffect(() => {
        if(!billingData){
            dispatch(getBillingData())
        }
    },[billingData, dispatch])

    const registerBillingMutation = useMutation(async (billingForm) => {        
        let billingData = {}                       
                
        setForm(billingForm);
        setLoading(true)

        billingData = {
            b_company: `${billingForm.b_company}`,
            s_tax_code: billingForm.s_tax_code,
            s_address: billingForm.s_address,
            id_country: "380",
            id_region: ""+billingForm.id_region,
            id_county: ""+billingForm.id_county,
            id_city: ""+billingForm.id_city,
            s_zip_code: billingForm.s_zip_code,
            b_ads2: `${billingForm.b_ads2}`,
            b_ads: `${billingForm.b_ads}`,
            b_privacy_medical: `${billingForm.b_privacy_medical}`,
        };

        if (billingForm.b_company) {
            billingData = {
                ...billingData,
                s_denomination: billingForm.s_denomination,
                s_tax_code_company: billingForm.s_tax_code_company,
                s_vat_code_company: billingForm.s_vat_code_company,
                s_address_company: billingForm.s_address_company,
                s_zip_code_company: billingForm.s_zip_code_company,
                id_country_company: "380",
                id_region_company: ""+billingForm.id_region_company,
                id_county_company: ""+billingForm.id_county_company,
                id_city_company: ""+billingForm.id_city_company,
                s_fe_sdi: billingForm.s_fe_sdi,
            };
        }

        const res = await registerBilling(billingData);
        if (res.error) {
            setLoading(false)
            toast.error(t(res.message));
            return;
        }
        await dispatch(refreshUser())        
        setLoading(false)        
        toast.success(t("Dati salvati con successo!"));        
        handleNext();        
        return res;
    });

    return (
        <Box mt={4} className={classes.root}>
            <Backdrop open={loading} customText={t('Salvando i dati...')} />            
            <BillingDataForm
                form={form}
                handleNext={(billingForm) => registerBillingMutation.mutate(billingForm)}
                handleBack={handleBack}                
            />            
        </Box>
    )
}

export default BillingDataStep