import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(5)
    },
    orderTitle: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(48),
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,
        [theme.breakpoints.down("sm")]:{
            fontSize: theme.typography.pxToRem(38),
        }
    },    
    orderNumber: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(20),
        color: theme.palette.primary.main,
    },
    orderDate: {        
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.secondary.main,
    },
    divider: {
        margin: theme.spacing(2,0)
    },
    sectionTitle: {
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.secondary.main,
        marginBottom: theme.spacing(1),
    },
    sectionText: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.grey5.main,
        letterSpacing: 0.44,
    },
    sectionTextMargin: {
        marginBottom: theme.spacing(3)
    },
    orderRow: {
        marginBottom: theme.spacing(4)
    },
    cartItemRow: {
        margin: theme.spacing(2,0)
    },
    totalRecapText:{ 
        color: theme.palette.primary.main,
        fontFamily: "Brown-Bold",
        fontSize: theme.typography.pxToRem(16),
        letterSpacing: 0.23
    },
    totalRecapContainer: {
        marginBottom: theme.spacing(4)
    },
    orderStatusText: {
        fontFamily: "Brown-Bold",
    },
    noInvoiceText: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(14),
    }
}));