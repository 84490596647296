import * as React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({    
    gridContainer: {
        position: "relative",
        minHeight: "100vh",        
    },
}));

const ClearLayout = ({ children }) => {
    const classes = useStyles();
    return (
        <Grid
            container
            direction={"column"}
            justifyContent={"flex-start"}
            className={classes.gridContainer}
        >
            <Grid item>{children}</Grid>
        </Grid>
    );
};

ClearLayout.defaultProps = {
    toolbarPosition: "sticky",
};

ClearLayout.propTypes = {
    toolbarPosition: PropTypes.string,
};

export default withRouter(ClearLayout);
