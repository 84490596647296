import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DialogConsumer } from "@kimiya/context/dialog.context";
import { useSelector } from "react-redux"

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import ContainedButton from "@kimiya/ui/button/contained-button.component";
import CheckoutStripePaymentDialog from "@kimiya/dialogs/checkout-stripe-payment/checkout-stripe-payment.dialog";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({    
    totalRecapText:{ 
        color: theme.palette.primary.main,
        fontFamily: "Brown-Bold",
        fontSize: theme.typography.pxToRem(24),
        letterSpacing: 0.23
    },
    totalRecapContainer: {
        marginBottom: theme.spacing(4)
    }
}));

const CheckoutTotal = () => {

    const { t } = useTranslation();
    const classes = useStyle();
    const history = useHistory();    
    const cart = useSelector(({ checkout }) => checkout.cart || null )    

    const goToPaymentSuccess = () => history.push("/payment-success");

    if(!cart) return <CircularProgress />

    return (
        <DialogConsumer>
            {({ showDialog }) => (        
                <>
                    <Grid container justifyContent="space-between" alignItems="center" className={classes.totalRecapContainer}>
                        <Grid item>
                            <Typography className={classes.totalRecapText}>
                                {t("Totale")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.totalRecapText}>
                                {cart ? `${cart.m_transaction_gross.toFixed(2)}€` : `0.00€`}
                            </Typography>
                        </Grid>
                    </Grid>
                    <ContainedButton
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() => showDialog(CheckoutStripePaymentDialog,{
                            title: t("Finalizza il tuo ordine"),                            
                            callback: () => goToPaymentSuccess()
                        })}
                    >
                        {t("Acquista ora")}
                    </ContainedButton>
                </>
            )}
        </DialogConsumer>
    )
}

export default CheckoutTotal