import { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCart } from "@kimiya/core/store/actions/checkout";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import ContainedButton from "@kimiya/ui/button/contained-button.component";
import UserMenu from "@kimiya/ui/toolbar/user-menu";
import NavBar from "@kimiya/ui/toolbar/navbar";
import LandingNavbar from "@kimiya/ui/toolbar/landing-navbar";
import Badge from "@kimiya/ui/cart-badge/cart-badge"
import MobileMenu from "@kimiya/ui/mobile-menu/mobile-menu";

import KimiyaLogo from "@kimiya/core/images/kimiya-logo-full.svg";
import CartIcon from "@kimiya/core/images/ICO-cart.svg";

import { useTheme, useMediaQuery } from "@material-ui/core";

import useStyles from "@kimiya/ui/toolbar/toolbar.styles";

const ToolbarComponent = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation()
    const user = useSelector(({ auth }) => auth.user || null);
    const cart = useSelector(({ checkout }) => checkout.cart || null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const goToLogin = () => history.push({
        pathname: '/login',    
        state: { redirect_to: location.pathname }
    });
    const goToCart = () => history.push("/cart");

    const openMenuVoice = useCallback(
        (url, external) => {
            if (external) return window.open(url, "_blank");
            history.push(url);
        },
        [history]
    );

    const getCartItemsNumber = () => cart.items.reduce((acc, item) => acc += item.m_quantity, 0)

    useEffect(() => {
        if(user){
            dispatch(getCart())
        }
    },[dispatch, user])

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Container maxWidth="lg">
                <Toolbar>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <Grid
                                container
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={4}
                            >
                                <Grid
                                    item
                                    className={classes.menuVoiceContainer}
                                >
                                    <img
                                        onClick={() =>
                                            (!user || (!user.isCoach && user.b_registration_completed)) ? openMenuVoice("/", false) : {}
                                        }
                                        src={KimiyaLogo}
                                        alt="kimiya logo"
                                        width={125}
                                        className={classes.kimiyaLogo}
                                    />
                                </Grid>                                
                            </Grid>
                        </Grid>
                        <Grid item>
                            {isMobile ? (
                                <MobileMenu />
                            ) : (                                                                
                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={3}
                                >
                                    <LandingNavbar />
                                    {(!user || (!user.isCoach && user.b_registration_completed)) && (                                        
                                        <NavBar />                                        
                                    )}    
                                    {!user ? (
                                        <Grid item>
                                            <ContainedButton
                                                variant="contained"
                                                color="secondary"
                                                onClick={goToLogin}
                                            >
                                                {t("Accedi")}
                                            </ContainedButton>
                                        </Grid>
                                    ) : (
                                        <UserMenu />
                                    )}
                                    {(!user || (!user.isCoach && user.b_registration_completed)) && (
                                        <Grid item>
                                            <IconButton onClick={goToCart} edge="end">
                                            {(!cart?.items || cart.items.length===0) ? (
                                                <img src={CartIcon} alt="cart icon" />
                                            ) : (
                                                <Badge badgeContent={getCartItemsNumber()} color="secondary">
                                                    <img src={CartIcon} alt="cart icon" />
                                                </Badge>
                                            )}
                                            </IconButton>
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default ToolbarComponent;
