import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Card from "@kimiya/ui/card/card";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
    card: {
        borderRadius: 10,
        margin: theme.spacing(2, 0, 4),
    },
    cardSelected: {
        border: `4px solid ${theme.palette.primary.main}`,
    },
    subscriptionName: {
        color: theme.palette.secondary.main,
        fontFamily: "Brown-Bold",
        fontSize: theme.typography.pxToRem(26),
    },
    subscriptionPrice: {
        color: theme.palette.primary.main,
        fontFamily: "Brown-Bold",
        fontSize: theme.typography.pxToRem(26),
    },
    subscriptionPricePeriod: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(16),
    },
    subscriptionBillingType: {
        color: theme.palette.secondary.main,
        fontSize: theme.typography.pxToRem(16),
    },
    subscriptionText: {
        color: theme.palette.grey5.main,
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(16),
        letterSpacing: 0.44,
    },
}));

const SubscriptionCard = ({ selected, onSelect, subscription }) => {
    const classes = useStyle();

    return (
        <Card
            size="medium"
            customClasses={`${classes.card} ${
                selected && classes.cardSelected
            }`}
            onClick={() => (onSelect ? onSelect(subscription.id) : {})}
        >
            <Grid container direction="column" spacing={4}>
                <Grid item xs={12}>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Grid item>
                            <Typography className={classes.subscriptionName}>
                                {subscription.name}
                            </Typography>
                        </Grid>
                        <Grid item align="right">
                            <Typography className={classes.subscriptionPrice}>
                                {subscription.price.toFixed(2)}/
                                <span
                                    className={classes.subscriptionPricePeriod}
                                >
                                    mese
                                </span>
                            </Typography>
                            <Typography
                                className={classes.subscriptionBillingType}
                            >
                                ciclo di fatturazione mensile
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {subscription.text && (
                    <Grid item xs={12}>
                        <Typography className={classes.subscriptionText}>
                            {subscription.text}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </Card>
    );
};

export default SubscriptionCard;
