import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useQuery } from "react-query";
import { getUser, updateUserInfo } from "@kimiya/core/store/actions/auth";
import { keysValuesGenerator } from "@kimiya/core/utils";
import * as yup from "yup";
import i18n from "@kimiya/core/i18n/";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import Backdrop from "@kimiya/ui/backdrop/backdrop";
import ContainedButton from "@kimiya/ui/button/contained-button.component";
import Input from "@kimiya/ui/input/input.component";

import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    verifiedText: {
        color: theme.palette.success.main,
    },
    notVerifiedText: {
        color: theme.palette.error.main,
    },
}));

const validationSchema = yup.object({
    s_firstname: yup
        .string(i18n.t("Inseriscio il tuo nome"))
        .required(i18n.t("Il nome è obbligatorio")),
    s_lastname: yup
        .string(i18n.t("Inserisci il tuo cognome"))
        .required(i18n.t("Il cognome è obbligatorio")),
    s_confirm_mobile_number: yup.string(
        i18n.t("Inserisci il tuo numero di cellulare")
    ),
});

const ProfileMobileForm = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [form, setForm] = useState({
        s_confirm_mobile_number: "",
        s_firstname: "",
        s_lastname: "",
    });
    const [loading, setLoading] = useState(false);
    const [saveError, setSaveError] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const fetchUser = async () => {
        const user = await getUser();
        const { s_confirm_mobile_number, s_firstname, s_lastname } = user;
        setForm({ s_confirm_mobile_number, s_firstname, s_lastname });
        return user;
    };

    const saveUserInfo = async ({
        s_firstname,
        s_lastname,
        s_confirm_mobile_number,
    }) => {
        setLoading(true);
        const keyValues = await keysValuesGenerator({
            s_firstname,
            s_lastname,
            s_confirm_mobile_number: s_confirm_mobile_number || null,
        });
        const res = await updateUserInfo(keyValues);
        setLoading(false);
        if (!res) {
            setSaveError(true);
            setShowToast(true);
            return;
        }
        setShowToast(true);
        fetchUser();
    };

    const formik = useFormik({
        initialValues: form,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            saveUserInfo(values);
        },
    });

    const handleClose = (event, reason) => {
        setSaveError(false);
        setShowToast(false);
    };

    const { isLoading, error } = useQuery("user-info", fetchUser);

    return (
        <Box py={3}>
            {error && <Typography>{error}</Typography>}
            {isLoading ? (
                <CircularProgress />
            ) : (
                <form
                    className={classes.root}
                    noValidate
                    autoComplete="off"
                    onSubmit={formik.handleSubmit}
                >
                    <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <Input
                                autoComplete="off"
                                id="s_firstname"
                                name="s_firstname"
                                label={t("Nome")}
                                variant="outlined"
                                fullWidth
                                value={formik.values.s_firstname}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.s_firstname &&
                                    Boolean(formik.errors.s_firstname)
                                }
                                helperText={
                                    formik.touched.s_firstname &&
                                    formik.errors.s_firstname
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                autoComplete="off"
                                id="s_lastname"
                                name="s_lastname"
                                label={t("Cognome")}
                                variant="outlined"
                                fullWidth
                                value={formik.values.s_lastname}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.s_lastname &&
                                    Boolean(formik.errors.s_lastname)
                                }
                                helperText={
                                    formik.touched.s_lastname &&
                                    formik.errors.s_lastname
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                autoComplete="off"
                                type="tel"
                                id="s_confirm_mobile_number"
                                name="s_confirm_mobile_number"
                                label={t("Telefono")}
                                variant="outlined"
                                fullWidth
                                value={formik.values.s_confirm_mobile_number}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.s_confirm_mobile_number &&
                                    Boolean(
                                        formik.errors.s_confirm_mobile_number
                                    )
                                }
                                helperText={
                                    formik.touched.s_confirm_mobile_number &&
                                    formik.errors.s_confirm_mobile_number
                                }
                            />
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item>
                                <ContainedButton
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                >
                                    {t("Salva")}
                                </ContainedButton>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            )}
            <Backdrop
                open={loading}
                customText={t("Salvando...Attendere...")}
            />
            <Snackbar
                open={showToast}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                {!saveError ? (
                    <Alert severity="success">
                        {t("Informazioni account modificate con successo")}
                    </Alert>
                ) : (
                    <Alert severity="error">
                        {t(
                            "Errore durante il salvataggio della nuova password"
                        )}
                    </Alert>
                )}
            </Snackbar>
        </Box>
    );
};

export default ProfileMobileForm;
