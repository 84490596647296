import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux"
import { useQuery } from "react-query"
import { getSubscriptions, completeUserRegistration } from "@kimiya/core/store/actions/auth"
import { logout } from "@kimiya/core/store/reducers/auth.reducer";
import { toast } from "react-toastify"

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ContainedButton from '../../ui/button/contained-button.component';
import OutlinedButton from '../../ui/button/outlined-button.component';

import SubscriptionCard from "@kimiya/ui/subscription-card/subscription-card"
import Backdrop from "@kimiya/ui/backdrop/backdrop"

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    subscriptionTitle: {        
        fontFamily: "Brown-Light",
        fontSize: 20,
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.main
    },
    subscriptionPaymentTypeContainer: {
        marginBottom: theme.typography.pxToRem(25)
    },
    subscriptionPaymentType: {
        fontFamily: "Brown-Light",
        fontSize: 16,
        color: theme.palette.grey5.main
    },
    backButton: {
        textTransform: "Capitalize"
    }
}));

const RegistrationSubscriptionStep = ({ handleNext, handleBack }) => {
    
    const classes = useStyles();
    const { t } = useTranslation();    
    const dispatch = useDispatch();    
    const paymentTiming = "monthly"
    const [selectedSubscription, setSelectedSubscription] = useState()
    const [subscriptions, setSubscriptions] = useState()    
    const user = useSelector(({ auth }) => auth.user)

    const handleSelectedSubscription = subscription => {
        setSelectedSubscription(subscription)
    }

    const completeRegistrationHandler = async () => {
        const res = await completeUserRegistration();
        if(typeof res === "string" || res===false){
            if(res===false){
                toast.error(t('Ops! Qualcosa è andato storto. Vi preghiamo di riprovare o di contattare la nostra assistenza. Grazie.'))            
            }else{                                
                toast.error(res,{ autoClose: 10000 })            
            }
            return false
        }
        dispatch(logout())
        toast.success("Registrazione completata con successo! Effettua di nuovo il login per navigare in Kimiya.", { autoClose: 10000 })
        return true;
    }

    const refetchSubscriptions = async () => {
        const subscriptions = await getSubscriptions();
        setSubscriptions(subscriptions)
        return subscriptions
    }

    useEffect(() => {
        console.log("user", user)
        if(user && !user.b_billing_completed){
            handleBack()
        }
    },[user, handleBack])
    
    const { isLoading, error } = useQuery("subscriptions", refetchSubscriptions)    

    if(isLoading) return <Backdrop open customText={t('Fetching subscriptions...')} />
    if(error) return "An error has occurred: " + error.message;

    return (
        <Box py={3} className={classes.root}>
            <Typography align="center" className={classes.subscriptionTitle}>{t("Scegli un piano di abbonamento")}</Typography>            
            {subscriptions && subscriptions.map(subscription => (
                <SubscriptionCard 
                    selected={!!(selectedSubscription && selectedSubscription.id_item===subscription.id_item)}
                    key={subscription.id_item}                     
                    item={subscription}
                    onSelect={handleSelectedSubscription}
                />
            ))}            
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <ContainedButton
                        onClick={() => handleNext({...selectedSubscription, paymentTiming})}
                        variant="contained"
                        color="secondary"
                        fullWidth
                        disabled={!selectedSubscription}
                    >
                        {t("AVANTI")}
                    </ContainedButton>
                </Grid>
                <Grid item>
                    <Typography variant="body2" color="secondary" align="center">{t("OPPURE")}</Typography>
                </Grid>
                <Grid item>
                <OutlinedButton
                    onClick={completeRegistrationHandler}
                    variant="outlined"
                    color="primary"
                    fullWidth
                >
                    {t("Registrati senza Abbonamento")}
                </OutlinedButton>
                </Grid>
            </Grid>
            {handleBack && (
                <Box my={4}>                                                
                    <Button onClick={handleBack} className={classes.backButton} fullWidth>
                        {t("Indietro")}
                    </Button>
                </Box>
            )}
        </Box>
    )
}

export default RegistrationSubscriptionStep