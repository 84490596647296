import { API } from '@kimiya/core/core/api.class'
import DB from '@kimiya/core/core/db-tables'

export const getCountries = async () => {
    try{
        const { rows } = await API.query(DB.COUNTRIES)
        return rows
    }catch(error){
        console.log('error')
    }
}

export const getRegions = async (id_country) => {
    try{
        const where = id_country ? `id_country~${id_country}` : ""
        const { rows } = await API.query(DB.REGIONS, where,"","s_region~asc")
        return rows
    }catch(error){
        console.log('error')
    }
}

export const getCounties = async (id_region) => {
    try{
        const where = id_region ? `id_region~${id_region}` : ""
        const { rows } = await API.query(DB.COUNTIES, where,"","s_county~asc")
        return rows
    }catch(error){
        console.log('error')
    }
}

export const getCities = async (id_county) => {
    try{
        const where = id_county ? `id_county~${id_county}` : ""
        const { rows } = await API.query(DB.CITIES, where,"","s_city~asc")
        return rows
    }catch(error){
        console.log('error')
    }
}

export const getTerms = async () => {
    try{
        const where = `id_agreement~1`
        const { rows } = await API.query(DB.TERMS, where,"","n_priority~asc")
        return rows
    }catch(error){
        console.log('error')
    }
}