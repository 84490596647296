export const PROFILE_DOC_TYPE = "10000";
export const PROFILE_ENTITY_ID = "7";

export const FRONT_DOC_TYPE = "10001";
export const FRONT_ENTITY_ID = "10000";

export const SIDE_DOC_TYPE = "10002";
export const SIDE_ENTITY_ID = "10000";

export const ANALYSIS_DOC_TYPE = "110003";
export const ANALYSIS_ENTITY_ID = "110001";

export const SUBSCRIPTION_CATEGORY_ID = 3;
export const SUBSCRIPTION_SUBCATEGORY_ID = 6;

export const CHATS_STATUSES = [
  {
    key: "open",
    label: "in corso",
  },
  {
    key: "archived",
    label: "archiviate",
  },
];

export const ORDER_TRANSACTION_TYPE = 1;

export const ORDER_STATUSES = Object.freeze({
  PENDING: "IN ATTESA DI CONFERMA",
  WORKING: "IN ELABORAZIONE",
  SHIPPED: "SPEDITO",
  CANCELED: "ANNULLATO",
  PAYED: "PAGATO E CONFERMATO"
})
