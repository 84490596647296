import { useState } from "react";
import { useTranslation } from "react-i18next";
import { changePassword } from "@kimiya/core/store/actions/auth";

import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';

import Backdrop from "@kimiya/ui/backdrop/backdrop"
import ProfileChangePasswordForm from "@kimiya/forms/profile/change-password.form";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
    sectionTitle: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(48),
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.pxToRem(28),
        }
    },
}));



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ProfileChangePassword = () => {
    const classes = useStyle();
    const { t } = useTranslation();    
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [showToast, setShowToast] = useState(false)

    const onSavePassword = async (password) => {
        setLoading(true)
        const res = await changePassword(password);
        setLoading(false)
        if(!res) {          
            setError(true)  
            setShowToast(true)
            return;
        }        
        setShowToast(true)
    };

    const handleClose = (event, reason) => {
        setError(false)  
        setShowToast(false)
    };

    return (
        <>
            <Typography className={classes.sectionTitle}>
                {t("Cambia password")}
            </Typography>
            <ProfileChangePasswordForm                
                onSavePassword={onSavePassword}
            />
            <Backdrop open={loading} customText={t("Salvando...Attendere...")} />
            <Snackbar 
                open={showToast} 
                autoHideDuration={6000} 
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                {!error 
                    ? <Alert severity="success">{t('Salvataggio avvenuto correttamente')}</Alert>
                    : <Alert severity="error">{t('Errore durante il salvataggio della nuova password')}</Alert>
                } 
            </Snackbar>
        </>
    );
};

export default ProfileChangePassword;
