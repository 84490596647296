import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    welcomeText: {
        color: theme.palette.secondary.main,
        fontSize: theme.typography.pxToRem(26),
        marginTop: theme.typography.pxToRem(20),
        marginBottom: theme.typography.pxToRem(10)
    },
    resetPasswordText: {
        fontFamily: "Brown-Light",
        fontSize: 16,
        letterSpacing: 0.44,
        color: theme.palette.grey5.main,
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(2),
        },
    },
    inputMargin: {
        marginBottom: theme.spacing(3),
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    bottomText: {
        marginTop: theme.spacing(2),
        color: theme.palette.secondary.main,
        fontSize: theme.typography.pxToRem(14),
        cursor: "pointer",
    },
    fieldLabel: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: "600",
    },
    validationList: {
        paddingLeft: 0,
        marginLeft: 0,
    },
    inactiveStatus: {
        color: theme.palette.grey[500],
    },
    invalidStatus: {
        color: theme.palette.error.main,
    },
    validStatus: {
        color: theme.palette.success.main,
    },
    validationText: {
        fontSize: theme.typography.pxToRem(12),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        color: theme.palette.secondary.main,
        textTransform: "none",
    },
    buttonsSeparator: {
        textTransform: "uppercase",
        color: theme.palette.secondary.main,
        textAlign: "center",
    },
    goBackButton: {
        marginTop: theme.spacing(2),
    },
}));
