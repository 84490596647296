import { useTranslation } from "react-i18next";
import {
    CHECKOUT_BILLING_RESOURCE,
    CHECKOUT_BILLING_ADDRESS,
    CHECKOUT_SHIPPING_ADDRESS,
} from "@kimiya/core/core/combined-fields-mapper";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import useStyle from "@kimiya/custom/order/order.style";

const OrderBillingShipping = ({ order }) => {
    const { t } = useTranslation();
    const classes = useStyle();

    return (
        <Grid
            container
            justifyContent="flex-start"
            alignItems="stretch"
            className={classes.orderRow}
        >
            {order.id_resource_shipping_delivery === 0 ? (
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        style={{ height: "100%" }}
                    >
                        <Grid item>
                            <Typography className={classes.sectionTitle}>
                                {t("fatturato e spedito a")}:
                            </Typography>
                            <Typography className={classes.sectionText}>
                                {order[CHECKOUT_BILLING_RESOURCE]?.s_resource}
                            </Typography>
                            {order[CHECKOUT_BILLING_RESOURCE]?.s_tax_code && (
                                <Typography className={classes.sectionText}>
                                    {t("P. IVA")}:{" "}
                                    {
                                        order[CHECKOUT_BILLING_RESOURCE]
                                            ?.s_tax_code
                                    }
                                </Typography>
                            )}
                            <Typography className={classes.sectionText}>
                                {t("Nome Indirizzo")}:{" "}
                                {
                                    order[CHECKOUT_BILLING_ADDRESS]
                                        ?.s_denomination
                                }
                            </Typography>
                            <Typography className={classes.sectionText}>
                                {`${
                                    order[CHECKOUT_BILLING_ADDRESS]?.s_address
                                } ${
                                    order[CHECKOUT_BILLING_ADDRESS]
                                        ?.s_zip_code || ""
                                }`}
                            </Typography>
                            <Typography className={classes.sectionText}>
                                {`${order[CHECKOUT_BILLING_ADDRESS]?.s_city} ${order[CHECKOUT_BILLING_ADDRESS]?.s_county} ${order[CHECKOUT_BILLING_ADDRESS]?.s_country}`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <>
                    <Grid item xs={6}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            style={{ height: "100%" }}
                        >
                            <Grid item>
                                <Typography className={classes.sectionTitle}>
                                    {t("fatturato a")}:
                                </Typography>
                                <Typography className={classes.sectionText}>
                                    {
                                        order[CHECKOUT_BILLING_RESOURCE]
                                            ?.s_resource
                                    }
                                </Typography>
                                {order[CHECKOUT_BILLING_RESOURCE]
                                    ?.s_tax_code && (
                                    <Typography className={classes.sectionText}>
                                        {t("P. IVA")}:{" "}
                                        {
                                            order[CHECKOUT_BILLING_RESOURCE]
                                                ?.s_tax_code
                                        }
                                    </Typography>
                                )}
                                <Typography className={classes.sectionText}>
                                    {t("Nome Indirizzo")}:{" "}
                                    {
                                        order[CHECKOUT_BILLING_ADDRESS]
                                            ?.s_denomination
                                    }
                                </Typography>
                                <Typography className={classes.sectionText}>
                                    {`${
                                        order[CHECKOUT_BILLING_ADDRESS]
                                            ?.s_address
                                    } ${
                                        order[CHECKOUT_BILLING_ADDRESS]
                                            ?.s_zip_code || ""
                                    }`}
                                </Typography>
                                <Typography className={classes.sectionText}>
                                    {`${order[CHECKOUT_BILLING_ADDRESS]?.s_city} ${order[CHECKOUT_BILLING_ADDRESS]?.s_county} ${order[CHECKOUT_BILLING_ADDRESS]?.s_country}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            style={{ height: "100%" }}
                        >
                            <Grid item>
                                <Typography className={classes.sectionTitle}>
                                    {t("spedito a")}:
                                </Typography>
                                <Grid item>
                                    <Typography className={classes.sectionTextBold}>
                                        {order[CHECKOUT_SHIPPING_ADDRESS].s_denomination}
                                    </Typography>
                                </Grid>
                                <Typography className={classes.sectionText}>
                                    {`${order[CHECKOUT_SHIPPING_ADDRESS].s_address} ${order[CHECKOUT_SHIPPING_ADDRESS].s_zip_code || ''}`}
                                </Typography>
                                <Typography className={classes.sectionText}>                                        
                                    {`${order[CHECKOUT_SHIPPING_ADDRESS].s_city} ${order[CHECKOUT_SHIPPING_ADDRESS].s_county} ${order[CHECKOUT_SHIPPING_ADDRESS].s_country}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default OrderBillingShipping;
