import Toolbar from "@kimiya/ui/toolbar/toolbar";

import useStyles from "@kimiya/ui/toolbar/toolbar.styles";

const CoachLayout = ({ sidebar, children }) => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                <Toolbar />                
                <main className={sidebar ? classes.content : classes.centeredContent}>                        
                    {children}
                </main>
            </div>            
        </>
    );
};

export default CoachLayout;
