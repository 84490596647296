import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "@kimiya/core/core/api.class"
import DB from "@kimiya/core/core/db-tables"
import { keysValuesGenerator } from "@kimiya/core/utils/"
import { getTime } from "date-fns";
import { groupBy, orderBy } from "lodash";

const PROJECT_PREFIX = process.env.REACT_APP_PRJ_PREFIX;

export const getChats = createAsyncThunk("auth/getChats", async () => {
    try{
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));        
        const where = user.b_working 
            ? `id_resource_asking~${user.id_resource}`
            : `id_resource_manager~${user.id_resource}`;
        const includes = [
            `id_resource_asking~${DB.CHATS}~id_resource~${DB.RESOURCES}~1`,
            `id_resource~${DB.RESOURCES}~id_resource~${DB.PROFILE}~1`,
            `id_resource~${DB.RESOURCES}~id_resource_customer~${DB.USER_SUBSCRIPTION}~1`,
            `id_item~${DB.USER_SUBSCRIPTION}~id_item~${DB.SHOP_ITEMS}~1`,
        ];
        const { rows } = await API.query(DB.CHATS,where,"",`d_created~desc`,includes.join("|"))
        return rows
    }catch(error){
        console.log('error')
        return false
    }
})

export const getChat = createAsyncThunk("auth/getChat", async (id_chat) => {
    try{
        const where = `id_support~${id_chat}`;        
        const includes = [
            `id_resource_asking~${DB.CHATS}~id_resource~${DB.RESOURCES}~1`,
            `id_resource~${DB.RESOURCES}~id_resource~${DB.PROFILE}~1`,
            `id_resource~${DB.RESOURCES}~id_resource_customer~${DB.USER_SUBSCRIPTION}~1`,
            `id_item~${DB.USER_SUBSCRIPTION}~id_item~${DB.SHOP_ITEMS}~1`,
        ];
        const { rows } = await API.query(DB.CHATS,where,"","",includes.join("|"))        
        return rows[0]
    }catch(error){
        console.log('error')
        return false
    }
})

// export const getChatMessages = createAsyncThunk("auth/getChatMessages", async (id_chat) => {
//     try{
//         const where = `id_support~${id_chat}`;        
//         const includes = [
//             `id_resource_asking~${DB.CHATS}~id_resource~${DB.RESOURCES}~1`,
//             `id_resource~${DB.RESOURCES}~id_resource~${DB.PROFILE}~1`,
//             `id_resource~${DB.RESOURCES}~id_resource_customer~${DB.USER_SUBSCRIPTION}~1`,
//             `id_item~${DB.USER_SUBSCRIPTION}~id_item~${DB.SHOP_ITEMS}~1`,
//         ];
//         const { rows } = await API.query(DB.CHAT_MESSAGES,where,"","",includes.join("|"))        
//         return rows[0]
//     }catch(error){
//         console.log('error')
//         return false
//     }
// })

export const createChat = async chatName => {
    try{
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));

        const { rows } = await API.query(DB.USER_PROFILE,`id_resource~${user.id_resource}`)

        const key_values = await keysValuesGenerator({
            id_resource_asking: user.id_resource,
            id_resource_manager: rows[0].id_resource_coach_primary,
            id_support_status: "2",
            s_support_request: chatName,
            id_support_time: "1",
            n_support_time: "0"
        });

        await API.create(DB.CHATS,key_values)

        return true

    }catch(error){
        console.log('error')
        return false
    }
}

export const archiveChat = async id_chat => {
    try{
    
        await API.update(DB.CHATS,`id_support~${id_chat}|b_completed~1`)
        
        return true

    }catch(error){
        console.log('error')
        return false
    }
}

export const createMessage = async ({chat, message}) => {
    try{
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));

        const key_values = await keysValuesGenerator({
            id_support: chat.id_support,
            id_resource_from: user.id_resource,            
            id_resource_to: chat.id_resource_manager,       
            s_message_body: message
        });

        await API.create(DB.CHAT_MESSAGES,key_values)

        return true

    }catch(error){
        console.log('error')
        return false
    }
}

export const getChatMessages = async (id_chat) => {
    try{
        const where = `id_support~${id_chat}`;                
        const { rows } = await API.query(DB.CHAT_MESSAGES,where,"","d_created~desc")

        let messages = [];
        for await(let message of rows){
            const resMessageAuthor = await API.query(DB.RESOURCES,`id_resource~${message.id_resource_from}`)
            const [date, time] = message.d_created.split(" ");
            const [year, month, day] = date.split("-");     
            const [hour, minutes, seconds ] = time.split(":")                                   
            const createdAt = getTime(new Date(year, month,day, hour, minutes, seconds))             
            messages.push({
                author: {
                    firstName: resMessageAuthor.rows[0].s_firstname,
                    lastName: resMessageAuthor.rows[0].s_lastname,
                    id: ""+message.id_resource_from                    
                },
                date: `${day}/${month}/${year} ${hour}:${minutes}`,                
                createdAt,
                id: message.id_support_message,
                "text": message.s_message_body,
                "type": "text"
            })
        }     

        return groupBy(orderBy(messages,"createdAt","asc"),"date")
    }catch(error){
        console.error(error)
        return false
    }
}