import { makeStyles } from "@material-ui/core/styles"

export default makeStyles(theme => ({
    root: {
        marginTop: 65,
        height: "calc(100% - 65px)",
        flex:1,
        flexGrow: 1,
    },
    sidebar: {
        background: theme.palette.grey1.main,
        maxHeight: "100%",
        overflow: "auto"        
    },
    sidebarEmptyContainer: {
        width: '100%',                 
        display: 'flex',
        flexDirection: 'column',
    },
    sidebarEmptySkeleton: {
        width: '100%',
    },
    sibebarList: {
        paddingTop: 0
    },
    coachItem: {
        height: 70,
        background: theme.palette.secondary.main,        
        margin: 0,
        borderRight: `1px solid ${theme.palette.common.white}`
    },
    coachText: {
        color: theme.palette.primary.main
    },
    chatSearchItem: {
        background: theme.palette.grey1.main,
        margin: 0
    },
    chat: {
        background: theme.palette.background.default
    },
    noChatContainer: {
        height: "100%",
    },
    chatContainer: {
        position: "relative",
        height: "100%",
        width: "100%",
        overflow: "hidden"
    },
    chatHeader: {
        position: "absolute",
        top: 0,
        left: 0,
        height: 70,
        padding: 15,
        width: "100%",
        zIndex: 2,
        background: theme.palette.secondary.main,        
    },
    chatHeaderTitle: {
        color: theme.palette.primary.main
    },
    chatHeaderSubtitle: {
        color: theme.palette.common.white
    },
    chatHeaderIcon: {
        color: theme.palette.primary.main
    },
    chatMessagesContainer: {
        background: theme.palette.background.default,
        position: "absolute",
        zIndex: 1,        
        top: 70,
        left: 0,
        width: "100%",
        height: "calc(100% - 145px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "25px 15px 100px"
    },
    chatBubbleContainer: {
        width: "100%",
        margin: "5px 0"
    },
    chatMessagesDateContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    chatMessagesDate: {
        fontFamily: "Brown-Bold",
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(11)
    },
    coachMessageBubble: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: 15
    },
    coachMessageBubbleFirst: {
        borderTopRightRadius: 0
    },
    userMessageBubble: {
        background: theme.palette.common.white,
        color: theme.palette.primary.default,
        borderRadius: 15
    },
    userhMessageBubbleFirst: {
        borderTopLeftRadius: 0
    },
    messageText: {
        fontFamily: "Brown-Light"
    },
    chatInput: {
        position: "absolute",
        zIndex: 2,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        maxHeight: 250,
        background: theme.palette.primary.main,        
        padding: 15,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    },
    chatInputTextField: {   
        color: theme.palette.common.white,        
        '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: theme.palette.common.white
        }        
    },
    chatInputIcon: {
        color: theme.palette.common.white
    },
    clientInfo: {
        background: theme.palette.grey1.main,        
        overflow: "auto",
        borderLeft: `1px solid ${theme.palette.grey1.main}`
    },
    userInfoPanelHeader: {
        width: "100%",
        height: 70,        
        padding: 15,
        background: theme.palette.secondary.main,        
    },
    userInfoTitle: {
        marginLeft: theme.spacing(2)
    },
    chatAvatar: {
        background: theme.palette.common.white,
        color: theme.palette.primary.main,
        fontFamily: "Brown-Light",                
    },
}))