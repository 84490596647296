import axiosInstance from "axios";

const PROJECT_PREFIX = process.env.REACT_APP_PRJ_PREFIX;

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            localStorage.removeItem(`${PROJECT_PREFIX}TOKEN`);
            localStorage.removeItem(`${PROJECT_PREFIX}USR`);
            axiosInstance.defaults.headers.common["Authorization"] = "";
            return Promise.reject(error)
        } else {
            return Promise.reject(error);
        }
    }
);

export const axios = axiosInstance;
