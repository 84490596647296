import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 300;

export default makeStyles((theme) => ({
    root: {
        display: "flex",
        height: "100%",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background: theme.palette.common.white,
        boxShadow: "none",
        borderBottom: `1px solid ${theme.palette.secondary.main}`
    },
    kimiyaLogo: {
        marginRight: theme.typography.pxToRem(55)
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiBackdrop-root": {
            display: "none"
        },
        "& .MuiDrawer-paper": {
            width: drawerWidth,
            position: 'absolute',
            height: props => props.height,
            transition: 'none !important',            
        }
    },
    drawerContainer: {
        overflow: "auto",
        marginTop: theme.spacing(2),        
    },
    mobileContent: {
        flexGrow: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    centeredContent: {
        flexGrow: 1,
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    content: {
        flexGrow: 1,
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    leftContent: {
        width: "100%",        
        height: "100%",
        marginTop: `${theme.mixins.toolbar.minHeight+theme.spacing(2)}px`,
        padding: theme.spacing(0, 4),        
        [theme.breakpoints.down("sm")]:{
            padding: theme.spacing(0, 1),        
        }
    },
    menuVoiceContainer: {
        padding: 0,
        cursor: "pointer",        
    },
    menuIconContainer:{
        color: theme.palette.grey3.main,
        backgroundColor: "transparent !important"
    },
    menuIconContainerSelected: {
        color: theme.palette.primary.main,
        backgroundColor: "transparent !important"
    },
    menuVoice: {
        color: theme.palette.grey3.main,
        fontSize: 17,        
    },
    menuVoiceSelected: {
        color: theme.palette.primary.main,
        fontSize: 17,
    },
    userAvatar: {
        background: theme.palette.common.white,
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
    },
    userName: {
        fontSize: theme.typography.pxToRem(14),
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        textTransform: "uppercase",
        letterSpacing: "1.25px",
        padding: "0 15px 0 0",
        "&:hover":{
            background: theme.palette.primary.main,
        }
    },
    userDropdownMenu: {
        padding: theme.spacing(2,0,0,0)
    },
    userDropdownMenuItem: {
        padding: theme.spacing(1,4)
    },
    userDropDownMenuItemIcon: {
        minWidth: 40,
        color: theme.palette.primary.main
    },
    userDropdownMenuDivider: {
        backgroundColor: theme.palette.secondary.main,
        margin: theme.spacing(2,0,2,0)
    },
    userDropDownMenuItemText: {
        fontSize: 17,
        color: theme.palette.primary.main
    },
    userDropDownLogoutContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
            background: "transparent"
        }
    },
    sidebarHeaderIcon: {
        minWidth: 16,
        marginRight: 10,
        color: theme.palette.grey2.main
    },
    sidebarHeaderText: {
        fontSize: 17,        
        letterSpacing: 0,
        color: theme.palette.grey2.main
    },
    sidebarHeaderDivider: {
        height: 2,
        backgroundColor: theme.palette.grey1.main
    },
    sidebarVoice: {
        fontFamily: "Brown-Light",
        fontSize: 20,
        color: theme.palette.secondary.main,
        letterSpacing: -0.9
    },
    sidebarVoiceSelected: {
        fontFamily: "Brown-Light",
        fontSize: 20,
        color: theme.palette.primary.main,
        letterSpacing: -0.9
    },
    footer: {
        width: "100%",
        minHeight: 300,
        backgroundColor: "yellow"
    }
}));
