import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query"
import { getTerms } from "@kimiya/core/store/actions/shared"

import Typography from "@material-ui/core/Typography";

import Backdrop from "@kimiya/ui/backdrop/backdrop"

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
    sectionTitle: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(48),
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.pxToRem(28),
        }
    },
    termTitle: {
        margin: theme.spacing(3, 0, 1),
        color: theme.palette.grey5.main,
        fontSize: theme.typography.pxToRem(16),
        letterSpacing: 0.44,
    },
    termParagraph: {        
        fontFamily: "Brown-Light",
        color: theme.palette.grey5.main,
        fontSize: theme.typography.pxToRem(16),
        letterSpacing: 0.44,
        textAlign: "justify",
    },
}));

const ProfileTerms = () => {
    const classes = useStyle();
    const { t } = useTranslation();

    const [terms, setTerms] = useState()

    const refetchTerms = async () => {        
        const terms = await getTerms()
        setTerms(terms ? terms : [])
        return terms
    }
    
    const { isLoading, error } = useQuery(["terms"], refetchTerms)

    if (isLoading) return <Backdrop open customText={t('Fetching terms and conditions...')} />
    if (error) return "An error has occurred: " + error.message;

    return (
        <>
            <Typography className={classes.sectionTitle}>
                {t("Termini e Condizioni")}
            </Typography>
            {terms.map((term, index) => (
                <>
                    <Typography className={classes.termTitle} key={index}>
                        {term.s_agreement_term_title}
                    </Typography>
                    <Typography className={classes.termParagraph} key={index}>
                        {term.s_agreement_term}
                    </Typography>
                </>
            ))}
        </>
    );
};

export default ProfileTerms;
