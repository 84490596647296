import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "@kimiya/core/core/api.class";
import DB from "@kimiya/core/core/db-tables";

export const getShippingTypes = async () => {
    try {
        const { rows } = await API.query(
            DB.SHIPPING_TYPES,
            "b_ecommerce~1",
            "",
            "s_shipping_type~asc"
        );
        return rows;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getCart = createAsyncThunk(
    "checkout/getCartItems",
    async (_, thunkAPI) => {
        try {
            const { id_transaction } = await API.custom(
                "com.matterbit.project.ProjectAction",
                "manageTransactionCart"
            );
            const includes = [
                "id_resource_transaction~mb_transaction~id_resource~bf_resource~1",
                "id_address_transaction~mb_transaction~id_address~bf_address~1",
                "id_resource_shipping_delivery~mb_transaction~id_resource~bf_resource~1",
                "id_address_shipping_delivery~mb_transaction~id_address~bf_address~1",
                "id_shipping_type~mb_transaction~id_shipping_type~mb_shipping_type~1",
            ];
            const { rows: transaction } = await API.query(
                DB.TRANSACTIONS,
                `id_transaction~${id_transaction}`,
                "",
                "",
                includes.join("|")
            );

            const { rows: items } = await API.query(
                DB.TRANSACTION_ITEMS,
                `id_transaction~${id_transaction}|id_transaction_item_container~0`
            );

            return {
                ...transaction[0],
                items,
            };
        } catch (error) {
            console.log("error");
            return thunkAPI.rejectWithValue({
                err: "Errore durante la ripresa del carrello",
            });
        }
    }
);

export const updateCartItem = createAsyncThunk(
    "checkout/updateCartItem",
    async ({ itemId, quantity }, thunkAPI) => {
        try {
            await API.custom(
                "com.matterbit.project.ProjectAction",
                "manageTransactionCart",
                {
                    items: `${itemId}~${quantity}`,
                }
            );
            return thunkAPI.dispatch(getCart());
        } catch (error) {
            console.log("error");
            return thunkAPI.rejectWithValue({
                err: "Errore durante l'aggiornamento del carrello",
            });
        }
    }
);

export const removeCartItem = createAsyncThunk(
    "checkout/updateCartItem",
    async ({ itemTransactionId }, thunkAPI) => {
        try {
            await API.custom(
                "com.matterbit.project.ProjectAction",
                "manageTransactionCart",
                {
                    removeTransactionItems: "" + itemTransactionId,
                }
            );
            return thunkAPI.dispatch(getCart());
        } catch (error) {
            console.log("error");
            return thunkAPI.rejectWithValue({
                err: "Errore durante l'aggiornamento del carrello",
            });
        }
    }
);

export const resetCartItems = createAsyncThunk(
    "checkout/resetCartItems",
    async (_, thunkAPI) => {
        try {
            await API.custom(
                "com.matterbit.project.ProjectAction",
                "manageTransactionCart",
                {
                    resetCart: "true",
                }
            );
            return thunkAPI.dispatch(getCart());
        } catch (error) {
            console.log("error");
            return thunkAPI.rejectWithValue({
                err: "Errore durante l'aggiornamento del carrello",
            });
        }
    }
);

export const setCartShippingType = createAsyncThunk(
    "checkout/setCartShippingType",
    async ({ idShippingType }, thunkAPI) => {
        try {
            await API.custom(
                "com.matterbit.project.ProjectAction",
                "manageTransactionCart",
                {
                    id_shipping_type: "" + idShippingType,
                }
            );
            return thunkAPI.dispatch(getCart());
        } catch (error) {
            console.log("error");
            return thunkAPI.rejectWithValue({
                err: "Errore durante il salvataggio della modalità di spedizione",
            });
        }
    }
);

export const setCartBillingAddress = createAsyncThunk(
    "checkout/setCartBillingAddress",
    async ({ idBillingAddress }, thunkAPI) => {
        try {
            await API.custom(
                "com.matterbit.project.ProjectAction",
                "manageTransactionCart",
                {
                    id_address_transaction_update: "" + idBillingAddress,
                }
            );
            return thunkAPI.dispatch(getCart());
        } catch (error) {
            console.log("error");
            return thunkAPI.rejectWithValue({
                err: "Errore durante la modifica dell'indirizzo di fatturazione",
            });
        }
    }
);

export const setCartShippingAddress = createAsyncThunk(
    "checkout/setCartShippingAddress",
    async ({ idShippingAddress }, thunkAPI) => {
        try {
            await API.custom(
                "com.matterbit.project.ProjectAction",
                "manageTransactionCart",
                {
                    id_address_shipping_delivery_update: "" + idShippingAddress,
                }
            );
            return thunkAPI.dispatch(getCart());
        } catch (error) {
            console.log("error");
            return thunkAPI.rejectWithValue({
                err: "Errore durante il salvataggio della modalità di spedizione",
            });
        }
    }
);

export const resetShippingAddress = createAsyncThunk(
    "checkout/resetShippingAddress",
    async (_, thunkAPI) => {
        try {
            await API.custom(
                "com.matterbit.project.ProjectAction",
                "manageTransactionCart",
                {
                    resetShippingAddress: "true",
                }
            );
            return thunkAPI.dispatch(getCart());
        } catch (error) {
            console.log("error");
            return thunkAPI.rejectWithValue({
                err: "Errore durante il reset dell'indirizzo di spedizione",
            });
        }
    }
);

export const createPaymentIntent = async (transactionId) => {
    try {
        const res = await API.custom(
            "work.bitframe.api.StripeAction",
            "createPaymentIntent",
            {
                id_transaction: "" + transactionId,
            }
        );
        console.log(res);
        return res.s_stripe_payment_intent_client_secret;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const cartPaymentCompleted = async ({ s_uuid, id_transaction }) => {
    try {
        await API.custom(
            "com.matterbit.project.ProjectAction",
            "createOrderFromCart",
            {
                id_transaction,
                s_uuid,
            }
        );

        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getPaymentMethods = async () => {
    try {
        const { rows } = await API.query(
            DB.PAYMENT_METHODS,
            "b_ecommerce=1|b_active=1"
        );
        return rows;
    } catch (error) {
        console.error(error);
        return false;
    }
};