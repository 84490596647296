import { API } from "@kimiya/core/core/api.class";
import { axios } from "@kimiya/core/utils/axios";
import DB from "@kimiya/core/core/db-tables";
import {
    PROFILE_DOC_TYPE,
    PROFILE_ENTITY_ID,
    ORDER_TRANSACTION_TYPE,
} from "@kimiya/core/core/defaults-values";
import { getMaxItemFromArrayByAttribute } from "@kimiya/core/utils";

const PROJECT_PREFIX = process.env.REACT_APP_PRJ_PREFIX;
const ENDPOINT = `${process.env.REACT_APP_BASE_URL}/api/`;

export const getProfile = async () => {
    try {
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));
        const { rows } = await API.query(
            DB.RESOURCES,
            `id_resource~${user.id_resource}`
        );
        return rows[0];
    } catch (error) {
        console.log("error");
    }
};
export const getSettings = async () => {
    try {
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));
        const where = `id_resource~${user.id_resource}`;
        const { rows } = await API.query(DB.PROFILE, where);
        return rows[0];
    } catch (error) {
        console.log("error");
    }
};

export const getUserSubscription = async () => {
    try {
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));
        if (!user.subscription) {
            return null;
        }
        const where = `id_item~${user.subscription.id_item}`;
        const include = `id_license_duration~mb_item~id_license_duration~mb_license_duration~1`;
        const { rows } = await API.query(
            DB.SUBSCRIPTIONS,
            where,
            "",
            "",
            include
        );
        return rows[0];
    } catch (error) {
        console.log("error");
    }
};

export const getAddresses = async () => {
    try {
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));
        const where = `id_resource~${user.id_resource}|b_outdated~0`;
        const { rows } = await API.query(DB.ADDRESSES, where);
        return rows;
    } catch (error) {
        console.log("error");
    }
};

export const getCompanyAddresses = async () => {
    try {
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));
        if (!user.id_resource_billing) {
            return [];
        }
        const where = `id_resource~${user.id_resource_billing}`;
        const { rows } = await API.query(DB.ADDRESSES, where);
        return rows;
    } catch (error) {
        console.log("error");
    }
};

export const getCompanyInfo = async () => {
    try {
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));
        if (!user.id_resource_billing) {
            return null;
        }
        const where = `id_resource~${user.id_resource_billing}`;
        const { rows } = await API.query(DB.RESOURCES, where);
        return rows[0];
    } catch (error) {
        console.log("error");
    }
};

export const getAddress = async (id_address) => {
    try {
        const where = `id_address~${id_address}`;
        const { rows } = await API.query(DB.ADDRESSES, where);
        return rows[0];
    } catch (error) {
        console.log("error");
    }
};

export const createAddress = async (keys_values) => {
    try {
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));
        const { primary_key } = await API.create(
            DB.ADDRESSES,
            `${keys_values}|id_resource~${user.id_resource}`
        );
        return primary_key.split("~")[1];
    } catch (error) {
        console.dir(error);
        return false;
    }
};

export const updateAddress = async (keys_values) => {
    try {
        await API.update(DB.ADDRESSES, keys_values);
        return true;
    } catch (error) {
        console.dir(error);
        return false;
    }
};

export const deleteAddress = async (keys_values) => {
    try {
        await API.update(DB.ADDRESSES, keys_values);
        return true;
    } catch (error) {
        console.dir(error);
        return false;
    }
};

export const updateSettings = async (keys_values) => {
    try {
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));
        await API.update(
            DB.PROFILE,
            `${keys_values}|id_resource~${user.id_resource}`
        );
        return true;
    } catch (error) {
        console.dir(error);
        return false;
    }
};

export const getProfilePicture = async () => {
    try {
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));
        const where = [
            `id_file_entity_type~${PROFILE_ENTITY_ID}`,
            `idl_file_entity~${user.id_resource}`,
            `id_document_type~${PROFILE_DOC_TYPE}`,
        ];
        const { rows } = await API.query(DB.FILES, where.join("|"));
        if (rows.length === 0) return null;
        if (rows.length === 1) return rows[0];
        return getMaxItemFromArrayByAttribute(rows, "d_creation");
    } catch (error) {
        console.log("error");
    }
};

export const uploadProfilePicture = async (file) => {
    try {
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));
        const token = localStorage.getItem(`${PROJECT_PREFIX}TOKEN`);

        var bodyFormData = new FormData();
        bodyFormData.append("action_type", "custom");
        bodyFormData.append(
            "action_custom_class",
            "work.bitframe.api.SystemAction"
        );
        bodyFormData.append("action_custom_method", "uploadFile");
        bodyFormData.append("id_resource_nav", user.id_resource);
        bodyFormData.append("auth_token", token);
        bodyFormData.append("id_file_entity_type", PROFILE_ENTITY_ID);
        bodyFormData.append("id_document_type", PROFILE_DOC_TYPE);
        bodyFormData.append("id_entity", user.id_resource);
        bodyFormData.append("file", file);

        const res = await axios({
            method: "POST",
            url: ENDPOINT,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        });

        console.log(res);
        return true;
    } catch (error) {
        console.dir(error);
        return false;
    }
};

export const getOrders = async () => {
    try {
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));
        const where = [
            `id_resource_transaction~${
                user.id_resource_billing || user.id_resource
            }`,
            `id_transaction_type~${ORDER_TRANSACTION_TYPE}`,
        ];
        const { rows } = await API.query(DB.TRANSACTIONS, where.join("|"));
        return rows;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getOrder = async (id_transaction) => {
    try {
        const includes = [
            "id_resource_transaction~mb_transaction~id_resource~bf_resource~1",
            "id_address_transaction~mb_transaction~id_address~bf_address~1",
            "id_resource_shipping_delivery~mb_transaction~id_resource~bf_resource~1",
            "id_address_shipping_delivery~mb_transaction~id_address~bf_address~1",
            "id_shipping_type~mb_transaction~id_shipping_type~mb_shipping_type~1",
        ];
        const { rows: order } = await API.query(
            DB.TRANSACTIONS,
            `id_transaction~${id_transaction}`,
            "",
            "",
            includes.join("|")
        );

        const { rows: items } = await API.query(
            DB.TRANSACTION_ITEMS,
            `id_transaction~${id_transaction}|id_transaction_item_container~0`
        );
        return {
            ...order[0],
            items,
        };
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getOrderInvoices = async ({ id_transaction, s_uuid }) => {
    try {

        return await API.custom(
          "com.matterbit.project.ProjectAction",
          "getOrderInvoice",
          {
            id_transaction,
            s_uuid
          }
        )

    } catch (error) {
        console.error(error);
    }
};
