import { useCallback, forwardRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { archiveChat, getChats, getChat } from "@kimiya/core/store/actions/coaching";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import ChatIcon from "@material-ui/icons/Forum";
import ArchiveIcon from "@material-ui/icons/Archive";

import useChatStyles from "@kimiya/custom/coach/chat.styles";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ChatHeader = ({ chat }) => {
    const classes = useChatStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const handleOpenConfirmDialog = () => {
        setOpenConfirmDialog(true);
    };
    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    };

    const refetchChats = useCallback(() => {
        dispatch(getChats());
    }, [dispatch]);

    const refetchChat = useCallback(() => {
        dispatch(getChat(chat.id_support));
    }, [dispatch, chat]);

    const archiveChatHandler = async () => {
        const res = await archiveChat(chat.id_support);
        if (!res) {
            alert(
                t(
                    "Errore durante l'archiviazione' della chat. Si prega di riprovare. Grazie"
                )
            );
            return;
        }
        handleCloseConfirmDialog()
        refetchChat();
        refetchChats();
    };

    return (
        <>
            <div className={classes.chatHeader}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                        >
                            <Grid item>
                                <Avatar className={classes.chatAvatar}>
                                    <ChatIcon fontSize="small" />
                                </Avatar>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.chatHeaderTitle}>
                                    {chat.s_support_request}
                                </Typography>
                                <Typography
                                    className={classes.chatHeaderSubtitle}
                                    variant="subtitle2"
                                >
                                    {chat.s_support_subject.replace("... per ", "")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {!chat.b_completed && (
                            <Button
                                variant="contained"
                                color={"primary"}
                                onClick={handleOpenConfirmDialog}
                                startIcon={<ArchiveIcon />}
                            >
                                {t("Archivia Chat")}
                            </Button>
                        )}
                    </Grid>
                </Grid>            
            </div>
            <Dialog
                open={openConfirmDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseConfirmDialog}
                aria-labelledby="confirm-archive-dialog-title"
                aria-describedby="confirm-archive-dialog-description"
            >
                <DialogTitle id="confirm-archive-dialog-title">
                    {t('Conferma Azione')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-archive-dialog-description">
                        {t('Procedere con l\'archiviazione della Chat?')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmDialog} color="primary">
                        {t('annulla')}
                    </Button>
                    <Button onClick={archiveChatHandler} color="primary">
                        {t('Conferma')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ChatHeader;
