import { useState } from "react"
import { useTranslation } from "react-i18next";
import { DialogConsumer } from "@kimiya/context/dialog.context";
import { useSelector } from "react-redux";
import { useQuery } from "react-query"
import { getUserSubscription } from "@kimiya/core/store/actions/profile"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { getUserPaymentMethod, updateSubscriptionPaymentMethod } from "@kimiya/core/store/actions/payments";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";

import Backdrop from "@kimiya/ui/backdrop/backdrop"
import ContainedButton from "@kimiya/ui/button/contained-button.component";
import OutlinedButton from "@kimiya/ui/button/outlined-button.component";
import QuitSubscriptionDialog from "@kimiya/dialogs/quit-subscription/quit-subscription.dialog"
import SubscriptionCard from "@kimiya/ui/subscription-card/subscription-card";
import StripeCardInput from "@kimiya/custom/stripe/card-element"

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
    sectionTitle: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(38),
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.pxToRem(28),
        }
    },
    sectionSubTitle: { 
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(28),
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.pxToRem(18),
        }
    },
    card: {
        borderRadius: 10,
        margin: theme.spacing(2, 0, 4),
    },
    subscriptionName: {
        color: theme.palette.secondary.main,
        fontFamily: "Brown-Bold",
        fontSize: theme.typography.pxToRem(26),
    },
    subscriptionPrice: {
        color: theme.palette.primary.main,
        fontFamily: "Brown-Bold",
        fontSize: theme.typography.pxToRem(26),
    },
    subscriptionPricePeriod: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(16),
    },
    subscriptionBillingType: {
        color: theme.palette.secondary.main,
        fontSize: theme.typography.pxToRem(16),
    },
    subscriptionText: {
        color: theme.palette.grey5.main,
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(16),
        letterSpacing: 0.44,
    },
    paymentContainer: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid  ${theme.palette.secondary.main}`,
        borderRadius: 20,
        marginTop: 15,
        padding: `20px  !important`,
        width: "100%"
    },
    divider:{
        marginTop: 25,
        marginBottom: 25
    }, 
}));

const ProfileSubscription = () => {
    const classes = useStyle();
    const { t } = useTranslation();    
    const stripe = useStripe();
    const elements = useElements();
    const user = useSelector(({ auth }) => auth.user)
    const [subscription, setSubscription] = useState()
    const [loading, setLoading] = useState()
    const [isCardOk, setIsCardOk] = useState(false)    

    const refetchSubscription = async () => {        
        let subscription = await getUserSubscription()        
        const paymentInfo = await getUserPaymentMethod()
        if(paymentInfo){
            subscription = {...subscription, paymentInfo}
        }
        setSubscription(subscription ? subscription : [])
        return subscription
    }

    const handleChangePaymentMethod = async () => {

        setLoading(true)

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            console.error("Payment Gateway not ready!")
            toast.error(t("Payment Gateway not ready!"));
            setLoading(false)
            return;
        }

        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                email: user.email,
                name: user.s_resource
            },
        });

        if (result.error) {
            setLoading(false)
            console.log(result.error.message);
            toast.error(result.error.message);
            return;
        }

        const res = await updateSubscriptionPaymentMethod({
            s_payment_method: result.paymentMethod.id,
            id_resource_billing: subscription.paymentInfo.id_resource_billing
        })

        if(!res){
            console.error(res)
            toast.error(t("Errore durante l'aggiornamento del metodo di pagamento!"));
            return;
        }

        toast.success(t("Metodo di pagamento aggiornato con successo!"));
    };

    const onCardChange = ({ empty, complete }) =>  {
        if(empty || !complete){
            setIsCardOk(false)
            return;
        }
        setIsCardOk(true)

    }

    const { isLoading, error } = useQuery(["subscription"], refetchSubscription)

    if (isLoading || !subscription) return <Backdrop open customText={t('Fetching subscription...')} />
    if (error) return "An error has occurred: " + error.message;    

    console.log(subscription)

    if(!subscription || subscription.length===0){
        return (
            <>
                <Backdrop open={loading} customText={t('Aggiornando le informazioni...')} />
                <Typography className={classes.sectionTitle}>
                    {t("Il tuo abbonamento")}
                </Typography>
                <Typography className={classes.subscriptionText}>
                    {t("Nessun abbonamento associato al tuo account")}
                </Typography>
            </>
        )
    }

    return (
        <>
            <Typography className={classes.sectionTitle}>
                {t("Il tuo abbonamento")}
            </Typography>
            <SubscriptionCard item={subscription} onSelect={() => {}} fullWidth />
            <Divider variant="middle" className={classes.divider} />
            <Typography className={classes.sectionSubTitle}>
                {t("Dati pagamento attualmente in uso")}:
            </Typography>
            {subscription?.paymentInfo ? (
                <>                        
                    <Typography component="p">{t('Carta di Credito')}: xxxxxxxxxxxx{subscription?.paymentInfo?.object?.card?.last4}</Typography>
                    <Typography component="p">{t('Titolare Carta')}: {subscription?.paymentInfo?.object?.billing_details?.name}</Typography>
                    <Typography component="p">{t('Scadenza')}: {subscription?.paymentInfo?.object?.card?.exp_month}/{subscription?.paymentInfo?.object?.card?.exp_year}</Typography>
                </>
            ) : (
                <Typography>{t('Nessun metodo di pagamento attualmente associato. Si prega di contattare Kimiya per ulteriori informazioni.')}</Typography>
            )}
            <Divider variant="middle" className={classes.divider} />            
            <Typography className={classes.sectionSubTitle}>
                {t("Aggiorna metodo di pagamento")}
            </Typography>            
            <Typography className={classes.recapText}>
                {t("Inserisci i dati della tua nuova carta per aggiornare il metodo di pagamento")}:
            </Typography>
            <div className={classes.paymentContainer}>
                <StripeCardInput onChange={onCardChange} />
            </div>            
            <Box justifyContent="center" display={'flex'} mt={2} mb={6}>
                <ContainedButton
                    type="submit"
                    variant="contained"
                    color="secondary"          
                    disabled={!isCardOk}      
                    onClick={handleChangePaymentMethod}                
                >
                    {t("Aggiorna metodo di pagamento")}
                </ContainedButton>
            </Box>
            <Divider variant="middle" className={classes.divider} />   
            <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={4}
            >
                <Grid item>
                    <DialogConsumer>
                        {({ showDialog }) => (
                            <OutlinedButton
                                variant="outlined"
                                onClick={() => {
                                    showDialog(QuitSubscriptionDialog, {
                                        title: "Vuoi davvero disdire?",                                    
                                    })
                                }}
                            >
                                {t("Disdici abbonamento")}
                            </OutlinedButton>
                        )}
                    </DialogConsumer>
                </Grid>                
            </Grid>
        </>
    );
};

export default ProfileSubscription;
