import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import useStyle from "@kimiya/custom/order/order.style"

const OrderItems = ({ items }) => {

    const { t } = useTranslation();
    const classes = useStyle();

    return (
        <Grid container justifyContent="flex-start" alignItems="stretch">
            <Grid item xs={12}>
                <Typography className={classes.cardTitle}>
                    {t('Dettagli ordine')}
                </Typography>
                <Divider className={classes.divider} />
                {items && items.map((item) => (
                    <Grid
                        key={item.id_item}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.cartItemRow}
                        >
                        <Grid item>
                            <Typography className={classes.cartText}>
                            {item.m_quantity}{t('pz')}. - {item.s_item}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.cartText}>
                            {item.m_price_total_final_gross.toFixed(2)} €
                            </Typography>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

export default OrderItems