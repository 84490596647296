import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const OutlinedButton = withStyles((theme) => ({
    root: {
        border: `1px solid ${theme.palette.secondary.main}`,
        color: theme.palette.primary.contrastText,
    },
    label: {
        color: theme.palette.secondary.contrastText,        
    },
}))(Button);

export default OutlinedButton;
