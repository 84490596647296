import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Card from "@kimiya/ui/card/card";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    cursor: "pointer",
  },
  orderNumber: {
    fontFamily: "Brown-Light",
    fontSize: 16,
    color: theme.palette.grey5.main,
    letterSpacing: 0.44,
    marginBottom: theme.spacing(1),
  },
  orderDate: {
    fontSize: 14,
    color: theme.palette.secondary.main,
  },
  orderTotal: {
    fontFamily: "Brown-Bold",
    fontSize: 16,
    color: theme.palette.primary.main,
  },
}));

const OrderCard = ({ order_id, order_number, order_date, total }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const history = useHistory();

  const goToOrder = () => history.push(`/order-details/${order_id}`);

  return (
    <Card size="small" customClasses={classes.root} onClick={() => goToOrder()}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography className={classes.orderNumber}>
            {order_number}
          </Typography>
          <Typography className={classes.orderDate}>
            {t('del')} {order_date}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="text"
            endIcon={<ChevronRightIcon />}
            className={classes.orderTotal}
          >
            {total}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export default OrderCard;

