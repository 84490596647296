import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    mobileMenuIcon: {
        color: theme.palette.primary.main
    },
    mobileDivider: {
        backgroundColor: theme.palette.secondary.main,
        margin: theme.spacing(2,0,2,0),        
    }    
}))