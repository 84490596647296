import { useTranslation } from "react-i18next";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import KimiyaLogoBlue from "@kimiya/core/images/kimiya-logo-blue.svg";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  registrationCompletedTitle: {
    fontFamily: "Brown-Light",
    fontSize: 20,
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

const PasswordResetRequestSuccessPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} align="center">
          <img src={KimiyaLogoBlue} alt="kimiya logo blue" />
        </Grid>
        <Grid item xs={12} align="center">
          <Typography className={classes.registrationCompletedTitle}>
            {t("Richiesta Reset password inviata con successo!")}
          </Typography>
        </Grid>        
        <Grid item xs={12} align="center">
          <Typography className={classes.registrationCompletedTitle}>
            {t("A breve riceverai una email con un link da cliccare per completare il reset della tua password")}
          </Typography>
        </Grid>        
      </Grid>
    </Container>
  );
};

export default PasswordResetRequestSuccessPage;

