import { useState, Fragment } from "react"
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom"
import { useQuery } from 'react-query'
import { getItem, getItemImage, getItemNutritious, getItemIngredients } from "@kimiya/core/store/actions/shop";
import { SHOP_ALLERGEN_ITEM, SHOP_INGREDIENT_ITEM, SHOP_INGREDIENT_ITEM_ALLERGEN, SHOP_NUTRITIOUS_TYPE} from "@kimiya/core/core/combined-fields-mapper"

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Chip from '@material-ui/core/Chip';
import Divider from "@material-ui/core/Divider";

import Card from "@kimiya/ui/card/card"
import Backdrop from "@kimiya/ui/backdrop/backdrop"
import ProductPriceCart from '@kimiya/custom/product/product-price-cart.component'

import FoodPlaceholder from "@kimiya/core/images/food-placeholder.jpg"
import BackIcon from "@material-ui/icons/KeyboardBackspace";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(3)
    },
    card: {
        borderRadius: 10,
        marginTop: 150
    },
    productTitle: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(38),
        color: theme.palette.primary.main,
        lineHeight: 1,
        padding: 0,
        margin: 0,
    },
    productDescription: {
        color: theme.palette.secondary.main,
        fontSize: theme.typography.pxToRem(14)
    },
    productImageContainer:{        
        width: "100%", 
        height: 150,       
        justifyContent: "center",
        alignItems: "center",
        display: "flex",        
    },
    productImage: {               
        borderRadius: "50%",
        width: 250,
        height: 250,           
        marginTop: -150,
        border: `1px solid ${theme.palette.secondary.main}`,
        objectFit: 'cover'
    },
    content: {
        padding: theme.spacing(3)
    },
    divider: {
        margin: theme.spacing(2,0)
    },
    sectionTitle: {
        fontSize: 14,
        color: theme.palette.secondary.main,        
    },
    sectionText: {
        fontFamily: "Brown-Light",
        fontSize: 16,
        color: theme.palette.grey5.main,
        letterSpacing: 0.44,
        marginBottom: theme.spacing(4)
    }
}));

const Shop = ({ history }) => {
    const classes = useStyle();
    const { t } = useTranslation();
    const { product_code } = useParams();
    
    const [item, setItem ] = useState()  
    const [allergens, setAllergens] = useState()
    const [ingredients, setIngredients] = useState()
    const [nutritious, setNutritious] = useState()  

    const goBack = () => history.goBack();

    const refetchItem = async () => {
        const item = await getItem(product_code)        
        setItem(item)
        return item
    }

    const refetchItemImage = async () => {
        const image = await getItemImage(item.id_item)        
        if(image)
            setItem({...item, s_image: `${process.env.REACT_APP_BASE_URL}/files/1/${image.s_file_name}.${image.s_file_ext}`})
        return image
    }

    const fetchIngredients = async () => {
        const res = await getItemIngredients(item.id_item)        
        if(res){
            let ingredientsAllergens = await res.filter(ing => ing[SHOP_INGREDIENT_ITEM][SHOP_INGREDIENT_ITEM_ALLERGEN] ? true : false);
            let allergens = await ingredientsAllergens.map(a => a[SHOP_INGREDIENT_ITEM][SHOP_INGREDIENT_ITEM_ALLERGEN][SHOP_ALLERGEN_ITEM].s_allergen)            
            if(allergens && allergens.length>0) allergens = [...new Set(allergens)]
            setAllergens(allergens)
            setIngredients(res)
        }
    }
    const fetchNutritious = async () => {
        const res = await getItemNutritious(item.id_item)        
        if(res){
            setNutritious(res)
        }
    }

    const { isLoading, error } = useQuery(["item", product_code], refetchItem);

    useQuery(["item-image"], refetchItemImage, {
        enabled: !!item
    });
    useQuery(["item-ingredients"], fetchIngredients, {
        enabled: !!item
    });
    useQuery(["item-nutritious"], fetchNutritious, {
        enabled: !!item
    });

    if (isLoading || !item) return <Backdrop open customText={t('Fetching shop item...')} />
    if (error) return "An error has occurred: " + error.message;

    return (
        <Container maxWidth={'md'} className={classes.root}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography className={classes.productTitle}>
                        {item.s_item}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        startIcon={<BackIcon />}
                        variant="text"
                        onClick={goBack}
                        color="secondary"
                    >
                        {t("Back")}
                    </Button>
                </Grid>
            </Grid>
            {item.s_item_descr && (
                <Typography className={classes.productDescription}>{item.s_item_descr}</Typography>
            )}
            <Card size="full" customClasses={classes.card}>
                <div className={classes.productImageContainer}>
                    {item ? (
                        <img 
                            src={`${item.s_image ? item.s_image : FoodPlaceholder}`} 
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src=FoodPlaceholder;
                            }} 
                            className={classes.productImage} 
                            alt="product" 
                        />
                    ) : (
                        <img 
                            src={FoodPlaceholder}
                            className={classes.productImage} 
                            alt="product" 
                        />
                    )}
                </div>
                <div className={classes.content}>
                    <ProductPriceCart itemId={item.id_item} price={`${item.m_sell_price_taxed.toFixed(2)}€`} />
                    <Divider className={classes.divider} />                    
                    {(allergens && allergens.length>0) && (
                        <>
                            <Typography className={classes.sectionTitle}>
                                {t("Allergeni")}:
                            </Typography>
                            <Typography className={classes.sectionText}>
                                {allergens.map((a,index) => (
                                    <Chip 
                                        key={index} 
                                        label={a} 
                                        variant="outlined" 
                                        style={{ marginRight: 5, marginTop: 5 }} 
                                    />                                    
                                ))}
                            </Typography>
                        </>
                    )}   
                    {(ingredients && ingredients.filter(i => i[SHOP_INGREDIENT_ITEM].b_food && i[SHOP_INGREDIENT_ITEM].id_catalog === 1 & i[SHOP_INGREDIENT_ITEM].id_category === 2).length>0) && (
                        <>
                            <Typography className={classes.sectionTitle}>
                                {t("Ingredienti")}:
                            </Typography>
                            <Typography className={classes.sectionText}>
                                {ingredients.filter(i => i[SHOP_INGREDIENT_ITEM].b_food && i[SHOP_INGREDIENT_ITEM].id_catalog === 1 & i[SHOP_INGREDIENT_ITEM].id_category === 2).map((i,index) => (
                                    <Chip 
                                        key={index} 
                                        label={i[SHOP_INGREDIENT_ITEM].s_item} 
                                        variant="outlined" 
                                        style={{ marginRight: 5, marginTop: 5 }} 
                                    />
                                ))}
                            </Typography>
                        </>
                    )} 
                    {(nutritious && nutritious.length>0) && (
                        <>
                            <Typography className={classes.sectionTitle}>
                                {t("Valori Nutrizionali")}:
                            </Typography>
                            <Typography className={classes.sectionText}>
                                {nutritious.map((n,index) => (
                                    <Chip 
                                        key={index} 
                                        label={(n.n_item_nutritious_kcal || n.n_item_nutritious_kj) 
                                            ? `Kcal ${n.n_item_nutritious_kcal} / kj ${n.n_item_nutritious_kj} ` 
                                            : `${n[SHOP_NUTRITIOUS_TYPE].s_nutritious_type} (g) ${n.m_item_nutritious_g} `
                                        }
                                        variant="outlined" 
                                        style={{ marginRight: 5, marginTop: 5 }} 
                                    />                                    
                                ))}                            
                            </Typography>
                        </>
                    )}                                                      
                    {item.s_use_guide!=="" && (
                        <>
                            <Divider className={classes.divider} />
                            <Typography className={classes.sectionText}>Guida all'utilizzo</Typography>
                            <Typography className={classes.sectionText}>{item.s_use_guide}</Typography>
                        </>
                    )}
                </div>
            </Card>
        </Container>
    );
};

export default Shop;
