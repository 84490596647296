import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "@kimiya/core/utils/axios";
import { API } from "@kimiya/core/core/api.class";
import DB from "@kimiya/core/core/db-tables";
import { decodeHtmlCharCodes } from "@kimiya/core/utils";
import {
    SUBSCRIPTION_CATEGORY_ID,
    SUBSCRIPTION_SUBCATEGORY_ID,
} from "@kimiya/core/utils/default_values";

const PROJECT_PREFIX = process.env.REACT_APP_PRJ_PREFIX;
// const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const isLoggedIn = createAsyncThunk("auth/isLoggedIn", async (_,thunkAPI) => {
    try {
        const token = localStorage.getItem(`${PROJECT_PREFIX}TOKEN`);
        let user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));
        if (!token) {            
            return { token: null, user: null };
        }
        await API.custom("work.bitframe.api.SystemAction", "checkAuthToken", {
            auth_token: token,
            id_resource_nav: user.id_resource,
        });
        axios.defaults.headers.common["Authorization"] = token;

        const res_resource = await API.query(
            DB.RESOURCES,
            `id_resource~${user.id_resource}`
        );

        const res_profile = await API.query(
            DB.PROFILE,
            `id_resource~${user.id_resource}`
        );

        const res_address = await API.query(
            DB.ADDRESSES,
            `id_resource~${user.id_resource}|b_legal~true`
        );

        user = {
            ...user,
            s_tax_code: res_resource.rows[0],
            b_billing_completed: res_profile.rows[0].b_billing_completed,
            billing_address: res_address.rows[0]
        };
        return { token, user }

    } catch (error) {
        console.dir(error);
        return thunkAPI.rejectWithValue({
            err: 'Token o utente non valido'
        });
    }
});

export const login = async (username, password) => {
    try {
        const {
            s_resource,
            s_tax_code,
            id_resource,
            id_resource_billing,
            b_registration_completed,
            b_working,
            b_billing_completed,
            auth_token,
        } = await API.custom("com.matterbit.project.ProjectAction", "login", {
            username, //s.landi@meetico.ltd
            password, //"69575495"
        });

        const user = {
            email: username,
            s_resource,
            s_tax_code,
            id_resource,
            id_resource_billing,
            isCoach: b_working ? true : false,
            b_registration_completed:
                b_registration_completed === "false" ? false : true,
            b_billing_completed: b_billing_completed === "false" ? false : true,
        };
        localStorage.setItem(`${PROJECT_PREFIX}TOKEN`, auth_token);
        localStorage.setItem(`${PROJECT_PREFIX}USR`, JSON.stringify(user));
        axios.defaults.headers.common["Authorization"] = auth_token;

        const subscription_res = await API.custom(
            "com.matterbit.project.ProjectAction",
            "getSubscriptions"
        );

        if (subscription_res.length > 0) {
            const subscription = await subscription_res.find(
                (s) =>
                    s.id_category === SUBSCRIPTION_CATEGORY_ID &&
                    s.id_subcategory === SUBSCRIPTION_SUBCATEGORY_ID
            );
            if (subscription) {
                user.subscription = subscription;
                localStorage.setItem(
                    `${PROJECT_PREFIX}USR`,
                    JSON.stringify(user)
                );
            }
        }

        return user;
    } catch (error) {
        console.dir(error);
        if (error.response.status === 417) {
            return "NOT_CONFIRMED";
        }
        if (error.response.data.event) {
            return await decodeHtmlCharCodes(error.response.data.event);
        }
        return false;
    }
};

export const getBillingData = createAsyncThunk("auth/getBillingData", async (_,thunkAPI) => {
    try {

        const initBillingData = {
            s_tax_code: "",
            s_address: "",
            s_zip_code: "",
            id_city: "",
            id_county: "",
            id_region: "",
            id_country: 380,
            b_company: false,
            s_denomination: "",
            s_tax_code_company: "",
            s_vat_code_company: "",
            s_address_company: "",
            s_zip_code_company: "",
            id_city_company: "",
            id_county_company: "",
            id_region_company: "",
            id_country_company: 380,
            s_fe_type: "sdi",
            s_fe_sdi: "",
            s_fe_pec: "",
            b_ads: false,
            b_ads2: false,
            b_privacy_medical: false,
        };

        let res = await API.custom("com.matterbit.project.ProjectAction","getBillingData");                
        if(res.id_resource_billing){
            res = {
                ...initBillingData,
                ...res,                
                b_company: true,
                s_fe_type: res.s_fe_sdi ? 'sdi' : res.s_fe_pec ? 'pec' : "sdi"
            }
        }
        return {
            ...initBillingData,
            ...res,
            b_company: false,
            b_ads: res.b_ads==='true' ? true : false,
            b_ads2: res.b_ads2==='true' ? true : false,
            b_privacy_medical: res.b_privacy_medical==='true' ? true : false,
        };
    } catch (error) {
        console.dir(error);
        return thunkAPI.rejectWithValue({
            err: 'Errore durante la ripresa degli indirizzi dell\'utente'
        });        
    }

})

export const updateBillingAddress = async (billingData) => {
    try {
        console.log(billingData);
        return true;
    } catch (error) {
        console.dir(error);
        return false;    
    }
}



export const logout = () => {
    localStorage.removeItem(`${PROJECT_PREFIX}TOKEN`);
    localStorage.removeItem(`${PROJECT_PREFIX}USR`);
    axios.defaults.headers.common["Authorization"] = "";
    return true;
};

export const changePassword = async (s_password) => {
    try {
        await API.custom(
            "com.matterbit.project.ProjectAction",
            "changePassword",
            {
                s_password,
            }
        );
        return true;
    } catch (error) {
        console.log("error");
        return false;
    }
};

export const changeUsername = async (s_confirm_email) => {
    try {
        await API.custom("com.matterbit.project.ProjectAction", "changeEmail", {
            s_confirm_email,
        });
        return true;
    } catch (error) {
        console.log("error");
        return false;
    }
};

export const checkUsername = (s_username) => async () => {
    try {
        const res = await API.custom(
            "work.bitframe.api.SystemAction",
            "lookupResourceUUID",
            { s_username }
        );
        return res.s_uuid;
    } catch (error) {
        console.dir(error);
        return false;
    }
};

export const verifyEmail = async (
    token,
    secret,
    id_resource,
    s_confirm_email
) => {
    try {
        await API.custom(
            "com.matterbit.project.ProjectAction",
            "confirmEmail",
            {
                s_api_token: token,
                s_api_secret: secret,
                s_confirm_email,
                id_resource,
            }
        );

        return true;
    } catch (error) {
        console.dir(error);
        if (error.response.data.event) {
            return await decodeHtmlCharCodes(error.response.data.event);
        }
        return false;
    }
};

export const verifyMobileNumber = async (
    token,
    secret,
    id_resource,
    s_confirm_mobile_number
) => {
    try {
        await API.custom(
            "com.matterbit.project.ProjectAction",
            "confirmMobileNumber",
            {
                s_api_token: token,
                s_api_secret: secret,
                s_confirm_mobile_number,
                id_resource,
            }
        );

        return true;
    } catch (error) {
        console.dir(error);
        if (error.response.data.event) {
            return await decodeHtmlCharCodes(error.response.data.event);
        }
        return false;
    }
};

export const requestResetPassword = async ({s_username}) => {
    try {
        await API.custom("com.matterbit.project.ProjectAction", "sendResetPasswordMessage", {
            s_username,           
        });
        return true;
    } catch (error) {
        console.dir(error);
        return false;
    }
};

export const resetPassword = async ({s_api_token, s_api_secret, s_username, s_password}) => {
    try {
        await API.custom("com.matterbit.project.ProjectAction", "resetPassword", {
            s_api_token,
            s_api_secret,
            s_username,  
            s_password         
        });
        return true;
    } catch (error) {
        console.dir(error);
        return false;
    }
};

export const registerPersonal = async (personalBody) => {
    try {
        const res = await API.custom(
            "com.matterbit.project.ProjectAction",
            "registerUser",
            personalBody
        );
        return res;
    } catch (error) {
        console.dir(error);
        if (error.response.data.errors) {
            let messages = await error.response.data.errors.map((error) =>
                decodeHtmlCharCodes(error.message)
            );
            console.dir({ messages });
            return messages.join("\n");
        }
        return false;
    }
};

export const registerBilling = async (billingInfo) => {
    try {        
        const res = await API.custom(
            "com.matterbit.project.ProjectAction",
            "registerBilling",
            billingInfo
        );
        let user = await JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));
        localStorage.setItem(`${PROJECT_PREFIX}USR`,JSON.stringify({...user, b_billing_completed: true }))
        return res;
    } catch (error) {
        console.dir(error);
        if (error.response.data.errors) {
            let messages = await error.response.data.errors.map((error) =>
                decodeHtmlCharCodes(error.message)
            );
            console.dir({ messages });
            return { error: true, message: messages.join("\n")};
        }
        if (error.response.data.event) {
            if (
                error.response.data.event.includes(
                    "Codice fiscale relativo ad una Anagrafica gi"
                )
            ) {
                return { error: true, message : "Codice fiscale relativo ad una Anagrafica già registrato" };
            }
            return {error: true, message: error.response.data.event};
        }
        return { error: true, message: "Errore durante l'esecuzione dell'operazione"};
    }
};

export const confirmUser = async (token, secret, id_resource) => {
    try {
        await API.custom("com.matterbit.project.ProjectAction", "confirmUser", {
            s_api_token: token,
            s_api_secret: secret,
            id_resource,
        });

        return true;
    } catch (error) {
        console.dir(error);
        if (error.response.data.event) {
            return await decodeHtmlCharCodes(error.response.data.event);
        }
        return false;
    }
};

export const resendVerifactionEmail = async (email) => {
    try {
        await API.custom(
            "com.matterbit.project.ProjectAction",
            "sendConfirmUserMessage",
            {
                s_username: email,
            }
        );
        return true;
    } catch (error) {
        console.dir(error);
        if (error.response.data.event) {
            return await decodeHtmlCharCodes(error.response.data.event);
        }
        return false;
    }
};

export const completeUserRegistration = async () => {
    try {
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));
        const where = `id_resource~${user.id_resource}|b_registration_completed~1`;
        await API.update(DB.RESOURCES, where);
        return true;
    } catch (error) {
        console.dir(error);
        if (error?.response?.data?.event || error?.data?.event) {
            if (error.response.data.event)
                return await decodeHtmlCharCodes(error.response.data.event);
            if (error.response.data.event)
                return await decodeHtmlCharCodes(error.data.event);
        }
        return false;
    }
};

export const getSubscriptions = async () => {
    try {
        const where = `id_category~3|id_subcategory~6`;
        const { rows } = await API.query(DB.SUBSCRIPTIONS, where);
        return rows;
    } catch (error) {
        console.dir(error);
        if (error.response.data.event) {
            return await decodeHtmlCharCodes(error.response.data.event);
        }
        return false;
    }
};

export const getSubscription = async (id_item) => {
    try {
        const where = `id_item~${id_item}`;
        const include = `id_license_duration~mb_item~id_license_duration~mb_license_duration~1`;
        const { rows } = await API.query(
            DB.SUBSCRIPTIONS,
            where,
            "",
            "",
            include
        );
        return rows[0];
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const createUserSubscription = async (id_item) => {
    try {
        const where = `id_item~${id_item}`;
        const include = `id_license_duration~mb_item~id_license_duration~mb_license_duration~1`;
        const { rows } = await API.query(
            DB.SUBSCRIPTIONS,
            where,
            "",
            "",
            include
        );
        return rows[0];
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const getUser = async () => {
    try {
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));
        const where = `id_resource~${user.id_resource}`;
        const { rows } = await API.query(
            DB.RESOURCES,
            where,
            "s_username|b_email_verified"
        );
        return rows[0];
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const updateUserInfo = async (keyValues) => {
    try {
        const user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));
        await API.update(
            DB.RESOURCES,
            `${keyValues}|id_resource~${user.id_resource}`
        );
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const requestCloseAccount = async (b_deleted_request) => {
    try {
        await API.custom(
            "work.bitframe.api.SystemAction",
            "resourceDeleteRequest",
            {
                b_deleted_request
            }
        )
        return true
    } catch (error) {
        console.log(error);
        return false;   
    }
}
