import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from 'react-query'
import { toast } from "react-toastify";
import { registerPersonal } from "@kimiya/core/store/actions/auth"

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import PersonalDataForm from "@kimiya/forms/registration/persona-data.form";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({    
    root: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    pageTitle: {
        fontFamily: "Brown-Light",
        textTransform: "uppercase",
        fontSize: theme.typography.pxToRem(24),
        color: theme.palette.grey3.main,
        [theme.breakpoints.down("sm")]:{
            marginTop: theme.spacing(6),        
        }
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepContainer: {
        minHeight: 500,
        height: "100%",
        flex: 1
    },
    passwordValidatorContainer: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
    },
    validationList: {
        paddingLeft: 0,
        marginLeft: 0,
    },
    inactiveStatus: {
        color: theme.palette.grey[500],
    },
    invalidStatus: {
        color: theme.palette.error.main,
    },
    validStatus: {
        color: theme.palette.success.main,
    },
    validationText: {
        fontSize: theme.typography.pxToRem(12),
    },
    stepper: {
        marginTop: theme.typography.pxToRem(15),
        padding: 0,
        background: "transparent",
    },
    stepLabel: {
        [theme.breakpoints.down("xs")]: {
            "&.MuiStepLabel-alternativeLabel": {
                marginTop: 5,
            },
            fontSize: theme.typography.pxToRem(10),
        },
    },
}));

const Register = ({ history }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    
    const [form, setForm] = useState({
        name: "",
        surname: "",
        email: "",
        phone: "",
        gender: "",
        password: "",
        confirmPassword: "",
        b_terms: false,
        b_privacy: false,
    });

    const registerPersonalMutation = useMutation(async (subscription) => {        
        const res = await registerPersonal({
            s_firstname : subscription.name,
            s_lastname : subscription.surname,
            s_confirm_email : subscription.email,
            s_confirm_mobile_number : subscription.phone,
            s_password : subscription.password,
            b_gender : subscription.gender,
            b_terms: ""+subscription.b_terms,
            b_privacy: ""+subscription.b_privacy
        })        
        if(!res.id_resource){
            console.log(res)
            if(typeof res !== "string")
                toast.error(t('Ops! Errore durante l\'esecuzione dell\'operazione.'))            
            else
                toast.error(res)            
            return false
        }        

        toast.success(t('Creazione account avvenuta correttamente. Ti abbiamo inviato una mail di verifica all\' indirizzo e-mail fornito.'),{
            autoClose: 20000
        })          
        history.push("/login")      
        return res
    })

    const handleChangeHandler = (name, value) =>
        setForm({ ...form, [name]: value });
    
    return (
        <Container maxWidth="sm" className={classes.root}>
            <Box mt={4}>
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    className={classes.root}
                >
                    <Grid item>
                        <Typography className={classes.pageTitle}>
                            {t("Registrati")}
                        </Typography>
                    </Grid>
                </Grid>                
                <Box className={classes.stepContainer}>
                    <PersonalDataForm
                        form={form}
                        handleChange={handleChangeHandler}
                        handleNext={(subscription) => registerPersonalMutation.mutate(subscription)}
                    />
                </Box>
            </Box>
        </Container>
    );
};

export default Register;
