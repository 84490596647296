import { API } from '@kimiya/core/core/api.class'
import DB from '@kimiya/core/core/db-tables'

export const getFaqs = async () => {
    try{
        const { rows } = await API.query(DB.FAQ)
        return rows
    }catch(error){
        console.log('error')
    }
}