import '@kimiya/core/themes/landing-pages.css';

import Container from '@material-ui/core/Container';
import ContainedButton from "@kimiya/core/components/ui/button/contained-button.component";
import Grid from '@material-ui/core/Grid';
import MissionMain from '@kimiya/core/images/mission-main-img.jpg'
import ArrowsDownIcon from '@kimiya/core/images/ICO-arrows-down.svg'
import missionContentImg from '@kimiya/core/images/mission-content-img.png'

const MissionPage = () => (
    <div>
        <div className="home-full">
            <div className="page-mainImg" style={{ backgroundImage: `url(${MissionMain})`}}>
                <h2>La nostra mission</h2>
                <img className="arrows-down" src={ArrowsDownIcon} alt="scroll-down" />
            </div>
        </div>
        <Container maxWidth="lg" className="inner-mainCont">
            <Grid container justifyContent="center" className="landing-mainCont">
                <Grid item xs={12} sm={10} md={8} className="pages-content">
                    <Grid container justifyContent="space-evenly" className="pages-intro-text">
                        <Grid item xs={3}>
                            <h5 className="page-titleSml mission-txt">Mission</h5>
                        </Grid>
                        <Grid item xs={9}>
                            <p className="page-textMain">
                                Quando si parla di "mangiare bene" si pensa immediatamente alle parole rinuncia, sacrificio e fatica.<br></br><br></br>
                                E' proprio da questa riflessione che è nata la nostra mission: creare un piano alimentare e una linea di prodotti che diano la possibilità di mangiare a sazietà, senza rinunce, in modo semplice e, perché no, raggiungendo il proprio peso forma nella tutela del benessere.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <img src={missionContentImg} alt="scroll-down" width={'100%'}/>
                    </Grid>
                    <Grid container justifyContent="space-evenly" className="pages-intro-text">
                        <Grid item xs={3}>
                            <h5 className="page-titleSml mission-txt">Benessere</h5>
                        </Grid>
                        <Grid item xs={9}>
                            <p className="page-text">
                            Il benessere è al centro del nostro progetto; la società attuale porta a trascurare la salute individuale in funzione dell’ottimizzazione di tempi e della produttività. <br></br><br></br>
                            Noi partiamo dall’idea di recuperare i ritmi naturali della persona, ristabilendo sia l’equilibrio nutrizionale (parametro che valuta le quantità e le proporzioni dei singoli nutrienti e componenti nutrizionali assunti con la dieta), che i ritmi circadiani (ritmo fisiologico di sonno/veglia nelle 24h determinato da alternanza giorno e notte). 
                            Tramite la stabilizzazione della curva, glicemica ottenuta dall’abbassamento di assunzione di zucchero, e il recupero del ritmo naturale, il nostro corpo sarà facilitato a mantenere l’omeostasi e, di conseguenza, la persona si sentirà immediatamente meglio sia dal punto di vista fisico che psicologico.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div className="social">
                    <div className="liner"></div>
                    <h4>Seguici sui social</h4>
                    <div className="social_buttons">
                        <p>Seguici su nostri canali social e rimani sempre aggiornato sulle nostre novità e promozioni</p>
                        <ContainedButton 
                            variant="contained" 
                            className="instaBut landingBut bigBut"
                            href="https://www.instagram.com/kimiya_nutrition/"
                            target="_blank"
                            rel="noopener"
                        >
                            @KIMIYA_OFFICIAL
                        </ContainedButton>
                        <ContainedButton 
                            variant="contained" 
                            className="fbBut landingBut bigBut"
                            href="https://www.facebook.com/profile.php?id=100086437812998"
                            target="_blank"
                            rel="noopener"
                        >
                            /KIMIYA_OFFICIAL
                        </ContainedButton>
                    </div>
                </div>
        </Container>
    </div>
    

)

export default MissionPage