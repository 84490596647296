import { useTranslation } from "react-i18next";
import { DialogConsumer } from "@kimiya/context/dialog.context";
import { useSelector, useDispatch } from "react-redux"
import { getCart, setCartBillingAddress } from "@kimiya/core/store/actions/checkout"
import { CHECKOUT_BILLING_RESOURCE,CHECKOUT_BILLING_ADDRESS } from "@kimiya/core/core/combined-fields-mapper"

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import OutlinedButton from "@kimiya/ui/button/outlined-button.component";

import CheckoutBillingAddressDialog from "@kimiya/core/components/dialogs/checkout-billing-address/checkout-billing-address.dialog";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({    
    sectionTitle: {
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.secondary.main,
        marginBottom: theme.spacing(1),
    },
    sectionTextBold: {
        fontFamily: "Brown-Bold",
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.grey5.main,
        letterSpacing: 0.44,
    },
    sectionText: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.grey5.main,
        letterSpacing: 0.44,
    },
    sectionTextMargin: {
        marginBottom: theme.spacing(1)
    },
    editAddressButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

const CheckoutBillingAddress = ({ sectionTitle }) => {
    
    const { t } = useTranslation();
    const classes = useStyle();
    const dispatch = useDispatch();
    const cart = useSelector(({ checkout }) => checkout.cart || null )
    const user = useSelector(({ auth }) => auth.user || null )

    const onBillingAddressChange = async (idBillingAddress) => {
        if(!idBillingAddress){
            dispatch(getCart());    
            return;
        }
        dispatch(setCartBillingAddress({ idBillingAddress }))
    };

    return (
        <DialogConsumer>
            {({ showDialog }) => (       
                <>
                    <Typography className={classes.sectionTitle}>
                        {sectionTitle}:
                    </Typography>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography className={classes.sectionTextBold}>
                                {cart[CHECKOUT_BILLING_RESOURCE].s_resource}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <OutlinedButton 
                                size="small"
                                className={classes.editAddressButton}
                                onClick={() => showDialog(CheckoutBillingAddressDialog, {
                                        title: user.id_resource_billing 
                                            ? t("Modifica indirizzo di fatturazione")
                                            : t("Seleziona indirizzo di fatturazione"),
                                        isBusiness: user.id_resource_billing ? true : false,
                                        callback: (address) => onBillingAddressChange(address)  
                                    })
                                }
                            >
                                {t("Modifica")}
                            </OutlinedButton>
                        </Grid>
                    </Grid>                                                                                        
                    {cart[CHECKOUT_BILLING_RESOURCE].s_tax_code && (
                        <Typography className={classes.sectionText}>
                            {t('P. IVA')}: {cart[CHECKOUT_BILLING_RESOURCE].s_tax_code}
                        </Typography>
                    )}
                    <Typography className={classes.sectionText}>
                        {t('Nome Indirizzo')}: {cart[CHECKOUT_BILLING_ADDRESS].s_denomination}
                    </Typography>
                    <Typography className={classes.sectionText}>
                        {`${cart[CHECKOUT_BILLING_ADDRESS].s_address} ${cart[CHECKOUT_BILLING_ADDRESS].s_zip_code || ''}`}
                    </Typography>
                    <Typography className={classes.sectionText}>                                        
                        {`${cart[CHECKOUT_BILLING_ADDRESS].s_city} ${cart[CHECKOUT_BILLING_ADDRESS].s_county} ${cart[CHECKOUT_BILLING_ADDRESS].s_country}`}
                    </Typography>
                </>         
            )}
        </DialogConsumer>
    )
}

export default CheckoutBillingAddress