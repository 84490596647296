import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { getOrderInvoices } from "@kimiya/core/store/actions/profile";

import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";

import OutlinedButton from "@kimiya/ui/button/outlined-button.component";

import useStyle from "@kimiya/custom/order/order.style";

const OrderInvoicesTracking = ({ order }) => {
    const { t } = useTranslation();
    const classes = useStyle();
    const [invoices, setInvoices] = useState();
    const [loading, setLoading] = useState();
    const [anchorInvoicesMenu, setAnchorInvoicesMenu] = useState(null);

    const openInvoicesMenu = (event) => {
        setAnchorInvoicesMenu(event.currentTarget);
        fetchInvoices();
    };
    const closeInvoicesMenu = () => {
        setAnchorInvoicesMenu(null);
    };

    const openLink = link => window.open(link,"_blank")

    const fetchInvoices = useCallback(async () => {
        setLoading(true);
        const invoices = await getOrderInvoices({
            id_transaction: "" + order.id_transaction,
            s_uuid: order.s_uuid,
        });        
        setInvoices(invoices || null);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, [order, setLoading]);

    return (
        <Grid
            container
            justifyContent="flex-start"
            alignItems="stretch"
            className={classes.orderRow}
            spacing={4}
        >
            <Grid item>
                <OutlinedButton
                    aria-controls="invoices-menu"
                    aria-haspopup="true"
                    onClick={openInvoicesMenu}
                >
                    {t("Fattura")}
                </OutlinedButton>
                <Menu
                    id="invoices-menu"
                    anchorEl={anchorInvoicesMenu}
                    keepMounted
                    open={Boolean(anchorInvoicesMenu)}
                    onClose={closeInvoicesMenu}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    {loading ? (
                        <MenuItem>
                            <CircularProgress
                                size={20}
                                variant={"indeterminate"}
                            />
                        </MenuItem>
                    ) : (typeof invoices === 'object' && invoices !== null && Object.keys(invoices).length>0) ? (
                        Object.keys(invoices).map((key, index) => (
                            <MenuItem key={index} onClick={() => openLink(invoices[key])}>
                                {key}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem className={classes.noInvoiceText}>
                            {t("Nessun documento disponibile al momento")}
                        </MenuItem>
                    )}
                </Menu>
            </Grid>
            {order.s_shipping_tracking && (
                <Grid item>
                    <OutlinedButton onClick={() => {}}>
                        {t("Traccia Ordine")}
                    </OutlinedButton>
                </Grid>
            )}
        </Grid>
    );
};

export default OrderInvoicesTracking;
