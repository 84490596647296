import { createSlice } from "@reduxjs/toolkit";
import { 
    getCart, 
    updateCartItem, 
    setCartShippingType, 
    resetCartItems, 
    setCartShippingAddress,
    resetShippingAddress,
    setCartBillingAddress
} from "@kimiya/core/store/actions/checkout";
import i18n from "@kimiya/core/i18n/";
import { toast } from "react-toastify";

const checkoutSlice = createSlice({
    name: "checkout",
    initialState: {
        loading: true,
        cart: null,
        settings: null,
        error: null        
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getCart.pending, (state) => {
                state.loading = true;                
                state.error = null;
            })
            .addCase(getCart.fulfilled, (state, action) => {                
                state.loading = false;
                state.cart = action.payload;                
            })
            .addCase(getCart.rejected, (state, action) => {
                state.loading = false;     
                state.cart = null;           
                state.error = action.payload.err;
            })
            .addCase(updateCartItem.pending, (state) => {
                state.loading = true;                
            })
            .addCase(updateCartItem.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(updateCartItem.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.err;
            })
            .addCase(setCartShippingType.pending, (state) => {
                state.loading = true;                
            })
            .addCase(setCartShippingType.fulfilled, (state) => {
                state.loading = false;
                toast.success(i18n.t('Modalità di spedizione salvata con successo!'))
            })
            .addCase(setCartShippingType.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.err;
                toast.error(i18n.t('Errore durante l\'associazione della modalità di spedizione'))
            })
            .addCase(setCartBillingAddress.pending, (state) => {
                state.loading = true;                
            })
            .addCase(setCartBillingAddress.fulfilled, (state,) => {
                state.loading = false;
                toast.success(i18n.t('Indirizzo di fatturazione modificato con successo!'))
            })
            .addCase(setCartBillingAddress.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.err;
                toast.error(i18n.t('Errore durante l\'associazione dell\'indirizzo di fatturazione'))
            })
            .addCase(setCartShippingAddress.pending, (state) => {
                state.loading = true;                
            })
            .addCase(setCartShippingAddress.fulfilled, (state,) => {
                state.loading = false;
                toast.success(i18n.t('Indirizzo di spedizione associato con successo!'))
            })
            .addCase(setCartShippingAddress.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.err;
                toast.error(i18n.t('Errore durante l\'associazione dell\'indirizzo di spedizione'))
            })
            .addCase(resetCartItems.pending, (state) => {
                state.loading = true;                
            })
            .addCase(resetCartItems.fulfilled, (state) => {
                state.loading = false;
                toast.success(i18n.t('Carrello svuotato con successo!'))
            })
            .addCase(resetCartItems.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.err;
                toast.error(i18n.t('Errore durante il reset del carrello!'))
            })
            .addCase(resetShippingAddress.pending, (state) => {
                state.loading = true;                
            })
            .addCase(resetShippingAddress.fulfilled, (state) => {
                state.loading = false;
                toast.success(i18n.t('Operazione avvenuta con successo!'))
            })
            .addCase(resetShippingAddress.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.err;
                toast.error(i18n.t('Errore durante il reset dell\'indirizzo di spedizione!'))
            });
    },
});

export default checkoutSlice.reducer;
