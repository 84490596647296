import { useState } from "react"
import { useHistory } from "react-router-dom"
import { useQuery } from 'react-query'
import { getItemImage } from "@kimiya/core/store/actions/shop";

import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"

import Card from "@kimiya/ui/card/card"
import ProductPriceCart from '@kimiya/custom/product/product-price-cart.component'

import FoodPlaceholder from "@kimiya/core/images/food-placeholder.jpg"

import { makeStyles } from "@material-ui/core/styles"

const useStyle = makeStyles(theme => ({    
    root: {
        borderRadius: 10,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    imageLoaderContainer: { 
        display:"flex", 
        justifyContent: "center", 
        alignItems: "center"
    },
    productImage: {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        width: "100%",
        height: 130,
        backgroundSize: "cover",
        cursor: "pointer",
        backgroundPosition: "center"
    },
    productContent: {
        padding: 15,        
    },
    productTitle: {        
        fontFamily: "Brown-Light",
        fontSize: 16, 
        color: theme.palette.grey5.main,
        letterSpacing: 0.44,
        lineHeight: theme.typography.pxToRem(17),
        marginBottom: 10,
        textTransform: "uppercase",
        cursor: "pointer"
    },
    productDescr: {
        fontSize: 14,
        color: theme.palette.secondary.main,
        letterSpacing: 0.1,
        marginBottom: 15
    },
    productPriceContent: {
        padding: theme.spacing(0,2,2)
    },    
    productPrice: {
        fontFamily: "Brown-Bold",
        fontSize: 16, 
        color: theme.palette.primary.main,
        letterSpacing: 0.15
    },
    productQty: {
        fontFamily: "Brown-Bold",
        fontSize: 16, 
        color: theme.palette.primary.main,                
        padding: 0,
        margin: 0
    }
}))

const ProductCard = ({ category, id, code, title, descr, price }) => {
    
    const classes = useStyle()
    const history = useHistory();
    const [image, setImage] = useState(FoodPlaceholder)
    
    const goToProductDetails = () => history.push({
        pathname: `/product-details/${code}`,
        state: { category }
    });

    

    const refetchItemImage = async () => {
        const image = await getItemImage(id)
        if(image){            
            setImage(`${process.env.REACT_APP_BASE_URL}/files/1/${image.s_file_name}.${image.s_file_ext}`)        
        }
        return image
    }

    const { isLoading, error } = useQuery(["item-image", code], refetchItemImage);

    return (
        <Card size="full" customClasses={classes.root}>            
            <div>
                {(isLoading) && (
                    <div className={classes.imageLoaderContainer}>
                        <CircularProgress />
                    </div>
                )}
                {error && <Typography variant="caption">{"An error has occurred: " + error.message}</Typography>} 
                {(!isLoading) && (                                
                    <div onClick={goToProductDetails} style={{backgroundImage: `url(${image})`}} className={classes.productImage} />                
                )}
                <div className={classes.productContent}>
                    <Typography onClick={goToProductDetails} className={classes.productTitle}>{title}</Typography>
                    <Typography className={classes.productDescr}>{descr}</Typography>
                </div>
            </div>
            <div className={classes.productPriceContent}>
                <ProductPriceCart itemId={id} price={price} />
            </div>
        </Card>
    )
}

export default ProductCard