import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import RequestResetPassword from "@kimiya/core/components/custom/reset-password/request-reset-password";
import ResetPassword from "@kimiya/core/components/custom/reset-password/reset-password";

import useStyles from "@kimiya/custom/reset-password/reset-password.styles"

const ResetPasswordPage = () => {
    const classes = useStyles();
    const { t } = useTranslation();    
    const { token } = useParams();    
    
    return (
        <Container component="main" maxWidth="sm" className={classes.root}>
            <div className={classes.paper}>
                <Typography className={classes.welcomeText}>
                    {t("Reset your password")}
                </Typography>
                <Typography className={classes.resetPasswordText}>
                    {t("se hai dimenticato la tua password inserisci la tua email e ti manderemo le istruzioni per chiederne una nuova:")}
                </Typography>
                <div className={classes.form}>
                {(!token) ? (
                    <RequestResetPassword />
                ) : (
                    <ResetPassword />
                )}                    
                </div>
            </div>
        </Container>
    );
};

export default withRouter(ResetPasswordPage);
