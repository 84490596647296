import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";

import ProfileSettingsForm from "@kimiya/forms/profile/settings.form";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({    
    sectionTitle: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(48),
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.pxToRem(28),
        }
    },
}));

const ProfileSettings = () => {
    const classes = useStyle();
    const { t } = useTranslation();
    return (
        <>
            <Typography className={classes.sectionTitle}>
                {t("Impostazioni")}
            </Typography>
            <ProfileSettingsForm />
        </>
    );
};

export default ProfileSettings;
