import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { DialogConsumer } from "@kimiya/context/dialog.context";
import { useHistory } from "react-router-dom";
import { getCart } from "@kimiya/core/store/actions/checkout";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";

import ContainedButton from "@kimiya/ui/button/contained-button.component";
import OutlinedButton from "@kimiya/ui/button/outlined-button.component";
import CartCard from "@kimiya/ui/card/cart-card";
import LoginDialog from "@kimiya/dialogs/login/login.dialog";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    minHeight: "80%",
  },
  titleContainer: {
    textAlign: "center",
  },
  pageTitle: {
    fontFamily: "Brown-Light",
    fontSize: theme.typography.pxToRem(48),
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
  },
  cartRecapContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  cartRecapText: {
    fontSize: 24,
    color: theme.palette.primary.main,
    letterSpacing: 0.23,
  },
  cartRecapDivider: {
    marginBottom: theme.spacing(4),
  },
  emptyCartContainer: {
    marginTop: theme.spacing(4),
  },
  cartEmptyText: {
    fontSize: 18,
    color: theme.palette.primary.main,
    letterSpacing: 0.23,
  },
}));

const CartPage = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const cart = useSelector(({ checkout }) => checkout.cart || null);
  const cartLoading = useSelector(({ checkout }) => checkout.loading || null);

  const goToCheckout = () => history.push("/checkout");
  const goToShop = () => history.push("/shop");
  const goToLogin = () => history.push("/login");

  useEffect(() => {
    if (!cart) dispatch(getCart());
  }, [dispatch, cart]);

  return (
    <Container maxWidth="md" className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography className={classes.pageTitle}>{t("Carrello")}</Typography>
        <DialogConsumer>
          {({ showDialog }) => (
            <ContainedButton
              disabled={!cart?.items || cart.items.length === 0}
              onClick={() => {
                user
                  ? goToCheckout()
                  : showDialog(LoginDialog, {
                      title: "Effettua il login",
                    });
              }}
              variant="contained"
              color="secondary"
              fullWidth
            >
              {t("Procedi con l'ordine")}
            </ContainedButton>
          )}
        </DialogConsumer>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.cartRecapContainer}
        >
          <Grid item>
            <Typography className={classes.cartRecapText}>
              {t("Totale Provvisorio")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.cartRecapText}>
              {cart ? `${cart.m_transaction_gross.toFixed(2)} €` : `0.00 €`}
            </Typography>
          </Grid>
        </Grid>
        <Divider className={classes.cartRecapDivider} />
      </div>
      <Grid container direction="column" spacing={2}>
        {!cart ? 
          cartLoading 
          ? ( <CircularProgress />) 
          : (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={4}
              className={classes.emptyCartContainer}
            >
              <Grid item>
                <Typography className={classes.cartEmptyText}>
                  {t("Per poter aggiungere un prodotto a carrello è richiesto il login.")}
                </Typography>
              </Grid>
              <Grid item>
                <OutlinedButton onClick={goToLogin}>
                  {t("Effettua Login")}
                </OutlinedButton>
              </Grid>
            </Grid>            
          )
         : cart.items ? (
          cart.items.map((item) => (
            <Grid item key={item.id_item}>
              <CartCard
                id={item.id_item}
                title={item.s_item}
                code={item.s_item_code}
                price={item.m_price_unit_final_gross}
              />
            </Grid>
          ))
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={4}
            className={classes.emptyCartContainer}
          >
            <Grid item>
              <Typography className={classes.cartEmptyText}>
                {t("Il carrello è vuoto!")}
              </Typography>
            </Grid>
            <Grid item>
              <OutlinedButton onClick={goToShop}>
                {t("Torna allo Shop")}
              </OutlinedButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default CartPage;
