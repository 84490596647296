import { API } from '@kimiya/core/core/api.class'
import DB from '@kimiya/core/core/db-tables'

export const getShopCategories = async () => {
    try{
        const { rows } = await API.query(DB.SHOP_CATEGORIES,`id_category~1|b_publish~1`)
        return rows
    }catch(error){
        console.log('error')
    }
}

export const getShopCategory = async (category_code) => {
    try{
        const where = category_code ? `s_code~${category_code}` : ""
        const { rows } = await API.query(DB.SHOP_CATEGORIES, where)
        return rows[0]
    }catch(error){
        console.log('error')
    }
}

export const getItems = async (category_code) => {
    try{
        let id_category;
        if(category_code){
            const res_category = await API.query(DB.SHOP_CATEGORIES, `s_code~${category_code}`)
            id_category = res_category?.rows[0]?.id_subcategory;
            if(!id_category){
                return [];
            }
        }
        const where = id_category
            ? `id_category~1|id_subcategory~${id_category}|b_confirmed~1|b_outdated~0|b_price_valid~1|b_sellable~1` 
            : "id_category~1|b_confirmed~1|b_outdated~0|b_price_valid~1|b_sellable~1";        
        const includes = id_category
            ? ""
            : `id_subcategory~${DB.SHOP_ITEMS}~id_subcategory~${DB.SHOP_CATEGORIES}~1`
        const { rows } = await API.query(DB.SHOP_ITEMS, where,"","n_priority~asc",includes)

        return rows
    }catch(error){
        console.log('error')
    }
}

export const getItem = async (item_code) => {
    try{
        const { rows } = await API.query(DB.SHOP_ITEMS, `s_item_code~${item_code}`)
        return rows[0]
    }catch(error){
        console.log('error')
    }
}

export const getItemImage = async (id_item) => {
    try{
        const where = [
            "s_entity_key_column~id_item",
            "s_entity_table~mb_item",
            "id_file_entity_type~14",
            "id_document_type~3",
            `idl_file_entity~${id_item}`
        ]
        const { rows } = await API.query(DB.FILES, where.join("|"))
        return rows[0] || null 
    }catch(error){
        console.log('error')
    }
}

export const getItemThumb = async (id_item) => {
    try{
        const where = [
            "s_entity_key_column~id_item",
            "s_entity_table~mb_item",
            "id_file_entity_type~14",
            "id_document_type~36",
            `idl_file_entity~${id_item}`
        ]
        const { rows } = await API.query(DB.FILES, where.join("|"))
        return rows[0] || null
    }catch(error){
        console.log('error', error)
        return false
    }
}

export const getItemAllergens = async (id_item) => {
    try{
        const includes = [`id_allergen~${DB.SHOP_ITEM_ALLERGENS}~id_allergen~${DB.ALLERGENS}~1`]; 
        const { rows } = await API.query(DB.SHOP_ITEM_ALLERGENS, `id_item~${id_item}`,"","",includes.join("|"))
        return rows
    }catch(error){
        console.log('error', error)
        return false
    }
}

export const getItemIngredients = async (id_item) => {
    try{
        const includes = [
            `id_item~${DB.SHOP_ITEM_INGREDIENTS}~id_item~${DB.SHOP_ITEMS}~1`,
            `id_item~${DB.SHOP_ITEMS}~id_item~${DB.SHOP_ITEM_ALLERGENS}~1`,
            `id_allergen~${DB.SHOP_ITEM_ALLERGENS}~id_allergen~${DB.ALLERGENS}~1`
        ]; 
        const { rows } = await API.query(DB.SHOP_ITEM_INGREDIENTS, `id_item_container~${id_item}`,"","",includes.join("|"))
        return rows
    }catch(error){
        console.log('error', error)
        return false
    }
}

export const getItemNutritious = async (id_item) => {
    try{
        const includes = [`id_nutritious_type~${DB.SHOP_ITEM_NUTRITIOUS}~id_nutritious_type~${DB.NUTRITIOUS}~1`]; 
        const { rows } = await API.query(DB.SHOP_ITEM_NUTRITIOUS, `id_item~${id_item}`,"","",includes.join("|"))
        return rows
    }catch(error){
        console.log('error', error)
        return false
    }
}