import { useState, useRef } from "react"
import { useQuery } from "react-query"
import { uploadProfilePicture, getProfilePicture } from "@kimiya/core/store/actions/profile"

import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({    
    root: {
        paddingTop: theme.typography.pxToRem(50),
        paddingBottom: theme.typography.pxToRem(50)
    },
    avatar: {
        width: 100,
        height: 100,
        margin: "0 auto",
        borderRadius: "50%",
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.grey1.main,
        backgroundPosition: "center",
        objectFit: "cover",
        fontFamily: "Brown-Bold",
        color: theme.palette.primary.main
    },
}));

const AccountProfilePicture = () => {

    const classes = useStyles();
    const [profilePicture, setProfilePicture] = useState()

    const refetchProfilePicture = async () => {        
        const profilePicture = await getProfilePicture()
        setProfilePicture(profilePicture ? `${profilePicture.s_file_name}.${profilePicture.s_file_ext}` : null)
        return profilePicture
    }

    const { isLoadingPicture } = useQuery(["profile-image"], refetchProfilePicture)

    const inputFileRef = useRef();
    const onTriggerProfilePicture = () => {        
        inputFileRef.current.click();
    };
    const onFileChange = (e) => {        
        if(e.target.files[0]){
            uploadProfilePicture(e.target.files[0]);
        }
    };

    return (
        <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            className={classes.root}
        >
            <Grid item xs={12} style={{ textAlign: 'center'}}>    
                {isLoadingPicture ? (
                    <CircularProgress />
                ) : (
                    profilePicture ? (
                        <img src={`${process.env.REACT_APP_BASE_URL}/files/1/${profilePicture}`} alt="profile" className={classes.avatar} onClick={onTriggerProfilePicture} />                    
                    ) : (
                        <Avatar className={classes.avatar} onClick={onTriggerProfilePicture}>KM</Avatar>  
                    )
                )}
                <input type="file" ref={inputFileRef} onChange={onFileChange} style={{ display: "none" }} />
            </Grid>
        </Grid>
    )
}

export default AccountProfilePicture