import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import InputBase from "@material-ui/core/InputBase";
import { withStyles, makeStyles } from "@material-ui/core/styles";

export const StyledInput = withStyles((theme) => ({
    root: {
        width: "100%",
        "label + &": {
            marginTop: theme.spacing(3),
            color: theme.palette.grey5.main,
        },
        "&.Mui-error > input": {
            border: `1px solid ${theme.palette.error.main}`,
            color: theme.palette.error.main            
        },
        "&.Mui-error > .MuiSelect-root": {
            border: `1px solid ${theme.palette.error.main}`,
            color: theme.palette.error.main            
        }
    },
    input: {
        borderRadius: 25,
        position: "relative",
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.secondary.main}`,
        fontFamily: "Brown-Light",
        fontSize: 16,
        lineHeight: "17px",
        width: "100%",
        padding: "10px 20px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        '&:focus': {
            borderRadius: 25,
            position: "relative",
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.secondary.main}`,
            fontSize: 16,
            lineHeight: "17px",
            width: "100%",
            padding: "10px 20px",
            transition: theme.transitions.create(["border-color", "box-shadow"]),
        },   
        '&::placeholder': {
            lineHeight: "17px"
        }     
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    formControl: {        
        width: "100%",
    },
    inputLabel: {
        fontSize: theme.typography.pxToRem(18),
        color: theme.palette.secondary.main,
        "&.Mui-focused": {
            color: theme.palette.secondary.main,
        }
    },
}));

const Input = (props) => {
    const classes = useStyles();    
    const {helperText, error, label, required, ...rest} = props;
    return (
        <FormControl className={classes.formControl} error={error}>
            {label && (
                <InputLabel
                    className={classes.inputLabel}
                    shrink
                >
                    {label}{required && ' *'}
                </InputLabel>
            )}
            <StyledInput {...rest} />
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
};

export default Input;
