import { createSlice } from "@reduxjs/toolkit";
import { axios } from "@kimiya/core/utils/axios";
import { isLoggedIn, getBillingData } from "@kimiya/core/store/actions/auth"

const PROJECT_PREFIX = process.env.REACT_APP_PRJ_PREFIX;

function logoutHandler() {
    localStorage.removeItem(`${PROJECT_PREFIX}TOKEN`);
    localStorage.removeItem(`${PROJECT_PREFIX}USR`);
    axios.defaults.headers.common["Authorization"] = "";
}

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: null,
        billingData: null,
        checking: true             
    },
    reducers: {
        login: (state, action) => {
            try {                 
                state.user = action.payload;                
            } catch (error) {                
                console.dir(error);                
            }
        },
        refreshUser: (state) => {
            let user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));
            state.user = user; 
        },
        logout: (state) => {
            state.user = null;
            logoutHandler();
        },
        completeUserRegistration: (state) => {
            try {                 
                let user = JSON.parse(localStorage.getItem(`${PROJECT_PREFIX}USR`));                
                user.b_registration_completed = true;
                localStorage.setItem(`${PROJECT_PREFIX}USR`, JSON.stringify(user));
                state.user = user; 
            } catch (error) {                
                console.dir(error);                
            }
        },        
        resetPassword: (state,action) => {

        }
    },
    extraReducers(builder) {
        builder
            .addCase(isLoggedIn.pending, (state) => {
                state.checking = true;                
            })
            .addCase(isLoggedIn.fulfilled, (state, action) => {
                state.checking = false;
                state.token = action.payload.token;
                state.user = action.payload.user;
            })
            .addCase(isLoggedIn.rejected, (state) => {
                state.checking = false;
                state.token = null;
                state.user = null;
            })
            .addCase(getBillingData.pending, (state) => {
                state.checking = true;   
                state.billingData = null;             
            })
            .addCase(getBillingData.fulfilled, (state, action) => {
                state.checking = false;
                state.billingData = action.payload;                
            })
            .addCase(getBillingData.rejected, (state) => {
                state.checking = false;
                state.billingData = null;
            });
    }
});

// Action creators are generated for each case reducer function
export const { login, logout, resetPassword, getUser, completeUserRegistration, refreshUser } = authSlice.actions;

export default authSlice.reducer;
