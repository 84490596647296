import { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
// import { CHATS_STATUSES } from "@kimiya/core/core/defaults-values"
import { getChats } from "@kimiya/core/store/actions/coaching"
import { isArrayEqual } from "@kimiya/core/utils"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListSubheader from "@material-ui/core/ListSubheader"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import Avatar from "@material-ui/core/Avatar"
import Divider from "@material-ui/core/Divider"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import Skeleton from "@material-ui/lab/Skeleton"

import ChatSidebarItem from "@kimiya/custom/coach/chat-sidebar-item/chat-sidebar-item"

import UserIcon from "@material-ui/icons/Person"
import SearchIcon from "@material-ui/icons/Search"

import useChatStyles from "@kimiya/custom/coach/chat.styles"

const ChatSidebar = ({ onSelect }) => {
    const classes = useChatStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const user = useSelector(({auth}) => auth.user)    
    const chats = useSelector(({ coaching }) => coaching.chats)
    const [coachChats, setCoachChats] = useState()

    const refetchChats = useCallback(() => {
        dispatch(getChats())
    },[dispatch])

    useEffect(() => {
        if(!coachChats || !isArrayEqual(chats,coachChats))
            setCoachChats(chats)
    }, [chats,coachChats])

    useEffect(() => {
        
        refetchChats()

        const interval = setInterval(() => {
            refetchChats()
        },10000)

        return () => clearInterval(interval)
        
    },[refetchChats])

    const renderCoachChats = useMemo(() => {

        if(!coachChats) 
            return (
                <ListItem className={classes.sidebarEmptyContainer}>
                    <Skeleton animation="wave" className={classes.sidebarEmptySkeleton} />
                    <Skeleton animation="wave" className={classes.sidebarEmptySkeleton} />
                    <Skeleton animation="wave" className={classes.sidebarEmptySkeleton} />
                    <Skeleton animation="wave" className={classes.sidebarEmptySkeleton} />
                </ListItem>
            )

        if(coachChats && coachChats.length===0) return (
            <ListItem>
                <ListItemText primary={t("Nessuna chat aperta al momento")} />
            </ListItem>
        )

        return coachChats.map((chat,index) => (
            <ChatSidebarItem 
                key={index}
                chat={chat}
                chatId={index+1}
                onSelect={onSelect}
                chatName={chat.s_support_request}
                chatDate={chat.s_support_subject.replace("... per ","")}
            />
        ))

    },[coachChats, onSelect, classes, t])

    return (
        <>
            <List className={classes.sibebarList}>
                <ListSubheader disableGutters>
                    <ListItem component="div" className={classes.coachItem}>
                        <ListItemAvatar>
                            <Avatar className={classes.chatAvatar}>
                                <UserIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText 
                            primary={`${t('Ciao')}, ${user.s_resource.toUpperCase()}`}                             
                            primaryTypographyProps={{ className: classes.coachText, noWrap: true }}
                        />
                    </ListItem>
                    <ListItem component="div" className={classes.chatSearchItem}>
                        <TextField            
                            fullWidth
                            placeholder={t("cerca chat")}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />                            
                    </ListItem>
                    <Divider />
                </ListSubheader>                    
                {renderCoachChats}
            </List>
        </>
    )
}

export default ChatSidebar