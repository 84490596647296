import { useTranslation } from "react-i18next";
import { DialogConsumer } from "@kimiya/context/dialog.context"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import Card from "@kimiya/ui/card/card"
import OutlinedButton from "@kimiya/ui/button/outlined-button.component"
import ConfirmActionDialog from "../../dialogs/confirm-action/confirm-action.dialog";

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(3,0)
    },
    defaultLabel: {
        color: theme.palette.secondary.main,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(16)
    },
    defaultValue: {
        fontFamily: "Brown-Bold",
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(16)
    },
    card: {
        borderRadius: 10
    },
    addressText: {
        fontFamily: "Brown-Light",
        color: theme.palette.grey5.main,
        fontSize: theme.typography.pxToRem(16),
        letterSpacing: 0.44
    },
    deleteButton: {
        borderColor: theme.palette.error.main,        
    },
    deleteButtonLabel: {
        color: theme.palette.error.main
    }
}))

const AddressCard = ({ address, openAddress, deleteAddress, canDelete = true, hideFlags = false }) => {
    const classes = useStyles()
    const { t } = useTranslation();

    return (
        <Grid container direction="column" spacing={1} className={classes.root}>
            {(!hideFlags && (address.b_shipping || address.b_billing)) && (
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
                        <Grid item>
                            <Typography className={classes.defaultLabel}>{t("predefinito per")}: </Typography>
                        </Grid>
                        <Grid item>
                            {(address.b_billing===true && address.b_shipping===false) && <Typography className={classes.defaultValue}>{t("fatturazione")}</Typography>}
                            {(address.b_billing===false && address.b_shipping===true) && <Typography className={classes.defaultValue}>{t("spedizione")}</Typography>}
                            {(address.b_billing===true && address.b_shipping===true) && <Typography className={classes.defaultValue}>{t("fatturazione e spedizione")}</Typography>}
                        </Grid>
                    </Grid>                
                </Grid>
            )}
            <Grid item>
                <Card size="medium" customClasses={classes.card}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs={12}>
                            <Typography className={classes.addressText}>{address.s_denomination}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.addressText}>{`${address.s_address} ${address.s_zip_code || ''}`}</Typography>
                            <Typography className={classes.addressText}>{`${address.s_city} ${address.s_county} ${address.s_country}`}</Typography> 
                            {address.s_note && <Typography className={classes.addressText}>{t("numero di telefono")}: {address.s_note}</Typography>}
                        </Grid>
                        <Grid item xs={12} align="right">
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    {canDelete && (
                                        <DialogConsumer>
                                            {({ showDialog }) => (
                                                <OutlinedButton 
                                                    variant="outlined" 
                                                    size="small"
                                                    className={classes.deleteButton} 
                                                    classes={{ label: classes.deleteButtonLabel }}
                                                    onClick={() => showDialog(ConfirmActionDialog,{
                                                        title: "Conferma Azione",
                                                        callback: () => deleteAddress(address.id_address)
                                                    })}
                                                >
                                                    {t("Elimina")}
                                                </OutlinedButton>
                                            )}
                                        </DialogConsumer>
                                    )}
                                </Grid>
                                <Grid item>
                                    <OutlinedButton 
                                        size="small"
                                        variant="outlined" 
                                        onClick={() => openAddress(address.id_address)}
                                    >
                                        {t("Modifica")}
                                    </OutlinedButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    )
}

export default AddressCard