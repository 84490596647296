import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import { registrationPersonalValidationSchema } from "@kimiya/core/utils/validation_schemas"

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link"

import ContainedButton from "@kimiya/ui/button/contained-button.component";
import Input from "@kimiya/ui/input/input.component";
import StyledSelect from "@kimiya/ui/select/select.component";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    formControl: {
        width: "100%",
    },
    checkboxLabel: {
        fontFamily: 'Brown-Light',
        fontSize: theme.typography.pxToRem(14)
    }
}));

const RegistrationPersonalDataForm = ({ form, handleNext }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const openTerms = (e) => {
        e.preventDefault();
        window.open("https://erp.kimiya.club/api/?id_transaction_type=0&id_agreement=8&request_format=url&action_type=custom&action_custom_method=generatePDF&action_custom_class=work.bitframe.api.SystemAction", "_blank")
    }

    const openPrivacy = (e) => {
        e.preventDefault();
        window.open("https://erp.kimiya.club/api/?id_transaction_type=0&id_agreement=7&request_format=url&action_type=custom&action_custom_method=generatePDF&action_custom_class=work.bitframe.api.SystemAction", "_blank")
    }

    const validationSchema = yup.object(registrationPersonalValidationSchema);

    const formik = useFormik({
        initialValues: form,     
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // alert(JSON.stringify(values, null, 2));
            handleNext(values);
        },
    });

    return (
        <Box py={3}>
            <form className={classes.root} noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Input
                            autoComplete="off"
                            required
                            id="name"
                            name="name"
                            label={t("Nome")}
                            variant="outlined"
                            fullWidth
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            autoComplete="off"
                            required
                            id="surname"
                            name="surname"
                            label={t("Cognome")}
                            variant="outlined"
                            fullWidth                            
                            value={formik.values.surname}
                            onChange={formik.handleChange}
                            error={formik.touched.surname && Boolean(formik.errors.surname)}
                            helperText={formik.touched.surname && formik.errors.surname}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            autoComplete="off"
                            required
                            type="email"
                            id="email"
                            name="email"
                            label={t("Email")}
                            variant="outlined"
                            fullWidth
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            autoComplete="off"
                            type="tel"
                            id="phone"
                            name="phone"
                            label={t("Cellulare (solo numeri ITA)")}
                            variant="outlined"
                            fullWidth
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                        />
                    </Grid>
                    <Grid item xs={12}>                        
                        <StyledSelect
                            autoComplete="off"                            
                            required
                            id="gender"
                            name="gender"
                            label={t("Sesso")}
                            variant="outlined"
                            fullWidth
                            value={formik.values.gender}
                            onChange={formik.handleChange}
                            error={formik.touched.gender && Boolean(formik.errors.gender)}
                            helperText={formik.touched.gender && formik.errors.gender}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value={"0"}>{t('Femmina')}</MenuItem>
                            <MenuItem value={"1"}>{t('Maschio')}</MenuItem>
                        </StyledSelect>
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            autoComplete="off"
                            required
                            type="password"
                            id="password"
                            name="password"
                            label={t("Password")}
                            variant="outlined"
                            fullWidth
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            autoComplete="off"
                            required
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            label={t("Ripeti password")}
                            variant="outlined"
                            fullWidth
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            className={classes.formControl}
                            error={!formik.values.b_terms}
                        >
                            <FormControlLabel
                                classes={{label: classes.checkboxLabel}}
                                control={
                                    <Checkbox
                                        checked={formik.values.b_terms}
                                        onChange={formik.handleChange}
                                        id="b_terms"
                                        name="b_terms"
                                    />
                                }
                                label={(
                                    <Link onClick={openTerms}>{t("Condizioni Generali d'utilizzo")}</Link>
                                )}
                            />
                            {formik.touched.b_terms &&
                                Boolean(formik.errors.b_terms) && (
                                    <FormHelperText className={classes.checkboxLabel}>
                                        {t(
                                            "Accettare le Condizioni Generali è obbligatorio"
                                        )}
                                    </FormHelperText>
                                )}
                        </FormControl>
                        <FormControl
                            className={classes.formControl}
                            error={!formik.values.b_privacy}
                        >
                            <FormControlLabel
                                classes={{label: classes.checkboxLabel}}
                                control={
                                    <Checkbox
                                        checked={formik.values.b_privacy}
                                        onChange={formik.handleChange}
                                        id="b_privacy"
                                        name="b_privacy"
                                    />
                                }
                                label={(
                                    <Link onClick={openPrivacy}>{t("Informativa sulla Privacy")}</Link>
                                )}
                            />
                            {formik.touched.b_privacy &&
                                Boolean(formik.errors.b_privacy) && (
                                    <FormHelperText className={classes.checkboxLabel}>
                                        {t(
                                            "Accettare la Privacy è obbligatorio"
                                        )}
                                    </FormHelperText>
                                )}
                        </FormControl>
                    </Grid>
                </Grid>
                <Box my={4}>
                    <ContainedButton
                        type="submit"
                        variant="contained"
                        color="secondary"
                        fullWidth
                    >
                        {t("Avanti")}
                    </ContainedButton>
                </Box>
            </form>
        </Box>
    );
};

export default RegistrationPersonalDataForm;
