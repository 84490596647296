import { createSlice } from "@reduxjs/toolkit";
import { getChats, getChat } from "@kimiya/core/store/actions/coaching";

const coachingSlice = createSlice({
    name: "coaching",
    initialState: {
        loading: true,
        chat: null,
        chats: null
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getChats.pending, (state) => {
                state.loading = true;                
            })
            .addCase(getChats.fulfilled, (state, action) => {
                // const open = action.payload
                //     ? action.payload.filter((chat) => !chat.b_completed)
                //     : null;
                // const archived = action.payload
                //     ? action.payload.filter((chat) => chat.b_completed)
                //     : null;
                // state.chats = open || archived ? { open, archived } : null;
                state.chats = action.payload;
                state.loading = false;
            })
            .addCase(getChats.rejected, (state) => {
                state.loading = false;
                state.chat = null;
                state.chats = null;
            })
            .addCase(getChat.pending, (state) => {
                state.loading = true;
                state.chat = null;
            })
            .addCase(getChat.fulfilled, (state, action) => {
                state.loading = false;
                state.chat = action.payload;
            })
            .addCase(getChat.rejected, (state) => {
                state.loading = false;
                state.chat = null;
            });
    },
});

export default coachingSlice.reducer;
