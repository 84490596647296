import { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux"
import { logout, completeUserRegistration } from "@kimiya/core/store/actions/auth"
import { completeUserRegistration as completeUserRegistrationReducer} from "@kimiya/core/store/reducers/auth.reducer";


import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import ContainedButton from "@kimiya/ui/button/contained-button.component";

import KimiyaLogoBlue from "@kimiya/core/images/kimiya-logo-blue.svg"
import AppStore from "@kimiya/core/images/app-store.svg"
import GooglePlay from "@kimiya/core/images/google-play.png"

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",        
        minHeight: 500,
        marginTop: theme.spacing(3)
    },    
    registrationCompletedTitle: {
        fontFamily: "Brown-Light",
        fontSize: 20,
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.main,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    registrationCompletedText:{
        fontFamily: "Brown-Light",
        fontSize: 16,
        marginTop: 30,
        color: theme.palette.grey5.main,
        letterSpacing: 0.44,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    downloadAppTitle: {
        fontFamily: "Brown-Light",
        fontSize: 20,
        color: theme.palette.primary.main,
        marginTop: theme.spacing(2)
    },
    backButton: {
        marginTop: theme.spacing(2)
    }
}));

const RegistrationCompleted = ({ subscription }) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();    

    const goToLogin = async () =>{
        await logout();
        window.location.href = '/login';
    }

    const completeRegistrationHandler = useCallback(async () => {
        await completeUserRegistration();
        dispatch(completeUserRegistrationReducer())        
        return true;
    },[dispatch])

    useEffect(() => {
        completeRegistrationHandler()  
    },[completeRegistrationHandler])


    return (        
        <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.root}>
            <Grid item xs={12} align="center">
                <img src={KimiyaLogoBlue} alt="kimiya logo blue" />                    
            </Grid>
            <Grid item xs={12} align="center">
                <Typography className={classes.registrationCompletedTitle}>{t("Registrazione avvenuta con successo!")}</Typography>
            </Grid>
            <Grid item xs={12} align="center">
                <Typography className={classes.registrationCompletedText}>{t("controlla la tua email e il tuo cellulare per completare l'attivazione del tuo Account")}</Typography>
            </Grid>
            {subscription && (
                <>
                    <Grid item xs={12} align="center">
                        <Typography className={classes.downloadAppTitle}>{t("Scarica la app")}:</Typography>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Grid container justifyContent="space-around" alignItems="center">
                            <Grid item>
                                <img src={GooglePlay} alt="app store" height="70" />
                            </Grid>
                            <Grid item>
                                <img src={AppStore} alt="app store" height="50" />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                <ContainedButton
                    className={classes.backButton}
                    onClick={goToLogin}
                    variant="contained"
                    color="secondary"
                    fullWidth
                >
                    {t("Torna al Login")}
                </ContainedButton>
            </Grid>
        </Grid>        
    )
}

export default RegistrationCompleted