import * as React from "react";
import { useEffect, useMemo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { isLoggedIn } from "@kimiya/core/store/actions/auth";
import ROUTES from "@kimiya/core/routes/routes";

import Layouts from "@kimiya/layouts";
import Backdrop from "@kimiya/ui/backdrop/backdrop";

const Routing = React.memo(() => {
  const { t } = useTranslation();
  const user = useSelector(({ auth }) => auth.user);
  const isChecking = useSelector(({ auth }) => auth.checking);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(isLoggedIn());
  }, [dispatch]);

  const filteredRoutes = ROUTES.filter((r) =>
    !user || (user && user.b_registration_completed)
      ? r.visibility.indexOf(
          user && !user.isCoach
            ? "USER"
            : user && user.isCoach
            ? "COACH"
            : "public"
        ) !== -1
      : r.key === "complete-registration"
  );

  const renderRedirectionDefaults = useMemo(() => {
    if (user && user.isCoach) {
      return (
        <>
          <Route exact path="/">
            <Redirect to="/coach/dashboard" />
          </Route>
          <Redirect to="/coach/dashboard" />
        </>
      );
    }

    if (!user) {
      return (
        <>
          <Route exact path="/home">
            <Redirect to="/home" />
          </Route>
          <Redirect to="/home" />
        </>
      );
    }

    if (user && !user.isCoach && user.b_registration_completed) {
      return (
        <>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Redirect to="/home" />
        </>
      );
    }
    return (
      <>
        <Route exact path="/">
          <Redirect to="/complete-registration/billing" />
        </Route>
        <Redirect to="/complete-registration/billing" />
      </>
    );
  }, [user]);

  if (isChecking)
    return <Backdrop open customText={t("Initializing App...")} />;

  return (
    <Router basename={"/app"}>
      <Switch>
        {filteredRoutes.map((r) => {
          let DynamicComponent = r.component;
          return (
            <Route
              key={r.key}
              path={r.routePath}
              exact
              render={(props) => (
                <Layouts layout={r.layout} sidebar={r.sidebar}>
                  <DynamicComponent
                    layout={r.layout}
                    user_type={r.user_type ? r.user_type : null}
                    {...props}
                  />
                </Layouts>
              )}
            />
          );
        })}
        {renderRedirectionDefaults}
      </Switch>
    </Router>
  );
});

export default Routing;
