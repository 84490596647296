import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import BillingDataStep from "@kimiya/custom/registration/billing-data"
import SubscriptionSelection from "@kimiya/custom/registration/subscription-step";
import RegistrationRecap from "@kimiya/custom/registration/recap";
import RegistrationCompleted from "@kimiya/core/components/custom/registration/registration-completed";

import KimiyaLogoBlue from "@kimiya/core/images/kimiya-logo-blue.svg"

import { makeStyles } from "@material-ui/core/styles";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const useStyles = makeStyles((theme) => ({    
    root: {
        width: "100%",
        minHeight: "100%",
        display: "flex",
        marginTop: 20,
        alignItems: "flex-start"
    },
    pageTitle: {
        fontFamily: "Brown-Light",
        textTransform: "uppercase",
        fontSize: theme.typography.pxToRem(24),
        color: theme.palette.grey3.main,
        marginBottom: theme.spacing(1),
        textAlign: "center"
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepContainer: {
        minHeight: 500,
        height: "100%",
        flex: 1
    },
    passwordValidatorContainer: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
    },
    validationList: {
        paddingLeft: 0,
        marginLeft: 0,
    },
    inactiveStatus: {
        color: theme.palette.grey[500],
    },
    invalidStatus: {
        color: theme.palette.error.main,
    },
    validStatus: {
        color: theme.palette.success.main,
    },
    validationText: {
        fontSize: theme.typography.pxToRem(12),
    },
    stepper: {
        marginTop: theme.typography.pxToRem(15),
        padding: 0,
        background: "transparent",
    },
    stepLabel: {
        [theme.breakpoints.down("xs")]: {
            "&.MuiStepLabel-alternativeLabel": {
                marginTop: 5,
            },
            fontSize: theme.typography.pxToRem(10),
        },
    },
}));

const stepperMapper = {
    "billing": 0,
    "subscription": 1,
    "recap": 2
}
const stepperIndexes = {
    0: "billing",
    1: "subscription",
    2: "recap"
}

const Register = ({ history }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { step } = useParams()

    const steps = [
        t("Dati Anagrafici"),
        t("Abbonamento"),
        t("Riepilogo"),
    ];

    const [subscription, setSubscription] = useState();
    const [activeStep, setActiveStep] = useState(stepperMapper[step]);    

    const handleNext = (subscription) => {
        if(subscription) setSubscription(subscription);
        window.scrollTo(0,0)
        setActiveStep((prevActiveStep) => {
            console.log()
            history.push(`/complete-registration/${stepperIndexes[prevActiveStep+1]}`)
            return prevActiveStep + 1
        });
    };

    const handleBack = () => {        
        setActiveStep((prevActiveStep) => {
            history.push(`/complete-registration/${stepperIndexes[prevActiveStep-1]}`)
            return prevActiveStep - 1
        });
    };    

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            
            case 0:
                return (
                    <BillingDataStep
                        handleNext={handleNext}                            
                    />
                );
            case 1:
                return (
                    <SubscriptionSelection
                        handleNext={handleNext}     
                        handleBack={handleBack}                       
                    />
                );
            case 2:
                return (
                    <RegistrationRecap                        
                        subscription={subscription}
                        handleNext={handleNext}
                        handleBack={handleBack}
                    />
                );
            default:
                return "Unknown stepIndex";
        }
    }


    return (
        <Elements stripe={stripePromise}>
            <Container maxWidth="sm" className={classes.root}>
                <Box mt={4} style={{ width: "100%" }}>
                    {activeStep !== steps.length && (
                        <>                        
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                className={classes.root}
                                spacing={2}
                            >                        
                                <Grid item style={{ width: "100%"}} align="center">
                                    <img src={KimiyaLogoBlue} alt="kimiya logo blue" />                    
                                </Grid>
                                <Grid item style={{ width: "100%"}}>                            
                                    <Typography className={classes.pageTitle}>
                                        {t("Completa la Registrazione")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Stepper
                                activeStep={activeStep}
                                alternativeLabel
                                className={classes.stepper}
                            >
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel
                                            classes={{
                                                label: classes.stepLabel,
                                                active: classes.stepLabel,
                                            }}
                                        >
                                            {label}
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </>
                    )}                    
                    <Box className={classes.stepContainer}>
                        {activeStep === steps.length ? (
                            <RegistrationCompleted subscription={subscription} />
                        ) : (
                            getStepContent(activeStep)
                        )}
                    </Box>
                </Box>
            </Container>
        </Elements>
    );
};

export default Register;
