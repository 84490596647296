import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query"
import { getAddresses } from "@kimiya/core/store/actions/profile"

import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from "@material-ui/core/CircularProgress";

import DialogTitle from "@kimiya/dialogs/components/dialog-title";
import ContainedButton from "@kimiya/ui/button/contained-button.component";
import OutlinedButton from "@kimiya/ui/button/outlined-button.component";
import StyledSelect from "@kimiya/ui/select/select.component";
import AddressForm from "@kimiya/forms/address/address.form";

import { makeStyles } from "@material-ui/core/styles";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    dialogText: {
        fontFamily: "Brown-Light",
        color: theme.palette.grey5.main,
        fontSize: theme.typography.pxToRem(16),
        letterSpacing: 0.44,
        marginBottom: theme.spacing(2)
    },
    newAddressText: {
        fontFamily: "Brown-Light",
        color: theme.palette.error.light,
        fontSize: theme.typography.pxToRem(16),
        textDecoration: 'underline',
        cursor: "pointer"
    },
    pickAddressText: {
        fontFamily: "Brown-Light",
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(16),
        textDecoration: 'underline',
        cursor: "pointer"
    },
    dialogTextQuit: {
        fontFamily: "Brown-Bold",
        color: theme.palette.primary.main,
    },    
    dialogActions: {
        marginTop: theme.spacing(4),
    }
}));

const CheckoutShippingAddressDialog = ({ onRequestClose, callback, title, bottomComponent }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [addresses, setAddresses] = useState([])
    const [selectedAddress, setSelectedAddress] = useState('')
    const [showForm, setShowForm] = useState(false)

    const onSelectAddress = (e) => {
        if(!e.target.value) return;
        setSelectedAddress(e.target.value);
    }

    const closeDialog = () => {
        onRequestClose();
    };

    const setShippingAddress = () => {
        callback(selectedAddress);
        onRequestClose();
    }

    const refetchAddresses = async () => {                
        const addresses = await getAddresses()        
        setAddresses(addresses ? addresses : [])
        return addresses
    }

    const { isLoading, error } = useQuery(["addresses"], refetchAddresses)

    if (isLoading) return <CircularProgress />
    if (error) return "An error has occurred: " + error.message;

    return (
        <Dialog
            maxWidth={"sm"}
            fullWidth
            open
            TransitionComponent={Transition}
            onClose={closeDialog}
            >            
            <DialogTitle
                title={title}
                bottomComponent={bottomComponent}
                closeDialog={closeDialog}
            />
            <DialogContent className={classes.dialogContent}>
                <Typography className={classes.dialogText}><span onClick={() => setShowForm(false)} className={classes.pickAddressText}>Seleziona un indirizzo dal menù a tendina</span> oppure <span onClick={() => setShowForm(true)} className={classes.newAddressText}>creane uno nuovo</span></Typography>
                {showForm ? (
                    <AddressForm 
                        callback={addressId => { 
                            refetchAddresses()
                            setSelectedAddress(addressId);
                            setShowForm(false);
                        }} 
                    />
                ) : (
                    <StyledSelect
                        label={t("Seleziona indirizzo")}
                        variant="outlined"                                    
                        value={selectedAddress}                                    
                        fullWidth
                        onChange={onSelectAddress}
                    >
                        <MenuItem value={''}>
                            -- {t('Seleziona indirizzo')} --
                        </MenuItem>
                        {addresses && addresses.map(address => (
                            <MenuItem 
                                key={address.id_address} 
                                value={address.id_address}
                            >
                                <ListItemText 
                                    primary={address.s_denomination}
                                    secondary={`${address.s_address} ${address.s_zip_code || ''}`}
                                />                            
                            </MenuItem>
                        ))}
                    </StyledSelect>
                )}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <OutlinedButton
                    fullWidth
                    onClick={closeDialog}
                    variant="outlined"
                >
                    {t("Annulla")}
                </OutlinedButton>
                <ContainedButton                    
                    fullWidth
                    disabled={!selectedAddress}
                    onClick={setShippingAddress}
                    color="secondary"
                    variant="contained"
                >
                    {t("Seleziona")}
                </ContainedButton>
            </DialogActions>            
        </Dialog>
    );
};

export default CheckoutShippingAddressDialog;
