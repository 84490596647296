import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import bgFooter from '@kimiya/core/images/bg-footer.png'
import bgHomePlant from '@kimiya/core/images/bgHomePlant.png'

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',        
        paddingTop:50,
        paddingBottom: 50
    },
    footerText: {
        color: theme.palette.grey['600'],
        textAlign: "center"
    }
}))

const Footer = () => {

    const classes = useStyles()

    return (
        <Container maxWidth={false} className="home-full footer-container" style={{ backgroundImage: `url(${bgFooter})`}}>
            <img className="bottomPlant" src={bgHomePlant}></img>
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
                <Grid item>
                    <Typography className={classes.footerText}>Kimiya S.r.l. | Capitale Sociale 200.000 euro i.v. | P.I. & C.F. 02741300996 | REA GE-507876</Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.footerText}>Sede legale Salita Santa Caterina 4/1 - 16123 Genova (GE)</Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.footerText}>E-mail info@kimiya.club | PEC kimiya.srl@legalmail.it</Typography>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Footer