import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useQuery } from "react-query";
import { getUser, changeUsername } from "@kimiya/core/store/actions/auth";
import * as yup from "yup";
import i18n from "@kimiya/core/i18n/";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';

import Backdrop from "@kimiya/ui/backdrop/backdrop"
import ContainedButton from "@kimiya/ui/button/contained-button.component";
import Input from "@kimiya/ui/input/input.component";

import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";

import { useTheme, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    verifiedText: {
        color: theme.palette.success.main,
    },
    notVerifiedText: {
        color: theme.palette.error.main,
    },
}));

const validationSchema = yup.object({
    s_username: yup
        .string(i18n.t("Inserisci la tua email"))
        .email(i18n.t("Inserisci un indirizzo email valido"))
        .required(i18n.t("L' indirizzo email è obbligatorio")),
});

const ProfileAccountForm = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [form, setForm] = useState({
        s_username: "",
        b_email_verified: false,
    });
    const [loading, setLoading] = useState(false)
    const [saveError, setSaveError] = useState(false)
    const [showToast, setShowToast] = useState(false)

    const fetchUser = async () => {
        const user = await getUser();
        const { s_confirm_email, b_email_verified } = user;
        setForm({ s_username: s_confirm_email, b_email_verified });
        return user;
    };

    const saveUsername = async (s_username) => {
        setLoading(true)
        const res = await changeUsername(s_username);
        setLoading(false)
        if(!res) {          
            setSaveError(true)  
            setShowToast(true)
            return;
        }        
        setShowToast(true)
        fetchUser()
    }

    const handleClose = (event, reason) => {
        setSaveError(false)  
        setShowToast(false)
    };

    const formik = useFormik({
        initialValues: form,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => saveUsername(values.s_username)
    });

    const { isLoading, error } = useQuery("user-username", fetchUser);

    return (
        <Box py={3}>
            <form
                className={classes.root}
                noValidate
                autoComplete="off"
                onSubmit={formik.handleSubmit}
            >
                {error && <Typography>{error}</Typography>}
                <Grid
                    container
                    direction={isMobile ? "column" : "row"}
                    justifyContent={isMobile ? "center" : "space-between"}
                    alignItems="center"
                    spacing={isMobile ? 4 : null}
                >
                    <Grid item xs={12} md={8}>
                        {isLoading ? (
                            <CircularProgress />
                        ) : (
                            <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
                                <Grid item xs={12}>
                                    <Input
                                        autoComplete="off"
                                        type="email"
                                        id="s_username"
                                        name="s_username"
                                        label={t("Email")}
                                        variant="outlined"
                                        fullWidth
                                        value={formik.values.s_username}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.s_username &&
                                            Boolean(formik.errors.s_username)
                                        }
                                        helperText={
                                            formik.touched.s_username &&
                                            formik.errors.s_username
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <Grid item>
                                            {form.b_email_verified ? (
                                                <DoneIcon
                                                    className={classes.verifiedText}
                                                />
                                            ) : (
                                                <CloseIcon
                                                    className={classes.notVerifiedText}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="body2"
                                                className={
                                                    form.b_email_verified
                                                        ? classes.verifiedText
                                                        : classes.notVerifiedText
                                                }
                                            >
                                                {form.b_email_verified
                                                    ? t("Email verificata")
                                                    : t("Email non verificata")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>  
                    <Grid item xs={12} md={2}>
                        <ContainedButton
                            type="submit"
                            variant="contained"
                            color="secondary"
                        >
                            {t("Imposta")}
                        </ContainedButton>
                    </Grid>                  
                </Grid>                
            </form>
            <Backdrop open={loading} customText={t("Salvando...Attendere...")} />
            <Snackbar 
                open={showToast} 
                autoHideDuration={6000} 
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                {!saveError 
                    ? <Alert severity="success">{t('Controlla la tua casella di posta. Ti abbiamo mandato una mail per verificare il tuo nuovo indirizzo.')}</Alert>
                    : <Alert severity="error">{t('Errore durante il salvataggio della nuova password')}</Alert>
                } 
            </Snackbar>
        </Box>
    );
};

export default ProfileAccountForm;
