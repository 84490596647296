import * as React from "react";
import PropTypes from "prop-types";

import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    card: {
        width: "100%",
        borderRadius: 4,
        boxShadow: "1px 1px 4px #0000000D",
        border: "1px solid #EDEDED",
        background: theme.palette.common.white,
    },
    full: {
        padding: 0
    },
    small: {
        padding: theme.typography.pxToRem(10),
    },
    medium: {
        padding: theme.typography.pxToRem(25),
    },
    large: {
        padding: theme.typography.pxToRem(50),
    },
}));

const CardComponent = ({ children, size, customClasses, onClick }) => {
    const classes = useStyles();

    return (
        <Paper
            className={`${classes.card} ${classes[size]} ${customClasses}`}
            onClick={onClick ? onClick : null}
        >
            {children}
        </Paper>
    );
};

CardComponent.defaultProps = {
    size: "medium",
};
CardComponent.propTypes = {
    size: PropTypes.oneOf(["full","small", "medium", "large"]),
};

export default CardComponent;
