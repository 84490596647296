import '@kimiya/core/themes/landing-pages.css';

import Container from '@material-ui/core/Container';
import ContainedButton from "@kimiya/core/components/ui/button/contained-button.component";
import Grid from '@material-ui/core/Grid';
import ProdottiMain from '@kimiya/core/images/prodotti-main-img.jpg'
import ArrowsDownIcon from '@kimiya/core/images/ICO-arrows-down.svg'
import placeHolderContentImg from '@kimiya/core/images/placeholder-content-img.png'

const OurProductsPage = () => (
    <div>
        <div className="home-full">
            <div className="page-mainImg" style={{ backgroundImage: `url(${ProdottiMain})`}}>
                <h2>I nostri prodotti</h2>
                <img className="arrows-down" src={ArrowsDownIcon} alt="scroll-down" />
            </div>
        </div>
        <Container maxWidth="lg" className="inner-mainCont">
            <Grid container justifyContent="center" className="landing-mainCont">
                <Grid item xs={12} sm={10} md={8} className="pages-content">
                    <Grid container justifyContent="space-evenly" className="pages-intro-text">
                        <Grid item xs={3}>
                            <h5 className="page-titleSml prodotti-txt">Obiettivo</h5>
                        </Grid>
                        <Grid item xs={9}>
                            <p className="page-textMain">
                                Gli obiettivi principali che ci siamo posti nella preparazione della nostra linea di prodotti sono tre: praticità, basso contenuto di zuccheri e alto livello proteico, caratteristiche che rendono le nostre ricette adatte a tutti i tipi di diete ed esigenze.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <img src={placeHolderContentImg} alt="scroll-down" width={'100%'}/>
                    </Grid>
                    <Grid container justifyContent="space-evenly" className="pages-intro-text">
                        <Grid item xs={3}>
                            <h5 className="page-titleSml prodotti-txt">I nostri piatti</h5>
                        </Grid>
                        <Grid item xs={9}>
                            <p className="page-text">
                                Piatti pratici nella preparazione: i nostri clienti hanno la possibilità di consumare, a casa e non, piatti elaborati, come al ristorante, pronti in qualche minuto. La difficoltà nel soddisfare questo obiettivo riguarda proprio la tutela del gusto e della qualità. I nostri piatti vengono successivamente abbattuti e consegnati ai consumatori a cui spetterà solo l’onere di scongelarli e riscaldarli, quando necessario.<br></br><br></br>
                                Basso contenuto di zuccheri: come da nostra mission, il focus primario rimane la stabilizzazione della curva glicemica. Per far questo ci accertiamo che gli alimenti che vengono utilizzati abbiano un bassissimo apporto di carboidrati e zuccheri. Il team dei nostri esperti, dopo lunghe ricerche e verifiche, è riuscito ad individuare prodotti naturali e alternativi (come, ad esempio, eritritolo e farina di semi di lino) che, combinati, fra loro non vadano ad incidere sulla curva glicemica.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-evenly" className="pages-intro-text">
                        <Grid item xs={3}>
                            <h5 className="page-titleSml prodotti-txt">Proteine</h5>
                        </Grid>
                        <Grid item xs={9}>
                            <p className="page-text">
                            Alto livello proteico: tutti sappiamo che le proteine sono l&#39;elemento costitutivo principale dei muscoli. Le loro funzioni però non si limitano a questo; infatti, tutte le cellule contengono proteine e proprio le proteine contribuiscono al benessere dei nostri tessuti. Il fabbisogno giornaliero di un individuo è di 0,6 g di proteine per chilogrammo di peso corporeo al giorno, ma questo valore può variare in funzione dell’intensità dell’attività fisica svolta nel quotidiano. Proprio per questo motivo i nostri piatti vengono calibrati per aumentare la carica proteica e far sì che sia possibile assumere la giusta quantità di nutrienti e non rischiare di perdere muscolo piuttosto che massa grassa.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div className="social">
                    <div className="liner"></div>
                    <h4>Seguici sui social</h4>
                    <div className="social_buttons">
                        <p>Seguici su nostri canali social e rimani sempre aggiornato sulle nostre novità e promozioni</p>
                        <ContainedButton 
                            variant="contained" 
                            className="instaBut landingBut bigBut"
                            href="https://www.instagram.com/kimiya_nutrition/"
                            target="_blank"
                            rel="noopener"
                        >
                            @KIMIYA_OFFICIAL
                        </ContainedButton>
                        <ContainedButton 
                            variant="contained" 
                            className="fbBut landingBut bigBut"
                            href="https://www.facebook.com/profile.php?id=100086437812998"
                            target="_blank"
                            rel="noopener"
                        >
                            /KIMIYA_OFFICIAL
                        </ContainedButton>
                    </div>
                </div>
        </Container>
    </div>
    

)

export default OurProductsPage