import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Container from "@material-ui/core/Container";

import ProfileAccount from "@kimiya/custom/profile/account";
import ProfileChangePassword from "@kimiya/custom/profile/change-password";
import ProfileAddresses from "@kimiya/custom/profile/addresses";
import ProfileVAT from "@kimiya/custom/profile/vat-data";
import ProfileSubscription from "@kimiya/custom/profile/subscription";
import ProfileSettings from "@kimiya/custom/profile/settings";
import ProfileTerms from "@kimiya/custom/profile/terms";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
    root: {
        minHeight: '80%',
        paddingBottom: theme.spacing(5),
    },
    sectionTitle: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(48),
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,
    },
}));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const Profile = () => {
    const classes = useStyle();
    const { profile_section } = useParams();

    const renderProfileSection = useMemo(() => {
        switch (profile_section) {
            case "password":
                return <ProfileChangePassword />;
            case "addresses":
                return <ProfileAddresses />;
            case "vat":
                return <ProfileVAT />;
            case "subscription":
                return (
                    <Elements stripe={stripePromise}>
                        <ProfileSubscription />
                    </Elements>
                )
            case "settings":
                return <ProfileSettings />;
            case "terms":
                return <ProfileTerms />;
            case "account":
            default:
                return <ProfileAccount />;
        }
    }, [profile_section]);

    return <Container maxWidth={'md'} className={classes.root}>{renderProfileSection}</Container>;
};
export default Profile;
