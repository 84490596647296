/* eslint-disable import/no-anonymous-default-export */
export default [
    //COACH ROUTES
    {
        component: require("../pages/__coach/dashboard/dashboard.page").default,
        routePath: "/coach/dashboard",        
        key: "coach-dashboard",
        layout: "coach",
        sidebar: "invisible",
        visibility: ["COACH"],        
    },    
    //AUTHENTICATED ROUTES
    {
        component: require("../pages/verify-email/verify-email.page").default,
        routePath: "/verify-email/:token/:secret/:id_resource/:email",        
        key: "verify-email",
        layout: "clear",
        visibility: ["USER"],
    },
    {
        component: require("../pages/orders/orders.page").default,
        routePath: "/orders",        
        key: "orders",
        layout: "default",        
        visibility: ["USER"],        
    },    
    {
        component: require("../pages/order/order.page").default,
        routePath: "/order-details/:order_id",        
        key: "order",
        layout: "default",        
        visibility: ["USER"],
    },
    {
        component: require("../pages/account/account.page").default,
        routePath: "/account",        
        key: "account",
        layout: "default",
        visibility: ["USER"],
    },
    {
        component: require("../pages/checkout/checkout.page").default,
        routePath: "/checkout",        
        key: "checkout",
        layout: "default",
        visibility: ["USER"],
    },
    {
        component: require("../pages/payment-success/payment-success.page").default,
        routePath: "/payment-success",        
        key: "payment-success",
        layout: "default",
        visibility: ["USER"],
    },
    {
        component: require("../pages/subscription_checkout/subscription_checkout.page").default,
        routePath: "/profile/:profile_section/checkout",        
        key: "subscription_checkout",
        layout: "default",        
        visibility: ["USER"],
    },
    {
        component: require("../pages/subscription_upgrade/subscription_upgrade.page").default,
        routePath: "/profile/:profile_section/upgrade",        
        key: "subscription_upgrade",
        layout: "default",
        sidebar: "profile",
        visibility: ["USER"],
    },    
    {
        component: require("../pages/address_form/address_form.page").default,
        routePath: "/profile/:profile_section/new",        
        key: "create_address",
        layout: "default",
        sidebar: "profile",
        visibility: ["USER"],
    },
    {
        component: require("../pages/address_form/address_form.page").default,
        routePath: "/profile/:profile_section/edit/:address_id",        
        key: "edit_address",
        layout: "default",
        sidebar: "profile",
        visibility: ["USER"],
    },
    {
        component: require("../pages/profile/profile.page").default,
        routePath: "/profile/:profile_section",        
        key: "profile",
        layout: "default",
        sidebar: "profile",
        visibility: ["USER"],
    },
    {
        component: require("../pages/complete-registration/complete-registration.page").default,
        routePath: "/complete-registration/:step",        
        key: "complete-registration",
        layout: "clear",
        visibility: ["USER"],
    },    
    //PUBLIC ROUTES        
    {
        component: require("../pages/pay-mobile/pay-mobile.page").default,
        routePath: "/complete-mobile-payment/:transaction_id/:uuid_transaction",        
        key: "complete-mobile-payment",
        layout: "clean",
        visibility: ["public"],
    },
    {
        component: require("../pages/help/help.page").default,
        routePath: "/request-support",
        key: "help",
        layout: "clear",
        visibility: ["public","USER"],
    },
    {
        component: require("../pages/verify-user/verify-user.page").default,
        routePath: "/verify-user/:token/:secret/:id_resource",        
        key: "verify-user",
        layout: "clear",
        visibility: "public",
    },
    {
        component: require("../pages/register/register-mobile.page").default,
        routePath: "/register-mobile",        
        key: "register-mobile",
        layout: "clear",
        visibility: "public",
    },
    {
        component: require("../pages/register/register.page").default,
        routePath: "/register",        
        key: "register",
        layout: "default",
        visibility: "public",
    },
    {
        component: require("../pages/reset-password/password-reset-success.page").default,
        routePath: "/reset-password-success",        
        key: "reset-password-success",
        layout: "clear",
        visibility: "public",
    },
    {
        component: require("../pages/reset-password/password-reset-request-success.page").default,
        routePath: "/reset-password-request-success",        
        key: "reset-password-request-success",
        layout: "clear",
        visibility: "public",
    },
    {
        component: require("../pages/reset-password/reset-password-mobile.page").default,
        routePath: "/reset-password-mobile/:token?/:secret?/:username?",        
        key: "reset-password-mobile",
        layout: "clear",
        visibility: "public",
    },
    {
        component: require("../pages/reset-password/reset-password.page").default,
        routePath: "/reset-password/:token?/:secret?/:username?",        
        key: "reset-password",
        layout: "default",
        visibility: "public",
    },
    {
        component: require("../pages/login/login.page").default,
        routePath: "/login",        
        key: "login",
        layout: "default",
        visibility: "public",
    },   
    {
        component: require("../pages/faq/faq.page").default,
        routePath: "/faq",        
        key: "faq",
        layout: "default",
        visibility: ["public","USER"],        
        sidebar: "invisible"
    },
    {
        component: require("../pages/cart/cart.page").default,
        routePath: "/cart",        
        key: "cart",
        layout: "default",
        visibility: ["public","USER"],  
        sidebar: "invisible"      
    },  
    {
        component: require("../pages/product-details/product-details.page").default,
        routePath: "/product-details/:product_code",        
        key: "product-details",
        layout: "default",
        visibility: ["public","USER"],
        sidebar: "shop"
    },     
    {
        component: require("../pages/shop/shop.page").default,
        routePath: "/shop/category/:product_category?",        
        key: "shop",
        layout: "default",
        visibility: ["public","USER"],
        sidebar: "shop"
    },
    {
        component: require("../pages/shop/shop.page").default,
        routePath: "/shop",        
        key: "shop",
        layout: "default",
        visibility: ["public","USER"],
        sidebar: "shop"
    },    
    {
        component: require("../pages/mission/mission.page").default,
        routePath: "/la-nostra-mission",        
        key: "mission",
        layout: "default",
        visibility: ["public","USER"],
        sidebar: "invisible"
    },
    {
        component: require("../pages/raw-material/raw-material.page").default,
        routePath: "/le-nostre-materie-prime",        
        key: "raw-material",
        layout: "default",
        visibility: ["public","USER"],
        sidebar: "invisible"
    },
    {
        component: require("../pages/our-products/our-products.page").default,
        routePath: "/i-nostri-prodotti",        
        key: "our-products",
        layout: "default",
        visibility: ["public","USER"],
        sidebar: "invisible"
    },
    {
        component: require("../pages/team/team.page").default,
        routePath: "/la-nostra-azienda",        
        key: "team",
        layout: "default",
        visibility: ["public","USER"],
        sidebar: "invisible"
    },
    {
        component: require("../pages/home/home.page").default,
        routePath: "/home",        
        key: "home",
        layout: "default",
        visibility: ["public","USER"],
        sidebar: "invisible"
    },
];
