import { useTranslation } from "react-i18next";
import { DialogConsumer } from "@kimiya/context/dialog.context";
import { useSelector, useDispatch } from "react-redux"
import { setCartShippingAddress, resetShippingAddress } from "@kimiya/core/store/actions/checkout"

import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import ConfirmActionDialog from "@kimiya/dialogs/confirm-action/confirm-action.dialog"
import CheckoutShippingAddressDialog from "@kimiya/core/components/dialogs/checkout-shipping-address/checkout-shipping-address.dialog";
import CheckoutBillingAddress from "@kimiya/custom/checkout/checkout-billing-address"
import CheckoutShippingAddress from "./checkout-shipping-address";

import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({    
    sectionTitle: {
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.secondary.main,
        marginBottom: theme.spacing(1),
    },
    sectionTextBold: {
        fontFamily: "Brown-Bold",
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.grey5.main,
        letterSpacing: 0.44,
    },
    sectionText: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.grey5.main,
        letterSpacing: 0.44,
    },
    sectionTextMargin: {
        marginBottom: theme.spacing(1)
    },
    editAddressButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },    
    changeShippingButton: {
        marginTop: theme.spacing(2),
        fontSize: theme.typography.pxToRem(12),
    }
}));

const CheckoutBillingShipping = () => {

    const { t } = useTranslation();
    const classes = useStyle();    
    const dispatch = useDispatch();
    const cart = useSelector(({ checkout }) => checkout.cart || null )

    const onShippingAddressChange = async (idShippingAddress) => {
        if(!idShippingAddress) return;
        dispatch(setCartShippingAddress({ idShippingAddress }))
    };

    const onShippingAddressReset = async () => {        
        dispatch(resetShippingAddress())
    }

    if(!cart) return <CircularProgress />

    return (
        <DialogConsumer>
            {({ showDialog }) => (                
                <Grid container direction="column" spacing={3}>
                    {cart?.id_resource_shipping_delivery===0 ? (
                        <Grid item xs={12}>
                            <CheckoutBillingAddress sectionTitle={t('fatturato e spedito a')} />
                            <Button 
                                endIcon={<LocalShippingIcon />}
                                className={classes.changeShippingButton}
                                variant="text"
                                onClick={() => showDialog(CheckoutShippingAddressDialog,{
                                    title: t('Seleziona indirizzo di spedizione'),
                                    callback: (address) => onShippingAddressChange(address)
                                })}
                            >
                                {t('Specisci ad un indirizzo diverso')}
                            </Button>                                                                       
                        </Grid>
                    ): (
                        <>
                            <Grid item xs={12}>
                                <CheckoutBillingAddress sectionTitle={t('fatturato a')} />
                                <Button 
                                    endIcon={<LocalShippingIcon />}
                                    className={classes.changeShippingButton}
                                    variant="text"
                                    onClick={() => showDialog(ConfirmActionDialog,{
                                        title: t('Conferma Azione'),
                                        callback: () => onShippingAddressReset()
                                    })}
                                >
                                    {t('Usa questo indirizzo anche per la spedizione')}
                                </Button>                                                                       
                            </Grid>

                            <Grid item xs={12}>
                                <CheckoutShippingAddress />                                
                            </Grid>
                        </>
                    )} 
                </Grid>
            )}
        </DialogConsumer>
    )
}

export default CheckoutBillingShipping