import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createSubscription } from "@kimiya/core/store/actions/payments";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import StripeCardInput from "@kimiya/custom/stripe/card-element"

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Backdrop from "@kimiya/ui/backdrop/backdrop"
import ContainedButton from "@kimiya/ui/button/contained-button.component";
import OutlinedButton from "@kimiya/ui/button/outlined-button.component"
import SubscriptionCard from "@kimiya/ui/subscription-card/subscription-card";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100vh",
    },
    subscriptionTitle: {
        fontFamily: "Brown-Light",
        fontSize: 20,
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.main,
    },
    backButton: {
        textTransform: "Capitalize",
    },
    recapText: {
        fontFamily: "Brown-Light",
        fontSize: 16,
        color: theme.palette.grey5.main,
        letterSpacing: 0.44,
    },
    paymentText: {
        fontSize: 16,
        color: theme.palette.secondary.main,
        marginBottom: theme.typography.pxToRem(15)
    },
    paymentContainer: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid  ${theme.palette.secondary.main}`,
        borderRadius: 20,
        padding: 20
    }    
}));

const RegistrationRecap = ({ subscription, handleBack, handleNext }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const user = useSelector(({ auth }) => auth.user)
    const stripe = useStripe();
    const elements = useElements();

    const [loading, setLoading] = useState(false)
    const [isCardOk, setIsCardOk] = useState(false)

    const completePayment = async () => {
        setLoading(true);
        
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            console.error("Payment Gateway not ready!")
            setLoading(false)
            return;
        }

        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                email: user.email,
                name: user.s_resource
            },
        });

        if (result.error) {
            setLoading(false)
            console.log(result.error.message);            
            return;
        }
          
        const sub = await createSubscription({
            id_item: ""+subscription.id_item, 
            s_payment_method: result.paymentMethod.id
        })

        if(!sub || !sub.client_secret){
            setLoading(false)
            toast.error("Sottoscrizione abbonamento non andata a buon fine per un problema tecnico. Ci scusiamo per il disagio. Se il problema persiste si prega di contattare il nostro centro assistenza. Grazie");            
            return;
        }

        const { client_secret } = sub;

        stripe
            .confirmCardPayment(client_secret)
            .then(function (result) {
                setLoading(false)
                if (result.error) {
                    console.log("There was an issue!");
                    console.log(result.error);
                    // Display error message in your UI.
                    // The card was declined (i.e. insufficient funds, card has expired, etc)
                } else {
                    console.log("Subscription completed");
                    // Show a success message to your customer                    
                    handleNext()
                }
            });        
    };

    const onCardChange = ({ empty, complete }) =>  {
        if(empty || !complete){
            setIsCardOk(false)
            return;
        }
        setIsCardOk(true)

    } 

    if (!subscription) return null;

    return (        
        <Box mt={4} className={classes.root}>
            <Backdrop open={loading} customText={t('Completando il pagamento...')} />
            <Typography
                align="center"
                className={classes.subscriptionTitle}
            >
                {t("Hai scelto il seguente abbonamento")}
            </Typography>            
            <SubscriptionCard item={subscription} onSelect={() => {}} />
            <Typography align="center" className={classes.recapText}>
                {t("Inserisci i tuoi dati per completare il pagamento")}:
            </Typography>
            <Box py={3}>
                <Typography className={classes.paymentText}>
                    {t("Inserisci i dati di pagamento")}:
                </Typography>
                <div className={classes.paymentContainer}>
                    <StripeCardInput onChange={onCardChange} />
                </div>
            </Box>                        
            <ContainedButton
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
                onClick={completePayment}
                disabled={!isCardOk}
            >
                {t("Completa il pagamento")}
            </ContainedButton>            
            {handleBack && (
                <Box my={4}>                                                
                    <OutlinedButton 
                        onClick={handleBack} 
                        fullWidth
                        variant="outlined"
                        color="secondary"
                    >
                        {t("Indietro")}
                    </OutlinedButton>
                </Box>
            )}
        </Box>
    );
};

export default RegistrationRecap;
