import { useState } from "react"
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { useQuery } from 'react-query'
import { getShopCategories } from "@kimiya/core/store/actions/shop";

import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Backdrop from "@kimiya/ui/backdrop/backdrop"

import ShopIcon from "@material-ui/icons/Store";

import useStyles from "@kimiya/ui/toolbar/toolbar.styles";

const AppSidebar = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const [categories, setCategories] = useState()    
    let { product_category } = useParams();

    if(!product_category && location?.state?.category)
        product_category = location.state.category;

    const filterProducts = (value) => {
        if(value) {
            history.push(`/shop/category/${value}`);
            return;
        }
        history.push(`/shop`);
    };

    const refretchShopCategories = async () => {
        const shop_categories = await getShopCategories()
        setCategories(shop_categories)
        return shop_categories
    }

    const { isLoading, error } = useQuery("shop-categories", refretchShopCategories);

    if (isLoading || !categories) return <Backdrop open customText={t('Fetching shop categories...')} />
    if (error) return "An error has occurred: " + error.message;    

    return (        
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <Toolbar />
            <div className={classes.drawerContainer}>
                <List>
                    <ListItem>
                        <ListItemIcon className={classes.sidebarHeaderIcon}>
                            <ShopIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("Shop")} className={classes.sidebarHeaderText} />
                    </ListItem>
                    <Divider className={classes.sidebarHeaderDivider} />
                    <ListItem button onClick={() => filterProducts()}>
                        <ListItemText
                            className={(!parseInt(product_category)) ? classes.sidebarVoiceSelected : classes.sidebarVoice}
                            primary={t("Tutti")}                                
                        />
                    </ListItem>
                    {categories.map((category, index) => (
                        <ListItem button key={index} onClick={() => filterProducts(category.s_code)}>
                            <ListItemText
                                className={((product_category===category.s_code)) ? classes.sidebarVoiceSelected : classes.sidebarVoice}
                                primary={category.s_subcategory}                                
                            />
                        </ListItem>
                    ))}
                </List>
            </div>
        </Drawer>
    );
};

export default AppSidebar;
