import { useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getShopCategories } from "@kimiya/core/store/actions/shop";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Backdrop from "@kimiya/ui/backdrop/backdrop";

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 50,
        marginBottom: 15,
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
}));

const ShopMenuMobile = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    let { product_category } = useParams();
    const [categories, setCategories] = useState();
    const [value, setValue] = useState(0);

    if (!product_category && location?.state?.category)
        product_category = location.state.category;

    const handleChange = (event, newValue) => {
        setValue(newValue);
        filterProducts(newValue!==0 ? categories[newValue-1].s_code : null);
    };

    const filterProducts = (value) => {
        if (value) {
            history.push(`/shop/category/${value}`);
            return;
        }
        history.push(`/shop`);
    };

    const refretchShopCategories = async () => {
        const shop_categories = await getShopCategories();        
        setCategories(shop_categories);
        if (product_category && shop_categories?.length > 0) {
            setValue(
                shop_categories.findIndex((c) => c.s_code === product_category)
            );
        }
        return shop_categories;
    };

    const { isLoading, error } = useQuery(
        "mobile-shop-categories",
        refretchShopCategories
    );

    if (isLoading || !categories)
        return <Backdrop open customText={t("Fetching shop categories...")} />;
    if (error) return "An error has occurred: " + error.message;

    return (
        <div className={classes.root}>
            <AppBar position="relative" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab                        
                        label={t('Tutti')}
                        id={`scrollable-auto-tab-0`}
                        aria-controls={`scrollable-auto-tabpanel-0`}
                    />
                    {categories.map((category, index) => (
                        <Tab
                            key={category.id_subcategory}
                            label={category.s_subcategory}
                            id={`scrollable-auto-tab-${index+1}`}
                            aria-controls={`scrollable-auto-tabpanel-${index+1}`}
                        />
                    ))}
                </Tabs>
            </AppBar>
        </div>
    );
};

export default ShopMenuMobile;
