import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { StyledInput } from "../input/input.component";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
    },
    inputLabel: {
        fontSize: theme.typography.pxToRem(18),
        color: theme.palette.secondary.main,
        "&.Mui-focused": {
            color: theme.palette.secondary.main,
        },
    },
}));

const StyledSelect = (props) => {
    const classes = useStyles();
    const {helperText, error, label, required, ...rest} = props;
    return (
        <FormControl className={classes.formControl} error={error}>
            <InputLabel shrink className={classes.inputLabel}>
                {label}{required && ' *'}
            </InputLabel>
            <Select
                error={error}
                input={<StyledInput error={error} {...rest} />}
                {...rest}
            >
                {props.children}
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
};

export default StyledSelect;
