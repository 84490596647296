import { useCallback, useEffect, useState, Fragment } from "react";
import { getChatMessages } from "@kimiya/core/store/actions/coaching";
import eventBus from "@kimiya/core/utils/event_bus"

import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";

import ChatBubble from "@kimiya/custom/coach/chat-bubble/chat-bubble";
import NoChatMessages from "@kimiya/custom/coach/no-chat-messages/no-chat-messages"

import useChatStyles from "@kimiya/custom/coach/chat.styles";

var interval;
var fetchChatMessages;

const ChatMessages = ({ chatId }) => {
    const classes = useChatStyles();
    const [messages, setMessages] = useState();
    const [loading, setLoading] = useState();

    const createInterval = useCallback(() => {
        interval = setInterval(async () => {
            fetchChatMessages()
        }, 10000);
    // eslint-disable-next-line no-use-before-define
    },[])

    fetchChatMessages = useCallback(async () => {
        if(!messages) setLoading(true)
        const newMessages = await getChatMessages(chatId);
        setLoading(false)
        setMessages(newMessages);
        clearInterval(interval);
        createInterval()
    }, [chatId, messages, createInterval]);


    useEffect(() => {
        eventBus.on("refetchMessages", () => {
            fetchChatMessages()
        });
    },[])


    useEffect(() => {
        fetchChatMessages();       
        return () => clearInterval(interval);
    }, [chatId]);
    
    if(loading) {
        return (
            <Grid container className={classes.chatMessagesContainer}>
                <Grid item xs={12}>
                    {new Array(10)
                        .fill(0)
                        .map((_, index) => (
                            <Skeleton
                                key={index}
                                animation="wave"
                                className={classes.sidebarEmptySkeleton}
                            />
                        ))
                    }
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container className={classes.chatMessagesContainer}>
            <Grid item xs={12}>
                {messages
                    ? Object.keys(messages).length>0 
                        ? Object.keys(messages).map((key) => (
                                <Fragment key={key}>
                                    <Grid container justifyContent="center" alignItems="center" className={classes.chatMessagesDateContainer}>
                                        <Grid item>
                                            <Typography className={classes.chatMessagesDate}>{key}</Typography>
                                        </Grid>
                                    </Grid>
                                    {messages[key].map((message,index) => (
                                        <ChatBubble
                                            key={message.id}
                                            idx={index}
                                            message={message}                              
                                        />
                                    ))}
                                </Fragment>
                            ))
                        : <NoChatMessages />
                    : new Array(10)
                          .fill(0)
                          .map((_, index) => (
                              <Skeleton
                                  key={index}
                                  animation="wave"
                                  className={classes.sidebarEmptySkeleton}
                              />
                          ))}
            </Grid>
        </Grid>
    );
};

export default ChatMessages;
