import { useState, useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom"
import { getOrder } from "@kimiya/core/store/actions/profile"
import { format } from "date-fns";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Skeleton from "@material-ui/lab/Skeleton"

import Card from "@kimiya/ui/card/card"
import OrderBillingShipping from "@kimiya/custom/order/billing-shipping.order"
import OrderInvoicesTracking from "@kimiya/core/components/custom/order/invoices-tracking.order";
import OrderStatusShipping from "@kimiya/core/components/custom/order/status-shipping.order";
import OrderItems from "@kimiya/core/components/custom/order/items.order";

import BackIcon from "@material-ui/icons/KeyboardBackspace";

import useStyle from "@kimiya/custom/order/order.style"

const Order = () => {

    const classes = useStyle();
    const { t } = useTranslation();
    const history = useHistory();
    const { order_id } = useParams()
    const [order, setOrder] = useState()

    const goBack = () => history.push("/orders");

    const fetchOrder = useCallback(async () => {
        const res = await getOrder(order_id)
        setOrder(res)
    },[order_id])

    useEffect(() => {
        fetchOrder()
    },[fetchOrder])

    return(
        <Container maxWidth={'md'} className={classes.root}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography className={classes.orderTitle}>
                        {t("Dettaglio ordine")}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        startIcon={<BackIcon />}
                        variant="text"
                        onClick={goBack}
                        color="secondary"
                    >
                        {t("Back")}
                    </Button>
                </Grid>
            </Grid>
            {order ? (

                <Card>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography className={classes.orderNumber}>
                                {t("Ordine")} {order.s_number} (#{order_id})
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.orderDate}>
                                {t("del")} {format(Date.parse(order.d_transaction),"dd/MM/yyyy - HH:mm")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <OrderBillingShipping order={order} />
                    <OrderInvoicesTracking order={order} />
                    <OrderStatusShipping order={order} />
                    <OrderItems items={order.items} />
                    <Divider className={classes.divider} />
                    <Grid container justifyContent="space-between" alignItems="center" className={classes.totalRecapContainer}>
                        <Grid item>
                            <Typography className={classes.totalRecapText}>
                                {t("Totale")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.totalRecapText}>
                                {order.m_transaction_gross.toFixed(2)}€
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            ) : new Array(10).fill(0)
                .map((_, index) => (
                    <Skeleton
                        key={index}
                        animation="wave"
                        style={{ width: '100%' }}
                    />
                ))
            }
        </Container>
    )
}

export default Order