import { useMemo, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import ShopIcon from "@material-ui/icons/Store";
// import BlogIcon from "@material-ui/icons/TabOutlined";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import useStyles from "@kimiya/ui/toolbar/toolbar.styles";

const menu = [
    {
        url: "/shop",
        external: false,
        label: "Shop",
        icon: <ShopIcon />,
    },
    // {
    //     url: "https://meetico.ltd",
    //     external: true,
    //     label: "Blog",
    //     icon: <BlogIcon />,
    // },
    {
        url: "/faq",
        external: false,
        label: "Domande e Risposte",
        icon: <InfoIcon />,
    },
];

const NavBar = () => {

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();    
    
    const openMenuVoice = useCallback(
        (url, external) => {
            if (external) return window.open(url, "_blank");
            history.push(url);
        },
        [history]
    );

    const isActiveRoute = useCallback(
        (url) => {
            return location.pathname.includes(url);
        },
        [location]
    );

    const renderMenu = useMemo(() => {
        return menu.map((item, index) => (
            <Grid item key={index} className={classes.menuVoiceContainer}>
                <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item>
                        <IconButton
                            onClick={() =>
                                openMenuVoice(item.url, item.external)
                            }
                            size="small"
                            className={
                                isActiveRoute(item.url)
                                    ? classes.menuIconContainerSelected
                                    : classes.menuIconContainer
                            }
                        >
                            {item.icon}
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography
                            className={
                                isActiveRoute(item.url)
                                    ? classes.menuVoiceSelected
                                    : classes.menuVoice
                            }
                            onClick={() =>
                                openMenuVoice(item.url, item.external)
                            }
                        >
                            {item.label}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        ));
    }, [classes, isActiveRoute, openMenuVoice]);

    return renderMenu
}

export default NavBar