import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  createPaymentIntent,
  cartPaymentCompleted,
} from "@kimiya/core/store/actions/checkout";

import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

import Backdrop from "@kimiya/ui/backdrop/backdrop";
import DialogTitle from "@kimiya/dialogs/components/dialog-title";
import ContainedButton from "@kimiya/ui/button/contained-button.component";
import OutlinedButton from "@kimiya/ui/button/outlined-button.component";
import StripeCardInput from "@kimiya/custom/stripe/card-element";

import { makeStyles } from "@material-ui/core/styles";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  dialogText: {
    fontFamily: "Brown-Light",
    color: theme.palette.grey5.main,
    fontSize: theme.typography.pxToRem(16),
    letterSpacing: 0.44,
    marginBottom: theme.spacing(2),
  },
  newAddressText: {
    fontFamily: "Brown-Light",
    color: theme.palette.error.light,
    fontSize: theme.typography.pxToRem(16),
    textDecoration: "underline",
    cursor: "pointer",
  },
  dialogTextQuit: {
    fontFamily: "Brown-Bold",
    color: theme.palette.primary.main,
  },
  dialogActions: {
    marginTop: theme.spacing(4),
  },
  errorText: {
    marginTop: 25,
    color: theme.palette.error.main,
    lineHeight: 1,
  },
}));

const CheckoutStripePaymentDialog = ({
  onRequestClose,
  callback,
  title,
  bottomComponent,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const cart = useSelector(({ checkout }) => checkout.cart);

  const [loading, setLoading] = useState(false);
  const [isCardOk, setIsCardOk] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const closeDialog = () => {
    onRequestClose();
  };

  const paymentCompletedCallback = () => {
    callback();
    onRequestClose();
  };

  const requestPaymentIntent = async () => {
    setErrorMessage(false);
    const clientSecret = await createPaymentIntent(cart.id_transaction);
    if (!clientSecret) {
      setErrorMessage(
        "Errore durante la creazione della richiesta di pagamento. Si prega di riprovare o di contattare l'assistenza."
      );
      return;
    }
    completePayment(clientSecret);
  };

  const completePayment = async (clientSecret) => {
    setLoading(true);
    if (!stripe || !elements || !clientSecret) {
      // Stripe.js has not yet loaded.
      console.error("Payment Gateway not ready or client secret missing!");
      setLoading(false);
      return;
    }
    const paymentResult = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });
    setLoading(false);

    if (paymentResult.error) {
      setErrorMessage(paymentResult.error.message);
    } else {
      if (paymentResult.paymentIntent.status === "succeeded") {
        //Convert transaction to order on payment succeded
        await cartPaymentCompleted({
          id_transaction: "" + cart.id_transaction,
          s_uuid: cart.s_uuid,
        });
        paymentCompletedCallback();
      }
    }
  };

  const onCardChange = ({ empty, complete }) => {
    if (empty || !complete) {
      setIsCardOk(false);
      return;
    }
    setIsCardOk(true);
  };

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open
      TransitionComponent={Transition}
      onClose={closeDialog}
    >
      <DialogTitle
        title={title}
        bottomComponent={bottomComponent}
        closeDialog={closeDialog}
      />
      <DialogContent className={classes.dialogContent}>
        <Backdrop
          open={loading}
          customText={t("Processando il pagamento...attendere...")}
        />
        <Typography className={classes.dialogText}>
          {t(
            'Inserisci i dati di pagamento e clicca su "Completa Ordine" per procedere con il pagamento'
          )}
        </Typography>
        <StripeCardInput onChange={onCardChange} />
        {errorMessage && (
          <Typography variant="caption" className={classes.errorText}>
            {errorMessage}
          </Typography>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <OutlinedButton fullWidth onClick={closeDialog} variant="outlined">
          {t("Annulla")}
        </OutlinedButton>
        <ContainedButton
          fullWidth
          disabled={!isCardOk}
          onClick={requestPaymentIntent}
          color="secondary"
          variant="contained"
        >
          {t("Completa Ordine")}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
};

export default CheckoutStripePaymentDialog;
