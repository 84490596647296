import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import KimiyaLogo from "@kimiya/core/images/kimiya-logo-blue.svg"

import useChatStyles from "@kimiya/custom/coach/chat.styles"

const NoChat = () => {

    const classes = useChatStyles()

    return (
        <Grid container justifyContent="center" alignItems="center" className={classes.noChatContainer}>
            <Grid item align="center">
                <img src={KimiyaLogo} alt="Kimiya Logo" />
                <Typography variant="h4" color="primary">Nessuna Chat selezionata!</Typography>
                <Typography variant="body2" color="secondary">Clicca su una chat dal menù laterale per visualizzare i messaggi</Typography>
            </Grid>
        </Grid>
    )
}

export default NoChat