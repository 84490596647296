import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import ContainedButton from "@kimiya/ui/button/contained-button.component";
import OutlinedButton from "@kimiya/ui/button/outlined-button.component";
import Card from "@kimiya/ui/card/card";
import SubscriptionCard from "@kimiya/ui/card/subscription-card";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
    pageTopContainer: {
        marginTop: theme.spacing(4),
        textAlign: "center",
    },
    pageTitle: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(48),
        color: theme.palette.primary.main,
        marginTop: theme.spacing(2),
    },
    pageSubtitle: {
        color: theme.palette.grey3.main,
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(24),
        marginBottom: theme.spacing(2),
    },
    recapCard: {
        marginTop: theme.spacing(2),
        borderRadius: 10
    },
    cardTitle: {
        fontFamily: "Brown-Bold",
        color: theme.palette.secondary.main,
        fontSize: theme.typography.pxToRem(26),
    },
    sectionTitle: {
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.secondary.main,
        marginBottom: theme.spacing(1),
    },
    sectionText: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.grey5.main,
        letterSpacing: 0.44,
    },
    sectionTextMargin: {
        marginBottom: theme.spacing(1)
    },
    cartItemRow: {
        margin: theme.spacing(2,0)
    },
    cartText: {
        fontFamily: "Brown-Light",
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.grey5.main,
        letterSpacing: 0.44
    },
    checkoutDivider: {
        margin: theme.spacing(4,0)
    },
    totalRecapText:{ 
        color: theme.palette.primary.main,
        fontFamily: "Brown-Bold",
        fontSize: theme.typography.pxToRem(24),
        letterSpacing: 0.23
    },
    totalRecapContainer: {
        marginBottom: theme.spacing(4)
    }
}));

const SubscriptionCheckoutPage = () => {
    const { t } = useTranslation();
    const classes = useStyle();
    const history = useHistory();

    const goToPayment = () => history.push({
        pathname: "/payment-success",
        state: {
            redirect_to: "/profile/subscription"
        }
    });

    return (
        <>
            <div className={classes.pageTopContainer}>
                <Typography className={classes.pageTitle}>
                    {t("Checkout upgrade")}
                </Typography>
                <Typography className={classes.pageSubtitle}>
                    {t("Hai scelto il seguente upgrade")}:
                </Typography>
            </div>
            <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >
                <Grid item xs={8}>
                    <SubscriptionCard 
                        selected
                        subscription={{
                            name: "BASIC",
                            price: 1069.9,
                            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Card customClasses={classes.recapCard}>
                        <Grid container direction="column" spacing={3}>
                            <Grid item xs={12}>
                                <Typography className={classes.sectionTitle}>
                                    {t("fatturato a")}:
                                </Typography>
                                <Typography className={classes.sectionText}>
                                    Mario Rossi
                                </Typography>
                                <Typography className={classes.sectionText}>
                                    KQRWBD81S41F779H
                                </Typography>
                                <Typography className={classes.sectionText}>
                                    viale Palmiro Ficino 59
                                </Typography>
                                <Typography className={classes.sectionText}>
                                    50019, Sesto Fiorentino (FI)
                                </Typography>
                                <Typography className={classes.sectionText}>
                                    +393331234567
                                </Typography>
                                <Typography className={`${classes.sectionText} ${classes.sectionTextMargin}`}>
                                    miaemail@mail.com
                                </Typography>
                                <OutlinedButton onClick={() => history.push("/profile/addresses")}>
                                    {t("Modifica")}
                                </OutlinedButton>
                            </Grid>
                            
                        </Grid>
                        <Divider className={classes.checkoutDivider} />
                        <Grid container justifyContent="space-between" alignItems="center" className={classes.totalRecapContainer}>
                            <Grid item>
                                <Typography className={classes.totalRecapText}>
                                    {t("Totale")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.totalRecapText}>
                                    1069.90€
                                </Typography>
                            </Grid>
                        </Grid>
                        <ContainedButton
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={goToPayment}
                        >
                            {t("Vai al pagamento")}
                        </ContainedButton>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default SubscriptionCheckoutPage;
