import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import ContainedButton from "@kimiya/ui/button/contained-button.component";
import Input from "@kimiya/ui/input/input.component";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
}));

const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

const initPasswordForm = {
    password: "",
    confirmPassword: "",
};

const ProfilePasswordForm = ({ onSavePassword }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [form, setPasswordForm] = useState(initPasswordForm);

    const togglePasswordType = () => {
        setPasswordType(passwordType === "password" ? "text" : "password");
    };
    const toggleConfirmPasswordType = () => {
        setConfirmPasswordType(
            confirmPasswordType === "password" ? "text" : "password"
        );
    };

    const validationSchema = yup.object({
        password: yup
            .string(t("Inserisci la tua password"))
            .min(8, t("La password deve essere lunga almeno 8 caratteri"))
            .required(t("La password è obbligatoria"))
            .matches(
                passwordRegex,
                "Deve essere lunga almeno 8 caratteri e contenere uno maiuscolo, uno minuscolo, un numero e un carattere speciale"
            ),
        confirmPassword: yup
            .string(t("Ripeti password"))
            .required(t("La password di conferma è obbligatoria"))
            .oneOf(
                [yup.ref("password"), null],
                t("Le password non coincidono")
            ),
    });

    const formik = useFormik({
        initialValues: form,
        validationSchema: validationSchema,
        onSubmit: (values) => {            
            onSavePassword(values.password);
            setPasswordForm(initPasswordForm)
        },
    });

    return (
        <Box py={3}>
            <form
                className={classes.root}
                noValidate
                autoComplete="off"
                onSubmit={formik.handleSubmit}
            >
                <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Input
                            autoComplete="off"
                            type={passwordType}
                            id="password"
                            name="password"
                            label={t("Nuova Password")}
                            variant="outlined"
                            fullWidth
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.password &&
                                Boolean(formik.errors.password)
                            }
                            helperText={
                                (formik.touched.password &&
                                    formik.errors.password) ||
                                "Deve essere lunga almeno 8 caratteri e contenere uno maiuscolo, uno minuscolo, un numero e un carattere speciale"
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={togglePasswordType}>
                                        {passwordType === "password" ? (
                                            <VisibilityIcon />
                                        ) : (
                                            <VisibilityOffIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            autoComplete="off"
                            type={confirmPasswordType}
                            id="confirmPassword"
                            name="confirmPassword"
                            label={t("Ripeti password")}
                            variant="outlined"
                            fullWidth
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.confirmPassword &&
                                Boolean(formik.errors.confirmPassword)
                            }
                            helperText={
                                formik.touched.confirmPassword &&
                                formik.errors.confirmPassword
                            }
                            endAdornment={
                                <InputAdornment
                                    position="end"
                                    onClick={toggleConfirmPasswordType}
                                >
                                    <IconButton
                                        onClick={toggleConfirmPasswordType}
                                    >
                                        {confirmPasswordType === "password" ? (
                                            <VisibilityIcon />
                                        ) : (
                                            <VisibilityOffIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                </Grid>
                <Box my={4}>
                    <ContainedButton
                        type="submit"
                        variant="contained"
                        color="secondary"
                        fullWidth
                    >
                        {t("Salva")}
                    </ContainedButton>
                </Box>
            </form>
        </Box>
    );
};

export default ProfilePasswordForm;
