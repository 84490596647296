import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { cartMobilePaymentCompleted, createMobilePaymentIntent } from "@kimiya/core/store/actions/mobile-checkout"
import { decode } from "base-64"

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Backdrop from "@kimiya/ui/backdrop/backdrop";
import ContainedButton from "@kimiya/ui/button/contained-button.component";
import StripeCardInput from "@kimiya/custom/stripe/card-element";

import KimiyaLogoBlue from "@kimiya/core/images/kimiya-logo-blue.svg";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.typography.pxToRem(50),
    },
    infoText: {
        fontFamily: "Brown-Light",
        color: theme.palette.grey5.main,
        fontSize: theme.typography.pxToRem(16),
        letterSpacing: 0.44,
        marginBottom: theme.spacing(2),
    },
    registrationCompletedTitle: {
        fontFamily: "Brown-Light",
        fontSize: 20,
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.main,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    errorText: {
        marginTop: 25,
        color: theme.palette.error.main,
        lineHeight: 1,
    },
    paymentButton: {
        marginTop: theme.spacing(2),
    },
}));

const PayMobilePage = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    const { uuid_transaction, transaction_id } = useParams();
    // eslint-disable-next-line no-unused-vars
    const search = useLocation().search;
    const encodedParams = new URLSearchParams(search).get('p')
    const [id_resource_nav, token] = decode(encodedParams).split('|')

    const [loading, setLoading] = useState(false);
    const [paying, setPaying] = useState(false);
    const [isCardOk, setIsCardOk] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [paymentCompleted, setPaymentCompleted] = useState(false);

    const requestPaymentIntent = async () => {
        setPaying(true)
        setErrorMessage(false);
        const clientSecret = await createMobilePaymentIntent({
            id_transaction: ''+transaction_id,
            auth_token: token,
            id_resource_nav: id_resource_nav
        });
        if (!clientSecret) {
            setErrorMessage(
                "Errore durante la creazione della richiesta di pagamento. Si prega di riprovare o di contattare l'assistenza."
            );
            setPaying(false)
            return;
        }
        completePayment(clientSecret);
    };

    const completePayment = async (clientSecret) => {
        setLoading(true);
        if (!stripe || !elements || !clientSecret) {
            // Stripe.js has not yet loaded.
            console.error(
                "Payment Gateway not ready or client secret missing!"
            );
            setPaying(false)
            setLoading(false);
            return;
        }
        const paymentResult = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
            },
        });        

        if (paymentResult.error) {
            setPaying(false)
            setErrorMessage(paymentResult.error.message);
        } else {
            if (paymentResult.paymentIntent.status === "succeeded") {
                await cartMobilePaymentCompleted({
                    id_transaction: "" + transaction_id,
                    s_uuid: uuid_transaction,
                    auth_token: token,
                    id_resource_nav: id_resource_nav
                });
                setPaymentCompleted(true);
                setLoading(false);
                setPaying(false)
            }
        }
    };

    const onCardChange = ({ empty, complete }) => {
        if (empty || !complete) {
            setIsCardOk(false);
            return;
        }
        setIsCardOk(true);
    };

    return (
        <Container maxWidth={"xs"} className={classes.root}>
            <Backdrop
                open={loading}
                customText={t("Processando il pagamento...attendere...")}
            />
            {!paymentCompleted ? (
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={12} align="center">
                        <Typography variant="h5" color="secondary">
                            {t("Pagamento Ordine #")}
                        </Typography>
                        <Typography variant="caption" color={"primary"}>
                            {uuid_transaction}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography className={classes.infoText}>
                            {t(
                                'Inserisci i dati di pagamento e clicca su "Completa Ordine" per procedere con il pagamento'
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <StripeCardInput onChange={onCardChange} />
                    </Grid>
                    <Grid item xs={12}>
                        {errorMessage && (
                            <Typography
                                variant="caption"
                                className={classes.errorText}
                            >
                                {errorMessage}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <ContainedButton
                            fullWidth
                            disabled={!isCardOk || paying}
                            onClick={requestPaymentIntent}
                            color="secondary"
                            variant="contained"
                            className={classes.paymentButton}
                        >
                            {t("Completa Ordine")}
                        </ContainedButton>
                    </Grid>
                </Grid>
            ) : (
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid item xs={12} align="center">
                        <img src={KimiyaLogoBlue} alt="kimiya logo blue" />
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography
                            className={classes.registrationCompletedTitle}
                        >
                            {t("Ordine effettuato con successo!")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography
                            className={classes.registrationCompletedTitle}
                        >
                            {t("Torna all'app di Kimiya per visualizzare il dettaglio del tuo ordine")}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Container>
    );
};

export default PayMobilePage;
