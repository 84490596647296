export const SUBSCRIPTION_DURATION = "id_license_duration|mb_item|id_license_duration|mb_license_duration";

export const USER_RESOURCE_PROFILE = "id_resource|bf_resource|id_resource|ki_resource_profile"
export const USER_SUBSCRIPTION = "id_resource|bf_resource|id_resource_customer|mb_item_serial"
export const USER_SUBSCRIPTION_DETAILS = "id_item|mb_item_serial|id_item|mb_item";

export const SHOP_NUTRITIOUS_TYPE = "id_nutritious_type|mb_item_nutritious|id_nutritious_type|mb_nutritious_type";
export const SHOP_INGREDIENT_ITEM = "id_item|mb_item_component|id_item|mb_item";
export const SHOP_INGREDIENT_ITEM_ALLERGEN = "id_item|mb_item|id_item|mb_item_allergen";
export const SHOP_ALLERGEN_ITEM = "id_allergen|mb_item_allergen|id_allergen|mb_allergen";

export const CHECKOUT_BILLING_RESOURCE = "id_resource_transaction|mb_transaction|id_resource|bf_resource";
export const CHECKOUT_BILLING_ADDRESS = "id_address_transaction|mb_transaction|id_address|bf_address";
export const CHECKOUT_SHIPPING_ADDRESS = "id_address_shipping_delivery|mb_transaction|id_address|bf_address";

export const ORDER_SHIPPING_TYPE = "id_shipping_type|mb_transaction|id_shipping_type|mb_shipping_type"

export const CHAT_USER_RESOURCE = "id_resource_asking|mb_support|id_resource|bf_resource";