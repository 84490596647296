import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getOrders } from "@kimiya/core/store/actions/profile";
import { format } from "date-fns";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import OrderCard from "@kimiya/ui/card/order-card";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    minHeight: "80%",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(5),
  },
  sectionTitle: {
    fontFamily: "Brown-Light",
    fontSize: theme.typography.pxToRem(48),
    color: theme.palette.primary.main,
    padding: 0,
    margin: 0,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(38),
    },
  },
}));

const Orders = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const [orders, setOrders] = useState();

  const fetchOrders = useCallback(async () => {
    const orders = await getOrders();
    setOrders(orders);
  }, [setOrders]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  return (
    <Container maxWidth={"md"} className={classes.root}>
      <Typography className={classes.sectionTitle}>
        {t("I miei ordini")}
      </Typography>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        {orders ? (
          orders.map((order) => (
            <Grid item xs={12} key={order.id_transaction}>
              <OrderCard
                order_number={`${t("Ordine")} ${order.s_number} (#${
                  order.id_transaction
                })`}
                order_id={order.id_transaction}
                order_date={format(
                  Date.parse(order.d_transaction),
                  "dd/MM/yyyy - HH:mm"
                )}
                total={`${order.m_transaction_gross.toFixed(2)} €`}
              />
            </Grid>
          ))
        ) : (
          <CircularProgress size={150} />
        )}
      </Grid>
    </Container>
  );
};

export default Orders;

