import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 50,
        marginBottom: 15,
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
}));

const profileSections = [    
    { label: "Il tuo account", value: "account" },
    { label: "Cambia password", value: "password" },
    { label: "I tuoi indirizzi", value: "addresses" },
    { label: "Gestione P.IVA", value: "vat" },
    { label: "Il tuo abbonamento", value: "subscription" },
    { label: "Impostazioni", value: "settings" },
    { label: "Termini e Condizioni", value: "terms" },
    { label: "Informativa Privacy", value: "privacy" },
];

const ProfileMenuMobile = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    let { section } = useParams();
    const [value, setValue] = useState(section ? profileSections.findIndex((c) => c.value === section) : 0);

    const handleChange = (event, newValue) => {        
        if(profileSections[newValue].value === 'terms'){
            window.open("https://erp.kimiya.club/api/?id_transaction_type=0&id_agreement=8&request_format=url&action_type=custom&action_custom_method=generatePDF&action_custom_class=work.bitframe.api.SystemAction", '_blank')
            return;
        }
        if(profileSections[newValue].value === 'privacy'){
            window.open("https://erp.kimiya.club/api/?id_transaction_type=0&id_agreement=7&request_format=url&action_type=custom&action_custom_method=generatePDF&action_custom_class=work.bitframe.api.SystemAction", '_blank')
            return;
        }
        setValue(newValue);
        goToProfileSection(profileSections[newValue].value);
    };

    const goToProfileSection = (value) => {
        history.push(`/profile/${value || ''}`);
    };

    return (
        <div className={classes.root}>
            <AppBar position="relative" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >                    
                    {profileSections.map((profileSection, index) => (
                        <Tab
                            key={profileSection.value}
                            label={t(profileSection.label)}
                            id={`scrollable-auto-tab-${index}`}
                            aria-controls={`scrollable-auto-tabpanel-${index}`}
                        />
                    ))}
                </Tabs>
            </AppBar>
        </div>
    );
};

export default ProfileMenuMobile;
