import { useState, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { logout } from "@kimiya/core/store/reducers/auth.reducer";
import { getCart } from "@kimiya/core/store/actions/checkout";

import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

import ContainedButton from "@kimiya/ui/button/contained-button.component";
import OutlinedButton from "@kimiya/ui/button/outlined-button.component";
import Badge from "@kimiya/ui/cart-badge/cart-badge";

import CartIcon from "@kimiya/core/images/ICO-cart.svg";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import KimiyaLogo from "@kimiya/core/images/kimiya-logo-full.svg";
import ShopIcon from "@material-ui/icons/Store";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import ProfileIcon from "@material-ui/icons/Person";
import OrdersIcon from "@material-ui/icons/PlaylistAddCheck";

import HomeIcon from "@material-ui/icons/Home";
import AdjustIcon from "@material-ui/icons/Adjust";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import StorefrontIcon from "@material-ui/icons/Storefront";

import useStyles from "@kimiya/ui/mobile-menu/mobile-menu.styles";

const menu = [
  {
    url: "/home",
    external: false,
    label: "Home",
    icon: <HomeIcon />,
  },
  {
    url: "/la-nostra-mission",
    external: false,
    label: "Mission",
    icon: <AdjustIcon />,
  },
  {
    url: "/le-nostre-materie-prime",
    external: false,
    label: "Materie Prime",
    icon: <MenuBookIcon />,
  },
  {
    url: "/i-nostri-prodotti",
    external: false,
    label: "Prodotti",
    icon: <FastfoodIcon />,
  },
  {
    url: "/la-nostra-azienda",
    external: false,
    label: "L'azienda",
    icon: <StorefrontIcon />,
  },
  {
    url: "/shop",
    external: false,
    label: "Shop",
    icon: <ShopIcon />,
  },
  {
    url: "/faq",
    external: false,
    label: "Domande e Risposte",
    icon: <InfoIcon />,
  },
];

function MobileMenu() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user || null);
  const cart = useSelector(({ checkout }) => checkout.cart || null);
  const [openDrawer, setOpenDrawer] = useState(false);

  const goToLogin = () => {
    setOpenDrawer(false);
    history.push({
      pathname: "/login",
      state: { redirect_to: location.pathname },
    });
  };
  const goToCart = () => history.push("/cart");

  const openMenuVoice = useCallback(
    (url, external) => {
      setOpenDrawer(false);
      if (external) return window.open(url, "_blank");
      history.push(url);
    },
    [history]
  );

  const goToOrders = () => history.push("/orders");
  const goToProfile = () => history.push("/profile/account");

  const logoutHandler = async (e) => {
    setOpenDrawer(false);
    e.preventDefault();
    dispatch(logout());
    history.push("/login");
  };

  const getCartItemsNumber = () =>
    cart.items.reduce((acc, item) => (acc += item.m_quantity), 0);

  useEffect(() => {
    if (user) {
      dispatch(getCart());
    }
  }, [dispatch, user]);

  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor={"right"}
        ModalProps={{
          style: { width: "100%" },
        }}
        PaperProps={{
          style: { width: "100%" },
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ paddingLeft: 15, paddingRight: 15 }}
        >
          <Grid item>
            <img
              src={KimiyaLogo}
              alt="kimiya logo"
              width={125}
              className={classes.kimiyaLogo}
            />
          </Grid>
          <Grid item>
            <IconButton onClick={() => setOpenDrawer(false)}>
              <CloseIcon className={classes.mobileMenuIcon} />
            </IconButton>
          </Grid>
        </Grid>
        <List>
          {menu.map((item, index) => (
            <ListItem
              key={index}
              onClick={() => openMenuVoice(item.url, item.external)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={t(item.label)} />
            </ListItem>
          ))}
          {user ? (
            <>
              {!user.isCoach && user.b_registration_completed && (
                <>
                  <Divider variant="middle" className={classes.mobileDivider} />
                  <ListItem onClick={goToProfile}>
                    <ListItemIcon>
                      <ProfileIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("Profilo")} />
                  </ListItem>
                  <ListItem onClick={goToOrders}>
                    <ListItemIcon>
                      <OrdersIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("I miei ordini")} />
                  </ListItem>
                </>
              )}
              <Divider variant="middle" className={classes.mobileDivider} />
              <ListItem>
                <OutlinedButton
                  variant="outlined"
                  onClick={logoutHandler}
                  fullWidth
                >
                  Logout
                </OutlinedButton>
              </ListItem>
            </>
          ) : (
            <>
              <Divider variant="middle" className={classes.mobileDivider} />
              <ListItem>
                <ContainedButton
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={goToLogin}
                >
                  {t("Accedi")}
                </ContainedButton>
              </ListItem>
            </>
          )}
        </List>
      </Drawer>
      <Grid container justifyContent="flex-end" alignItems="center">
        <Grid item>
          <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
            <MenuIcon className={classes.mobileMenuIcon} />
          </IconButton>
        </Grid>
        {user && !user.isCoach && user.b_registration_completed && (
          <Grid item>
            <IconButton onClick={goToCart} edge="end">
              {!cart?.items || cart.items.length === 0 ? (
                <img src={CartIcon} alt="cart icon" />
              ) : (
                <Badge badgeContent={getCartItemsNumber()} color="secondary">
                  <img src={CartIcon} alt="cart icon" />
                </Badge>
              )}
            </IconButton>
          </Grid>
        )}
      </Grid>
    </>
  );
}
export default MobileMenu;
