import '@kimiya/core/themes/landing-pages.css';

import Container from '@material-ui/core/Container';
import ContainedButton from "@kimiya/core/components/ui/button/contained-button.component";
import Grid from '@material-ui/core/Grid';
import MateriePrimeMain from '@kimiya/core/images/materieprime-main-img.jpg'
import ArrowsDownIcon from '@kimiya/core/images/ICO-arrows-down.svg'
import placeHolderContentImg from '@kimiya/core/images/placeholder-content-img.png'

const RawMaterialPage = () => (
    <div>
        <div className="home-full">
            <div className="page-mainImg" style={{ backgroundImage: `url(${MateriePrimeMain})`}}>
                <h2>La materie prime</h2>
                <img className="arrows-down" src={ArrowsDownIcon} alt="scroll-down" />
            </div>
        </div>
        <Container maxWidth="lg" className="inner-mainCont">
            <Grid container justifyContent="center" className="landing-mainCont">
                <Grid item xs={12} sm={10} md={8} className="pages-content">
                    <Grid container justifyContent="space-evenly" className="pages-intro-text">
                        <Grid item xs={3}>
                            <h5 className="page-titleSml materiePrime-txt">Qualitá</h5>
                        </Grid>
                        <Grid item xs={9}>
                            <p className="page-textMain">
                                Uno dei punti cardine della nostra filosofia: qualità. Vogliamo a tutti i costi che i piatti soddisfino i nostri standard di gusto, di tutela di benessere e salute. Per fare in modo che questo avvenga la priorità si
                                focalizza sulla scelta delle materie prime per quanto riguarda la consistenza, l’aspetto esteriore, la provenienza, il metodo di coltivazione o allevamento dei prodotti che stanno alla base dei nostri piatti.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <img src={placeHolderContentImg} alt="scroll-down" width={'100%'}/>
                    </Grid>
                    <Grid container justifyContent="space-evenly" className="pages-intro-text">
                        <Grid item xs={3}>
                            <h5 className="page-titleSml materiePrime-txt">Controllo</h5>
                        </Grid>
                        <Grid item xs={9}>
                            <p className="page-text">
                            Per fare ciò verifichiamo direttamente i nostri produttori e ci impegniamo fin da subito ad accorciare la
                            catena di fornitura così da evitare, per quanto possibile, intermediari che incidano negativamente sul costo
                            del prodotto e limitino la possibilità di verificare concretamente la qualità delle materie prime.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-evenly" className="pages-intro-text">
                        <Grid item xs={3}>
                            <h5 className="page-titleSml materiePrime-txt">Feedback</h5>
                        </Grid>
                        <Grid item xs={9}>
                            <p className="page-text">
                            Un ulteriore aspetto centrale risulta la richiesta di feedback da parte dei consumatori; dal confronto onesto
                            e dal continuo scambio di opinioni si crea costantemente occasione di crescita e miglioramento.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div className="social">
                    <div className="liner"></div>
                    <h4>Seguici sui social</h4>
                    <div className="social_buttons">
                        <p>Seguici su nostri canali social e rimani sempre aggiornato sulle nostre novità e promozioni</p>
                        <ContainedButton 
                            variant="contained" 
                            className="instaBut landingBut bigBut"
                            href="https://www.instagram.com/kimiya_nutrition/"
                            target="_blank"
                            rel="noopener"
                        >
                            @KIMIYA_OFFICIAL
                        </ContainedButton>
                        <ContainedButton 
                            variant="contained" 
                            className="fbBut landingBut bigBut"
                            href="https://www.facebook.com/profile.php?id=100086437812998"
                            target="_blank"
                            rel="noopener"
                        >
                            /KIMIYA_OFFICIAL
                        </ContainedButton>
                    </div>
                </div>
        </Container>
    </div>
    

)

export default RawMaterialPage